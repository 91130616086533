import validators from '@/helpers/validators';
import useForm from '@/hooks/useForm';
import { UseFormControl } from 'src/types';
import { updateMask as updateMaskCPF } from '@/helpers/masks/cpf';
import { updateMask as updateMaskPhone } from '@/helpers/masks/mobilePhone';
import { updateMask as updateMaskDate } from '@/helpers/masks/generalDate';
import { useNavigate, useParams } from 'react-router-dom';
import CartEventTicketUserData from '@/model/CartEventTicketUserData';
import Order from '@/model/Order';
import React, { Fragment, JSX, useEffect, useState } from 'react';
import { AxiosError, api } from '@/services/api';
import { toast } from 'react-toastify';
import OrderItemType from '@/model/OrderItemType';
import OrderItem from '@/model/OrderItem';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading, useLoading } from '@/redux/loading/loadingSlice';
import dayjs from 'dayjs';
import { FormInputNameRegister } from '../../types';
import { TicketTransferContainer } from './ui';

export const TicketTransferScreen: React.FC = (): JSX.Element => {
  const { id } = useParams();
  const [order, setOrder] = useState<Order>({} as Order);
  const [countTickets, setCountTickets] = useState<number>(0);
  const [open, setOpen] = useState(false);
  const [itemIndex, setItemIndex] = useState<string>(undefined as unknown as string);
  const { loading } = useSelector(useLoading);
  const dispatch = useDispatch();
  const history = useNavigate();

  const getOrder = async (orderId: string): Promise<void> => {
    dispatch(setLoading(true));
    const response = await api.get<Order>(`/client/order/${orderId}`);
    setOrder(response.data);
    let found = false;
    let amount = 0;
    response.data.events.forEach(event => {
      event.items.forEach(item => {
        if (item.itemType === OrderItemType.TICKET) {
          // eslint-disable-next-line no-plusplus
          amount++;
          found = true;
        }
      });
    });
    if (!found) {
      toast.warn('Você não possui ingressos para transferir!');
      setTimeout(() => {
        history(-1);
      }, 1000);
    } else {
      setCountTickets(amount);
    }
    dispatch(setLoading(false));
  };

  const toCurrency = (value: number): string => {
    const formatter = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });
    return formatter.format(value);
  };

  const {
    formData: formDataTransferTicket,
    formErrors: formErrorsTransferTicket,
    setErrors: setErrorsTransferTicket,
    onChangeFormInput: onChangeFormInputTransferTicket,
    isFormValid: isFormValidTransferTicket,
    resetForm: resetFormTransferTicket,
  } = useForm({
    initialData: {
      id: '',
      name: '',
      document: '',
      email: '',
      phone: '',
      birthDate: '',
      motherName: '',
    },
    validators: {
      id: [],
      name: [validators.required],
      document: [validators.required, validators.cpf],
      email: [validators.required, validators.email],
      phone: [validators.required, validators.mobilePhone],
      birthDate: [validators.required, validators.birthday],
      motherName: [validators.required],
    },
    formatters: {
      document: updateMaskCPF,
      phone: updateMaskPhone,
      birthDate: updateMaskDate,
    },
  });

  const handleSetOpen = (b: boolean, item: OrderItem, index: string): void => {
    resetFormTransferTicket();
    if (item.userData) {
      if (item.userData.name) {
        onChangeFormInputTransferTicket(FormInputNameRegister.name)(item.userData.name);
      }
      if (item.userData.document) {
        onChangeFormInputTransferTicket(FormInputNameRegister.document)(item.userData.document);
      }
      if (item.userData.email) {
        onChangeFormInputTransferTicket(FormInputNameRegister.email)(item.userData.email);
      }
      if (item.userData.phone) {
        onChangeFormInputTransferTicket(FormInputNameRegister.phone)(item.userData.phone);
      }
    }
    setItemIndex(index);
    setOpen(b);
  };

  const handleOnCheckCpf = async (cpf: string): Promise<void> => {
    onChangeFormInputTransferTicket(FormInputNameRegister.name)('');
    onChangeFormInputTransferTicket(FormInputNameRegister.email)('');
    onChangeFormInputTransferTicket(FormInputNameRegister.phone)('');
    onChangeFormInputTransferTicket(FormInputNameRegister.birthDate)('');
    onChangeFormInputTransferTicket(FormInputNameRegister.motherName)('');
    try {
      dispatch(setLoading(true));
      const { data } = await api.get<CartEventTicketUserData>(`/client/cpf/${cpf}/check`);
      if (data.name) {
        if (data.name) {
          onChangeFormInputTransferTicket(FormInputNameRegister.name)(data.name);
        }
        if (data.email) {
          onChangeFormInputTransferTicket(FormInputNameRegister.email)(data.email);
        }
        if (data.phone) {
          onChangeFormInputTransferTicket(FormInputNameRegister.phone)(data.phone);
        }
        if (data.birthDate) {
          onChangeFormInputTransferTicket(FormInputNameRegister.birthDate)(
            data.birthDate ? dayjs(data.birthDate).format('DD/MM/YYYY') : '',
          );
        }
        if (data.motherName) {
          onChangeFormInputTransferTicket(FormInputNameRegister.motherName)(
            data.motherName ? data.motherName : '',
          );
        }
      }
    } catch (error) {
      const err = error as AxiosError;
      toast.error(err.message);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleSubmitTranferTicket = async (orderItem: OrderItem): Promise<void> => {
    if (isFormValidTransferTicket()) {
      try {
        dispatch(setLoading(true));
        const birthDateSplit = formDataTransferTicket[FormInputNameRegister.birthDate].split('/');
        const birthDateString = `${birthDateSplit[2]}-${birthDateSplit[1]}-${birthDateSplit[0]}T12:00:00.000Z`;
        const userData = {
          name: formDataTransferTicket[FormInputNameRegister.name],
          document: formDataTransferTicket[FormInputNameRegister.document],
          email: formDataTransferTicket[FormInputNameRegister.email],
          phone: formDataTransferTicket[FormInputNameRegister.phone],
          birthDate: new Date(birthDateString),
          motherName: formDataTransferTicket[FormInputNameRegister.motherName],
        } as CartEventTicketUserData;
        await api.patch(`/client/order/${order.id}/ticket/${orderItem.id}/transfer`, userData);
        resetFormTransferTicket();
        await getOrder(id as string);
        setOpen(!open);
        toast.success('Ingresso transferido com sucesso!');
      } finally {
        dispatch(setLoading(false));
      }
    }
  };

  const controllerTransferTicket: UseFormControl = {
    formData: formDataTransferTicket,
    formErrors: formErrorsTransferTicket,
    setErrors: setErrorsTransferTicket,
    onChangeFormInput: onChangeFormInputTransferTicket,
    isFormValid: isFormValidTransferTicket,
    resetForm: resetFormTransferTicket,
  };

  useEffect(() => {
    if (id && id.trim().length > 0) {
      getOrder(id);
    }
  }, [id]);
  return (
    <Fragment>
      {order && order.id && (
        <TicketTransferContainer
          state={loading}
          order={order}
          countTickets={countTickets}
          open={open}
          itemIndex={itemIndex}
          controllerTransferTicket={controllerTransferTicket}
          toCurrency={toCurrency}
          setOpen={handleSetOpen}
          onCheckCpf={handleOnCheckCpf}
          onSubmit={handleSubmitTranferTicket}
        />
      )}
    </Fragment>
  );
};
