import React from 'react';
import empty from '@/assets/images/other-images/imgvazio.svg';
import OrderEvent from '@/model/OrderEvent';
import { imageStyle } from './styles';

interface ColumnImageProps {
  srcImage?: string;
  orderEvents?: OrderEvent[];
}

const ImageContent: React.FC<ColumnImageProps> = ({ srcImage }) => (
  <>
    {srcImage ? <img style={imageStyle} src={srcImage} /> : <img style={imageStyle} src={empty} />}
  </>
);

const GridImage: React.FC<ColumnImageProps> = ({ orderEvents }) => (
  <>
    {orderEvents && orderEvents.length > 0 ? (
      <>
        {orderEvents.length > 1 ? (
          <div className="grid-image" style={imageStyle}>
            {orderEvents &&
              orderEvents.map((data, index) => <img key={index} src={data.event.imageUrl} />)}
          </div>
        ) : (
          <ImageContent srcImage={orderEvents[0].event.imageUrl} />
        )}
      </>
    ) : (
      <img style={imageStyle} src={empty} />
    )}
  </>
);

export const ColumnImage: React.FC<ColumnImageProps> = ({ orderEvents }) => (
  <div style={{ maxHeight: '5.188rem', padding: 0 }}>
    {!orderEvents || orderEvents.length === 1 ? (
      <img style={imageStyle} src={orderEvents ? orderEvents[0].event.imageUrl : empty} />
    ) : (
      <GridImage orderEvents={orderEvents} />
    )}
  </div>
);
