import React, { JSX } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import AuthorizedRoutes from './AuthorizedRoutes';

const Routers: React.FC = (): JSX.Element => (
  <BrowserRouter basename={'/'}>
    <Routes>
      <Route path={`/*`} element={<AuthorizedRoutes />} />
    </Routes>
  </BrowserRouter>
);

export default Routers;
