import React, { JSX } from 'react';
import { InputText } from '@/components';
import { EventTicketSite } from '@/features/event/screens/Event';

interface StateProps {
  item: EventTicketSite;
  eventId: string;
  eventName: string;
  sectionId: string;
}

interface DispatchProps {
  onChange: (
    eventId: string,
    sectionId: string,
    ticketId: string,
    index: number,
    value: string,
  ) => void;
  onCheck: (
    eventId: string,
    sectionId: string,
    ticketId: string,
    index: number,
    value: string,
  ) => void;
}

type Props = StateProps & DispatchProps;

export const InputTicketsTransfer: React.FC<Props> = ({
  item,
  eventId,
  eventName,
  sectionId,
  onChange,
  onCheck,
}): JSX.Element => (
  <>
    {item.userDatas.map((userData, index) => (
      <>
        <div key={`empty-tag-${index}`}></div>
        <div>{item.isHalfPrice ? `${eventName} Meia` : eventName}</div>
        <div className="mb-[20px]" key={`input-${index}-${index}`}>
          <InputText
            name={`document-${index}`}
            label="CPF"
            placeholder="123.456.789-00"
            maxLength={14}
            value={userData.document}
            onChange={e => onChange(eventId, sectionId, item.id, index, e.target.value)}
            onBlur={e => onCheck(eventId, sectionId, item.id, index, e.target.value)}
            error={userData.documentError}
          />
        </div>
      </>
    ))}
  </>
);
