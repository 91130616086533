import { configureStore } from '@reduxjs/toolkit';
import EventSlice from './event/eventSlice';
import LoadingSlice from './loading/loadingSlice';

const store = configureStore({
  reducer: {
    events: EventSlice,
    loading: LoadingSlice,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
export default store;
