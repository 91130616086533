import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import EventCategorySite from '@/model/EventCategorySite';
import EventSite from '@/model/EventSite';
import { useEventStore } from '@/redux/event/eventSlice';
import { setLoading, useLoading } from '@/redux/loading/loadingSlice';
import { EventByCategoryContainer } from './ui';

export const EventByCategoryScreen: React.FC = () => {
  const { id } = useParams();
  const [search, setSearch] = useState('');
  const [eventCategory, setEventCategory] = useState<EventCategorySite>({} as EventCategorySite);
  const [eventsFiltred, setEventsFiltred] = useState<EventSite[]>([]);
  const { events } = useSelector(useEventStore);
  const { loading } = useSelector(useLoading);
  const dispatch = useDispatch();
  const history = useNavigate();

  const getEventByCategory = async (idCategory: string): Promise<void> => {
    try {
      dispatch(setLoading(true));

      if (idCategory === 'numberAccesses') {
        // const list: EventCategorySite[] = [];
        const listEventSite: EventSite[] = [];
        events.forEach(data => {
          // list.push(data);
          data.events.forEach(event => {
            listEventSite.push(event);
          });
        });

        const sortedList = listEventSite.sort((a, b) => {
          if (a.numberAccesses > b.numberAccesses) {
            return -1;
          }
          if (a.numberAccesses < b.numberAccesses) {
            return 1;
          }
          return 0;
        });

        const listNew: EventSite[] = [];
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < sortedList.length; i++) {
          if (i < 10) {
            listNew.push(sortedList[i]);
          } else {
            break;
          }
        }
        setEventsFiltred(listNew);
        const newCategory: EventCategorySite = {
          id: 'numberAccesses',
          name: 'Festivais mais acessados',
          count: listNew.length,
          events: listNew,
        };
        // list.push(newCategory);
        setEventCategory(newCategory);
      } else {
        const eventByCategory: EventCategorySite[] = events.filter(data => data.id === idCategory);
        if (eventByCategory.length > 0) {
          setEventCategory(eventByCategory[0]);
        } else {
          setEventCategory({} as EventCategorySite);
        }
        if (eventByCategory.length > 0) {
          const listEvents: EventSite[] = [];
          eventByCategory[0].events.forEach(data => {
            listEvents.push(data);
          });
          setEventsFiltred(listEvents);
        }
      }

      dispatch(setLoading(false));
    } catch {
      dispatch(setLoading(false));
    }
  };

  const handleClickSearch = (): void => {
    const listEvents: EventSite[] = [];
    eventCategory.events.forEach(data => {
      if (data.name.toLowerCase().includes(search.toLowerCase())) {
        listEvents.push(data);
      }
    });
    setEventsFiltred(listEvents);
  };

  const handleChangeInputSearch = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setSearch(e.target.value);
    const listEvents: EventSite[] = [];
    eventCategory.events.forEach(data => {
      if (data.name.toLowerCase().includes(e.target.value.toLowerCase())) {
        listEvents.push(data);
      }
    });
    setEventsFiltred(listEvents);
  };

  const handleSelectEvent = (event: EventSite): void => {
    history(`/event/${event.id}`);
  };

  const handleClearInputSearch = (): void => {
    setSearch('');
    if (eventCategory && eventCategory.events && eventCategory.events.length > 0) {
      const listEvents: EventSite[] = [];
      eventCategory.events.forEach(data => {
        listEvents.push(data);
      });
      setEventsFiltred(listEvents);
    }
  };

  useEffect(() => {
    getEventByCategory(id as string);
  }, []);

  return (
    <EventByCategoryContainer
      state={loading}
      search={search}
      eventCategory={eventCategory}
      events={eventsFiltred}
      onClickSearch={handleClickSearch}
      onChangeInputSearch={handleChangeInputSearch}
      onSelectEvent={handleSelectEvent}
      onClearInputSearch={handleClearInputSearch}
    />
  );
};
