import React, { JSX } from 'react';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import store from './redux/store';
import Loading from './components/Loading';
import { SidebarProvider } from './hooks/useSidebar';
import Routers from './route';
import 'dayjs';
import 'dayjs/locale/pt-br';
import 'react-toastify/dist/ReactToastify.min.css';
import './styles/global.css';

const App: React.FC = (): JSX.Element => (
  <Provider store={store}>
    <Loading />
    <SidebarProvider>
      <Routers />
    </SidebarProvider>
    <ToastContainer />
  </Provider>
);

export default App;
