import { useNavigate, useParams } from 'react-router-dom';
import Order from '@/model/Order';
import React, { useEffect, useState } from 'react';
import { api } from '@/services/api';
import { toast } from 'react-toastify';
import OrderItem from '@/model/OrderItem';
import { useDispatch } from 'react-redux';
import { setLoading } from '@/redux/loading/loadingSlice';
import OrderItemTicket from '@/model/OrderItemTicket';
import StatusType from '@/model/StatusType';
import { TicketPrintContainer } from './ui';

export const TicketPrintScreen: React.FC = () => {
  const { id } = useParams();
  const [order, setOrder] = useState<Order>({} as Order);
  const [countTickets, setCountTickets] = useState<number>(0);
  const dispatch = useDispatch();
  const history = useNavigate();

  const getOrder = async (orderId: string): Promise<void> => {
    dispatch(setLoading(true));
    const response = await api.get<Order>(`/client/order/${orderId}`);
    setOrder(response.data);
    let found = false;
    let amount = 0;
    response.data.events.forEach(event => {
      event.items.forEach(item => {
        if (item.statusType === StatusType.ACTIVE) {
          // eslint-disable-next-line no-plusplus
          amount++;
          found = true;
        }
      });
    });
    if (!found) {
      toast.warn('Você não possui ingressos para transferir!');
      setTimeout(() => {
        history(-1);
      }, 1000);
    } else {
      setCountTickets(amount);
    }
    dispatch(setLoading(false));
  };

  const toCurrency = (value: number): string => {
    const formatter = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });
    return formatter.format(value);
  };

  const handleOnPrint = async (orderItem: OrderItem): Promise<void> => {
    dispatch(setLoading(true));
    const response = await api.get<OrderItemTicket>(
      `/client/order/${order.id}/ticket/${orderItem.id}`,
    );
    window.open(response.data.itemUrl);
    dispatch(setLoading(false));
  };

  useEffect(() => {
    if (id && id.trim().length > 0) {
      getOrder(id);
    }
  }, [id]);
  return (
    <>
      {order && order.id && (
        <TicketPrintContainer
          order={order}
          countTickets={countTickets}
          toCurrency={toCurrency}
          onPrint={handleOnPrint}
        />
      )}
    </>
  );
};
