import React, { Fragment, JSX } from 'react';
import { Route, Routes } from 'react-router-dom';
import { routes } from './Routes';

const AuthorizedRoutes: React.FC = (): JSX.Element => (
  <>
    <Routes>
      {routes.map(({ path, Component }, i) => (
        <Fragment key={i}>
          <Route key={i}>
            <Route path={path} element={Component} />
          </Route>
        </Fragment>
      ))}
    </Routes>
  </>
);

export default AuthorizedRoutes;
