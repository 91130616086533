import * as SwitchPrimitive from '@radix-ui/react-switch';
import React from 'react';

interface SwitchProps {
  value?: string;
  checked?: boolean;
  className?: string;
  onChange: (checked: boolean) => void;
}

export const Switch: React.FC<SwitchProps> = ({ value, checked, className, onChange }) => (
  <SwitchPrimitive.Root
    aria-checked={checked}
    value={value}
    onCheckedChange={e => onChange(!!e)}
    className={`switch relative w-[48px] h-[25px] py=[2.29px] px-[3px] rounded-[23px] bg-switch ${className}`}
  >
    <SwitchPrimitive.Thumb className="switch-thumb block w-[21px] h-[21px] rounded-full" />
  </SwitchPrimitive.Root>
);
