import { IconTrash } from '@/assets/icons/IconTrash';
import { Button, InputText } from '@/components';
import { OptionProps, Select } from '@/components/Select';
import { classNames } from '@/helpers/common';
import CardType from '@/model/CardType';
import logoAmex from '@/assets/images/logo_amex.png';
import logoDinersClub from '@/assets/images/logo_diners_club.png';
import logoDiscover from '@/assets/images/logo_discover.png';
import logoJcb from '@/assets/images/logo_jcb.png';
import logoMaster from '@/assets/images/logo_mastercard.png';
import logoVisa from '@/assets/images/logo_visa.png';
import logoElo from '@/assets/images/logo_elo.png';
import { ControllerCreditCard } from '@/features/profile/types';
import Payment from '@/model/Payment';
import CardDb from '@/model/CardDb';
import { CardStatus } from '@/model/CardStatus';
import React from 'react';
import { CardCheckout } from '../types';

interface StateProps {
  id: string;
  index: number;
  payment: Payment;
  card: CardCheckout;
  totalValue: number;
  showValue: boolean;
  controllerCreditCard: ControllerCreditCard;
  cardSelected: CardDb | undefined;
  cardSelectedInt: number;
}

interface DispatchProps {
  onChange: (
    index: number,
    e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>,
  ) => void;
  toCurrency: (value: number) => string;
  onCardSelected: (id: string, index: number) => void;
  setCardSelectedInt: (indexList: number, index: number) => void;
  validateCreditCard: (index: number) => void;
}

type Props = StateProps & DispatchProps;

export const CardCheckoutContent: React.FC<Props> = ({
  index,
  card,
  payment,
  totalValue,
  showValue,
  onChange,
  toCurrency,
  controllerCreditCard,
  onCardSelected,
  cardSelectedInt,
  setCardSelectedInt,
  validateCreditCard,
}) => {
  const options: OptionProps[] = [];

  if (payment && payment.fees && payment.fees.installments) {
    if (totalValue > 0) {
      const newTotalValue = Number(totalValue) + Number(totalValue * payment.fees.credit) / 100;
      const feeCreditCard = Number(payment.fees.fee) / 100;
      // eslint-disable-next-line no-plusplus
      for (let i = 1; i <= payment.fees.installments; i++) {
        let value: number;
        if (i === 1) {
          value = newTotalValue + 0;
        } else {
          value = newTotalValue * (1 + feeCreditCard) ** (i - 1);
        }
        const option: OptionProps = {
          value: i,
          label: `${i} X ${toCurrency(value / i)}`,
        };
        options.push(option);
      }
    }
  }

  const getCardLogo = (cardType: CardType): string => {
    let logo = logoMaster;
    switch (cardType) {
      case CardType.MASTER_CARD:
        logo = logoMaster;
        break;
      case CardType.VISA:
        logo = logoVisa;
        break;
      case CardType.AMERICAN_EXPRESS:
        logo = logoAmex;
        break;
      case CardType.DISCOVER:
        logo = logoDiscover;
        break;
      case CardType.DINERS_CLUB:
        logo = logoDinersClub;
        break;
      case CardType.JCB:
        logo = logoJcb;
        break;
      case CardType.ELO:
        logo = logoElo;
        break;
      default:
        break;
    }
    return logo;
  };
  return (
    <div>
      {controllerCreditCard.list.length > 0 &&
        controllerCreditCard.list.map((item, i) => (
          <div key={item.id} className="mb-[20px]">
            <div
              className={classNames(
                i + 1 === cardSelectedInt ? 'bg-alert-info-soft text-white' : '',
                `w-full h-[71px] ${
                  item.cardStatus === CardStatus.VALIDATING ? 'bg-[#FFE249] bg-opacity-50' : ''
                } shadow-card rounded-[5px] flex justify-between items-center px-[30px] py-[15px] cursor-pointer`,
              )}
              onClick={() => {
                setCardSelectedInt(index, i + 1);
                onCardSelected(item.id, index);
              }}
            >
              <img
                style={{ maxWidth: '36px' }}
                src={getCardLogo(item.cardType)}
                alt="logo"
                className="mr-[30px]"
              />
              <div className="flex-1">
                <div
                  className={classNames(
                    i + 1 === cardSelectedInt ? 'text-white' : 'text-neutro-b-400',
                    'text-[12px] font-dmsans font-bold ',
                  )}
                >
                  {item.cardNumber}
                  {item.cardStatus === CardStatus.VALIDATING ? (
                    <span
                      className={classNames(
                        'text-neutro-w-600',
                        'text-[12px] font-dmsans font-normal',
                        'text-red-600',
                      )}
                    >
                      {' '}
                      Aguardando validação
                    </span>
                  ) : (
                    ''
                  )}
                </div>
                <div className="mt-[5px] flex items-center">
                  <span
                    className={classNames(
                      i + 1 === cardSelectedInt ? 'text-white' : 'text-neutro-w-600',
                      'text-[12px] font-dmsans font-normal ',
                    )}
                  >
                    {item.cardholderName}
                  </span>
                  <span
                    className={classNames(
                      'bg-neutro-w-300',
                      'block w-[6px] h-[6px] mx-2  rounded-full',
                    )}
                  ></span>
                  <span
                    className={classNames(
                      i + 1 === cardSelectedInt ? 'text-white' : 'text-neutro-w-600',
                      'text-[12px] font-dmsans font-normal',
                    )}
                  >
                    Vencimento em: {item.cardExpirationDate}
                  </span>
                </div>
              </div>
              <a
                className="cursor-pointer"
                onClick={() => controllerCreditCard.onShowDeleteCreditCard(item.id)}
              >
                <IconTrash
                  className={classNames(
                    cardSelectedInt === i + 1 ? 'text-white' : 'text-neutro-w-600',
                    'h-[17px]',
                  )}
                />
              </a>
            </div>

            <div
              className={classNames(
                cardSelectedInt === i + 1 ? 'block' : 'hidden',
                `mt-[20px] mb-[20px]`,
              )}
            >
              {item.cardStatus === CardStatus.VALIDATING ? (
                <>
                  <div className="mt-[20px] mb-[20px]">
                    <InputText
                      name="validationValue"
                      label="Digite o valor recebido em sua fatura"
                      placeholder="R$"
                      className={`appearance-none w-full
              rounded-md text-heading leading-tight focus:outline-none
              focus:shadow-outline text-xs font-dmsans font-normal`}
                      maxLength={18}
                      value={card.validationValue}
                      onChange={e => onChange(index, e)}
                      error={card.validationValueError}
                    />
                  </div>
                  <div className="h-[60px] flex items-center justify-center">
                    <Button
                      title="Validar Cartão"
                      buttonStyle="primary"
                      className="block"
                      disabled={!card.validationValue || card.validationValue.trim().length === 0}
                      onClick={() => validateCreditCard(index)}
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className="mt-[20px] mb-[20px]">
                    <InputText
                      name="value"
                      label="Valor"
                      placeholder="R$"
                      className={`appearance-none w-full
              rounded-md text-heading leading-tight focus:outline-none
              focus:shadow-outline text-xs font-dmsans font-normal`}
                      maxLength={18}
                      value={card.value}
                      onChange={e => onChange(index, e)}
                      disabled={showValue === false}
                      error={card.valueError}
                    />
                  </div>

                  <div className="mb-[20px]">
                    <InputText
                      name="securityCode"
                      label="CVV"
                      placeholder="000"
                      className={`appearance-none w-full
              rounded-md text-heading leading-tight focus:outline-none
              focus:shadow-outline text-xs font-dmsans font-normal`}
                      value={card.securityCode}
                      onChange={e => onChange(index, e)}
                      error={card.securityCodeError}
                    />
                  </div>

                  <div className="mb-[20px]">
                    <Select
                      name="installments"
                      label="Parcelamento"
                      placeholder="Escolha a quantidade de parcelas"
                      className={`appearance-none w-full
              rounded-md text-heading leading-tight focus:outline-none
              focus:shadow-outline text-xs font-dmsans font-normal`}
                      maxLength={18}
                      value={card.installments}
                      onChange={e => onChange(index, e)}
                      options={options}
                      error={card.installmentsError}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        ))}
    </div>
  );
};
