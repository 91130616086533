import React from 'react';
import { IconClose, IconSearch } from '@/assets/icons';
import { SliderEventItem } from '@/components';
import { AuthWrapper } from '@/features/core/auth/screens/AuthWrapper';
import EventCategorySite from '@/model/EventCategorySite';
import EventSite from '@/model/EventSite';

interface StatesProps {
  state: boolean;
  search: string;
  eventCategory: EventCategorySite;
  events: EventSite[];
}

interface DispatchProps {
  onClickSearch: () => void;
  onChangeInputSearch: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSelectEvent: (event: EventSite) => void;
  onClearInputSearch: () => void;
}

type EventByCategoryProps = StatesProps & DispatchProps;

export const EventByCategoryContainer: React.FC<EventByCategoryProps> = ({
  state,
  eventCategory,
  search,
  events,
  onClickSearch,
  onChangeInputSearch,
  onSelectEvent,
  onClearInputSearch,
}) => {
  const { name, count } = eventCategory;
  return (
    <AuthWrapper isHomepage={false}>
      <div className="w-full container mx-auto pt-[54px] pb-[100px] font-dmsans">
        <div className="relative h-[72px]">
          <input
            value={search}
            onChange={e => onChangeInputSearch(e)}
            className="absolute w-full h-[72px] pl-[20px] bg-white border border-neutral-200 font-normal
            font-dmsans placeholder:text-neutro-w-600 rounded-lg"
            placeholder="Pesquise pelo evento"
          />
          <button
            style={{
              display: `${search !== '' ? 'block' : 'none'}`,
            }}
            className="absolute top-[50%] right-0 transform -translate-x-[75px] -translate-y-[50%]"
            onClick={onClearInputSearch}
          >
            <IconClose className="text-neutro-w-500 h-[12px]" />
          </button>

          <button
            className="absolute top-[50%] right-0 transform -translate-x-[5px] -translate-y-[50%]
                w-[48px] h-[62px] bg-neutro-w-600 hover:bg-neutro-w-500 rounded-[7px] flex justify-center items-center"
            onClick={onClickSearch}
            disabled={state || search === ''}
          >
            <IconSearch className="text-white h-[22px]" />
          </button>
        </div>
        <div className="mt-[85px]">
          {events && events.length > 0 ? (
            <>
              <h1 className="font-medium text-[28px] text-neutro-b-400">{name}</h1>
              <p className="font-normal text-base text-neutro-w-600 mt-1 mb-[30px]">
                {count} resultados
              </p>
            </>
          ) : (
            ''
          )}
          <div className="flex flex-wrap gap-5 event-category">
            {events && events.length > 0 ? (
              events.map((event: EventSite) => (
                <SliderEventItem
                  key={event.id}
                  event={event}
                  onSelectEvent={onSelectEvent}
                  className="event mb-[70px] w-full sm:w-64"
                />
              ))
            ) : (
              <p>Nenhum resultado encontrado.</p>
            )}
          </div>
        </div>
      </div>
    </AuthWrapper>
  );
};
