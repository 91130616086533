import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { Button, InputTextCupom, Modal, TabLinks } from '@/components';
import { Calendar } from '@/assets/icons';
import { AuthWrapper } from '@/features/core/auth/screens/AuthWrapper';
import SliderDate from '@/features/event/components/SliderDate';
import Section from '@/model/Section';
import { unmask } from '@/helpers/masks/cash';
import { MapContent } from '@/features/event/components/MapContent';
import EventMap from '@/model/EventMap';
import EventSiteHeader from '@/model/EventSiteHeader';
import { EventSelectContent } from '@/features/event/components/EventSelectContent';
import { SelectedObject, ShouldShowModalProps, ShowModal } from '@/features/event/types';
import MetaPixel from '@/utils/meta/metaPixel';
import { SelectableObject, TicketTypeJson } from '@seatsio/seatsio-react';
import { EventDate, EventDateSectionSite, EventGroup } from '..';

interface StateProps {
  state: boolean;
  events: EventSiteHeader[];
  event?: EventDate;
  eventMap?: EventMap;
  eventKey?: string;
  selectedObjects: SelectedObject[];
  sectionId: string;
  shouldShowModal: ShowModal;
  showProducts: boolean;
  groupId: string;
  coupon: string;
  amountItens: number;
  totalValue: number;
  totalValueWithFee: number;
  visible: boolean;
  title: React.ReactNode | string;
  showMap: boolean;
}

interface DispathProps {
  onSelectDate: (event: EventSiteHeader) => void;
  onSelectSection: (section: Section) => void;
  onIncrementTicket: (index: number) => void;
  onDecrementTicket: (index: number) => void;
  onSelectGroup: (group: EventGroup) => void;
  onIncrementProduct: (index: number) => void;
  onDecrementProduct: (index: number) => void;
  onIncrementCombo: (index: number) => void;
  onDecrementCombo: (index: number) => void;
  onCheckShowProducts: (checked: boolean) => void;
  onHandleMapSection: (sections: EventDateSectionSite[]) => EventDateSectionSite[];
  onChangeCoupon: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onApplyCoupon: () => void;
  onRemoveCoupon: () => void;
  onClearEvent: () => void;
  onSubmitAddCart: () => void;
  getEventKey: (sectionId: string, ticketId: string) => string;
  showModal: (props: ShouldShowModalProps) => void;
  onToggle: () => void;
  onChartObjectSelected: (object: SelectableObject, selectedTicketType: TicketTypeJson) => void;
  onChartObjectDeselected: (object: SelectableObject, selectedTicketType: TicketTypeJson) => void;
  onModal: (sectionId: string, tickectId: string, hasMap: boolean) => void;
}

type EventContainerProps = StateProps & DispathProps;

export const EventContainer: React.FC<EventContainerProps> = ({
  state,
  events,
  event,
  eventMap,
  eventKey,
  selectedObjects,
  sectionId,
  shouldShowModal,
  showProducts,
  groupId,
  coupon,
  amountItens,
  totalValue,
  totalValueWithFee,
  visible,
  title,
  showMap,
  onSelectDate,
  onSelectSection,
  onIncrementTicket,
  onDecrementTicket,
  onCheckShowProducts,
  onIncrementProduct,
  onDecrementProduct,
  onIncrementCombo,
  onDecrementCombo,
  onSelectGroup,
  onChangeCoupon,
  onApplyCoupon,
  onRemoveCoupon,
  onClearEvent,
  onSubmitAddCart,
  getEventKey,
  showModal,
  onToggle,
  onChartObjectSelected,
  onChartObjectDeselected,
  onHandleMapSection,
  onModal,
}) => {
  const toCurrency = (value: number): string => {
    const formatter = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });
    return formatter.format(value);
  };

  const [mostrarMais, setMostrarMais] = useState(false);

  const handleMostrarMais = (): void => {
    setMostrarMais(!mostrarMais);
  };

  const getDiscountValue = (): string | undefined => {
    let value = 0;
    if (event && event.discountCoupon && event.discountCoupon.discountCoupon) {
      value += event.discountCoupon.discountCoupon.discount;
    }
    if (value > 0) {
      return toCurrency(value);
    }
    return undefined;
  };

  const mountTextDate = (): string => {
    const startDate = dayjs(event?.startDate).locale('pt-br').format('DD MMM');
    const endDate = dayjs(event?.endDate).locale('pt-br').format('DD MMM');
    if (startDate === endDate) {
      return startDate;
    }
    return `${startDate} > ${endDate}`;
  };

  const renderText = (): string => {
    const texto = event?.websiteDescription;
    if (mostrarMais || !texto || texto.length <= 100) {
      return texto as string;
    }
    return `${texto.substring(0, 100)}...`;
  };
  const checkIsMobile = (): void => {
    const width = window.innerWidth;

    if (width > 768) {
      setMostrarMais(true);
    } else {
      setMostrarMais(false);
    }
  };

  useEffect(() => {
    checkIsMobile();
    window.addEventListener('resize', checkIsMobile);
    return () => window.removeEventListener('resize', checkIsMobile);
  }, []);

  // Pixel
  useEffect(() => {
    if (
      event &&
      event.pixel &&
      event.pixel !== undefined &&
      event.pixel !== null &&
      event.pixel.trim().length > 0
    ) {
      const dynamicMetaPixel = MetaPixel(event.pixel);
      dynamicMetaPixel.trackPageView();
    }
  }, [event]);

  return (
    <AuthWrapper isHomepage={false}>
      <div className="event w-full container mx-auto mb-36 lg:pt-[100px]">
        <img
          className="event__image w-full lg:h-[381px] object-cover rounded-md before:absolute before:w-full before:h-full
              before:top-0 before:left-0 before:bg-opacity-20 before:bg-black"
          src={event?.imageUrl}
          alt={event?.name}
        />
        <div className="event__content grid grid-cols-[1fr] w-full md:grid-cols-[1fr] lg:grid-cols-[1fr,_535px] xl:grid-cols-[1fr,_635px] gap-20 mt-12">
          <div className="event__detail">
            <div className="md:p-0">
              <h1 className="text-heading font-dmsans font-medium text-4xl uppercase">
                {event?.name}
              </h1>
              <div className="flex flex-wrap items-center mt-2">
                <span className="font-dmsans font-normal text-paragraph text-xl">
                  {mountTextDate()}
                </span>
                <span className="w-[6px] h-[6px] bg-gray-300 rounded-full"></span>
                <span className="font-dmsans font-normal text-paragraph text-xl">
                  {event?.address.city}/{event?.address.state}
                </span>
              </div>
            </div>
            <div className="mt-10 md:p-0">
              <TabLinks
                tabs={['Sobre', 'Descontos', 'Ponto de venda']}
                contents={[
                  <div key={1} className="text-paragraph">
                    {event?.imageDescriptionUrl && <img src={event?.imageDescriptionUrl} />}
                    {renderText()
                      ?.split('\n')
                      .map((data, index) => (
                        <p key={`${index}-${data}`} style={{ margin: 'revert' }}>
                          {data}
                        </p>
                      ))}
                    {!mostrarMais &&
                      event?.websiteDescription &&
                      event?.websiteDescription.length > 100 && (
                        <button
                          onClick={handleMostrarMais}
                          className="bg-gray-500 text-white px-1 rounded flex items-center text-sm"
                        >
                          Ver Mais <span className="ml-1 text-sm">&#9660;</span>
                        </button>
                      )}
                    {/* {event?.websiteDescription
                      .split('\n')
                      .map((data, index) => (
                        <p
                          key={`${index}-${data}`}
                          style={{ margin: 'revert' }}
                        >
                          {data}
                        </p>
                      ))} */}
                  </div>,
                  <div key={2} className="text-paragraph">
                    {event?.discountDescription}
                  </div>,
                  <div key={3} className="text-paragraph">
                    {event &&
                      event.pdvs &&
                      event.pdvs.length > 0 &&
                      event.pdvs.map(data => (
                        <div key={data.id} className="my-10 pb-10 border-b border-neutro-w-200">
                          <div
                            style={{
                              marginBottom: '20px',
                            }}
                          >
                            <h1
                              style={{
                                fontFamily: 'DM Sans',
                                fontStyle: 'normal',
                                fontWeight: '500',
                                fontSize: '21px',
                                lineHeight: '27px',

                                /* Cores/Preto/-1 */

                                color: '#222222',
                              }}
                            >
                              {`${data.name}`}
                            </h1>
                          </div>
                          <div
                            style={{
                              marginBottom: '20px',
                            }}
                          >
                            <h1
                              style={{
                                fontFamily: 'DM Sans',
                                fontStyle: 'normal',
                                fontWeight: '400',
                                fontSize: '18px',
                                lineHeight: '27px',

                                /* Cores/Preto/-1 */

                                color: '#222222',
                              }}
                            >
                              Endereço
                            </h1>
                            <span
                              style={{
                                fontFamily: 'DM Sans',
                                fontStyle: 'normal',
                                fontWeight: '400',
                                fontSize: '16px',
                                lineHeight: '21px',
                                color: '#828282',
                              }}
                            >{`${data.address.street}, ${data.address.number}, ${data.address.city} - ${data.address.state}`}</span>
                          </div>
                          {/*
                          <div
                            style={{
                              marginBottom: '20px',
                            }}
                          >
                            <h1
                              style={{
                                fontFamily: 'DM Sans',
                                fontStyle: 'normal',
                                fontWeight: '400',
                                fontSize: '18px',
                                lineHeight: '27px',
                                color: '#222222',
                              }}
                            >
                              Telefone
                            </h1>
                            <span
                              style={{
                                fontFamily: 'DM Sans',
                                fontStyle: 'normal',
                                fontWeight: '400',
                                fontSize: '16px',
                                lineHeight: '21px',
                                color: '#828282',
                              }}
                            >
                              {updateMask(data.telephone)}
                            </span>
                          </div>
                          <div>
                            <h1
                              style={{
                                fontFamily: 'DM Sans',
                                fontStyle: 'normal',
                                fontWeight: '400',
                                fontSize: '18px',
                                lineHeight: '27px',
                                color: '#222222',
                              }}
                            >
                              E-mail para contato
                            </h1>
                            <span
                              style={{
                                fontFamily: 'DM Sans',
                                fontStyle: 'normal',
                                fontWeight: '400',
                                fontSize: '16px',
                                lineHeight: '21px',
                                color: '#828282',
                              }}
                            >
                              {data.email}
                            </span>
                          </div>
                           */}
                        </div>
                      ))}
                  </div>,
                ]}
              />
            </div>
          </div>
          <div className="event__tickets space-y-8 md:p-0">
            <div className="w-full py-5 md:py-10 md:px-10 rounded md:shadow-xs">
              <section className="event__date mb-10">
                <h2 className="text-heading text-xl font-dmsans font-normal flex">
                  <img src={Calendar} alt="Selecione o dia" className="fill-heading mr-3" />
                  <span>Selecione o dia</span>
                </h2>
                <div className="">
                  {events && events.length > 0 && (
                    <SliderDate eventDates={events} handleOnClick={onSelectDate} />
                  )}
                  <div className="ticket-result mt-8">
                    <div className="badge sm:w-max px-5 py-3 flex justify-center items-center text-[#60B9CD] bg-badge rounded-[20px]">
                      {event?.name}
                    </div>
                  </div>
                </div>
              </section>
              <EventSelectContent
                event={event}
                sectionId={sectionId}
                showProducts={showProducts}
                groupId={groupId}
                showMap={showMap}
                onSelectSection={onSelectSection}
                onIncrementTicket={onIncrementTicket}
                onDecrementTicket={onDecrementTicket}
                getEventKey={getEventKey}
                onShowModal={showModal}
                onCheckShowProducts={onCheckShowProducts}
                onIncrementProduct={onIncrementProduct}
                onDecrementProduct={onDecrementProduct}
                onIncrementCombo={onIncrementCombo}
                onDecrementCombo={onDecrementCombo}
                onSelectGroup={onSelectGroup}
                onHandleMapSection={onHandleMapSection}
                onModal={onModal}
              />
              <hr className="mb-10" />
              {event && event.allowDiscount && (
                <section className="mb-7">
                  <div className="text-label font-dmsans"></div>
                  <div>
                    <InputTextCupom
                      name="document"
                      label="Cupom de desconto (apenas um cupom por compra)"
                      placeholder="ABCD1234"
                      maxLength={8}
                      value={coupon}
                      onChange={e => onChangeCoupon(e)}
                      error={''}
                      onApply={onApplyCoupon}
                      onRemove={onRemoveCoupon}
                      discountCoupon={event.discountCoupon}
                    />
                  </div>
                </section>
              )}

              <div className="mt-2">
                <div className="flex justify-between items-center mb-5">
                  <div className="text-heading font-dmsans font-normal">
                    Total de itens: <span className="font-bold">{amountItens}</span>
                  </div>
                  <button
                    className="font-dmsans text-base text-neutro-w-300"
                    onClick={() => onClearEvent()}
                  >
                    Limpar
                  </button>
                </div>
                <div className="flex justify-between items-center mb-5">
                  <div className="text-heading font-dmsans font-normal">
                    Sub total: <span className="font-bold">{toCurrency(totalValue)}</span>
                  </div>
                </div>
                {getDiscountValue() && (
                  <div className="flex justify-between items-center mb-5">
                    <div className="text-heading font-dmsans font-normal">
                      Desconto: <span className="font-bold">- {getDiscountValue()}</span>
                    </div>
                  </div>
                )}
                <div className="flex justify-between items-center mb-5">
                  <div className="text-heading font-dmsans font-normal">
                    Taxa:{' '}
                    <span className="font-bold">
                      {toCurrency(
                        getDiscountValue()
                          ? totalValueWithFee +
                              Number(unmask(getDiscountValue() as string)) -
                              totalValue
                          : totalValueWithFee - totalValue,
                      )}
                    </span>
                  </div>
                </div>
                <hr className="mb-10" />
                <div className="flex justify-between items-center mb-5">
                  <div className="text-heading font-dmsans font-normal">
                    Valor total: <span className="font-bold">{toCurrency(totalValueWithFee)}</span>
                  </div>
                </div>
                <Button
                  type="submit"
                  title="Adicionar ao carrinho"
                  buttonStyle="primary"
                  size="xlg"
                  className="w-full"
                  onClick={onSubmitAddCart}
                  disabled={totalValueWithFee === 0 || state}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        visible={visible}
        title={title}
        onSetVisible={onToggle}
        actions={[]}
        className="md:w-[750px]"
      >
        {
          {
            [ShowModal.MAP]: (
              <MapContent
                eventMap={eventMap}
                eventKey={eventKey}
                selectedObjects={selectedObjects}
                onObjectSelected={onChartObjectSelected}
                onObjectDeselected={onChartObjectDeselected}
              />
            ),
          }[shouldShowModal]
        }
      </Modal>
    </AuthWrapper>
  );
};
