import React, { JSX } from 'react';
import { Button } from '@/components';
import { CardValidationResponse } from '@/model/CardValidationResponse';
import logoAmex from '@/assets/images/logo_amex.png';
import logoDinersClub from '@/assets/images/logo_diners_club.png';
import logoDiscover from '@/assets/images/logo_discover.png';
import logoJcb from '@/assets/images/logo_jcb.png';
import logoMaster from '@/assets/images/logo_mastercard.png';
import logoVisa from '@/assets/images/logo_visa.png';
import logoElo from '@/assets/images/logo_elo.png';
import CardType from '@/model/CardType';
import { IconCheckSuccess } from '@/assets/icons';

interface StateProps {
  cardValidationResponses: CardValidationResponse[];
}

interface DispatchProps {
  onContinue: () => void;
}

type Props = StateProps & DispatchProps;

export const ValidatedCreditCardContent: React.FC<Props> = ({
  cardValidationResponses,
  onContinue,
}): JSX.Element => {
  const getCardLogo = (cardType: CardType): string => {
    let logo = logoMaster;
    switch (cardType) {
      case CardType.MASTER_CARD:
        logo = logoMaster;
        break;
      case CardType.VISA:
        logo = logoVisa;
        break;
      case CardType.AMERICAN_EXPRESS:
        logo = logoAmex;
        break;
      case CardType.DISCOVER:
        logo = logoDiscover;
        break;
      case CardType.DINERS_CLUB:
        logo = logoDinersClub;
        break;
      case CardType.JCB:
        logo = logoJcb;
        break;
      case CardType.ELO:
        logo = logoElo;
        break;
      default:
        break;
    }
    return logo;
  };

  return (
    <div className="w-[411px] mx-auto">
      <div className="flex flex-col justify-center">
        <IconCheckSuccess height="50px" className="self-center text-[#7AD81B] mb-[30px]" />
        <h4 className="font-dmsans font-bold text-neutro-b-400 text-[21px] mb-[20px]">
          {`${
            cardValidationResponses.length > 1 ? 'Seus cartões foram' : 'Seu cartão foi'
          } validado com Sucesso`}
        </h4>
        {cardValidationResponses.map(data => (
          <div
            key={`Validating-credit-card-${data.card.id}`}
            className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0"
          >
            <img
              style={{ maxWidth: '36px' }}
              src={getCardLogo(data.card.cardType)}
              alt="logo"
              className="mr-[30px]"
            />
            <label className="ml-[20px]">{data.card.cardNumber}</label>
          </div>
        ))}
        <Button
          type="button"
          title="Continuar"
          buttonStyle="primary"
          size="xlg"
          className="w-full mb-[25px]"
          onClick={() => onContinue()}
        />
      </div>
    </div>
  );
};
