import * as Dialog from '@radix-ui/react-dialog';
import { Close } from '@/assets/icons';
import React from 'react';
import { ButtonSize } from '../Button';

interface ModalProps {
  visible: boolean;
  onSetVisible: (open: boolean) => void;
  title?: string | React.ReactNode;
  children?: React.ReactNode;
  actions?: ActionProps[];
  className?: string;
  showCloseButton?: boolean;
}

export interface ActionProps {
  title?: string;
  buttonStyle?: string;
  size?: ButtonSize;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

export const Modal: React.FC<ModalProps> = ({
  visible,
  onSetVisible,
  title,
  children,
  actions,
  className,
  showCloseButton,
}) => (
  <Dialog.Root open={visible} onOpenChange={onSetVisible}>
    <Dialog.Portal>
      <Dialog.Overlay className="fixed inset-0 bg-modal-overlay z-10">
        <Dialog.Content className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center text-center sm:items-center p-0 md:p-4">
            <div
              className={`py-7 px-10 md:px-16 relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all my-0 sm:my-8 w-full md:w-[680px] ${className}`}
            >
              <div className="flex flex-col justify-between items-center">
                {(showCloseButton === undefined || showCloseButton === true) && (
                  <div className="self-end">
                    <Dialog.Close>
                      <img src={Close} alt="button closer" />
                    </Dialog.Close>
                  </div>
                )}

                {title && (
                  <div className="self-start text-heading text-4xl font-dmsans font-bold">
                    {title}
                  </div>
                )}
              </div>
              {children}
              {actions && (
                <div>
                  {actions.map((action: ActionProps, index: number) => (
                    <button key={index}>{action.title}</button>
                  ))}
                </div>
              )}
            </div>
          </div>
        </Dialog.Content>
      </Dialog.Overlay>
    </Dialog.Portal>
  </Dialog.Root>
);

export const ShowModal: React.FC<ModalProps> = ({
  visible,
  onSetVisible,
  title,
  children,
  actions,
}) => (
  <Modal visible={visible} onSetVisible={onSetVisible} title={title} actions={actions}>
    {children}
  </Modal>
);
