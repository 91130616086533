import React, { JSX, useEffect, useState } from 'react';
import { Range } from '@/components';
import { api } from '@/services/api';
import EventCategorySite from '@/model/EventCategorySite';
import EventSite from '@/model/EventSite';
import { Address } from '@/model/Address';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setEventStore } from '@/redux/event/eventSlice';
import { setLoading } from '@/redux/loading/loadingSlice';
import dayjs from 'dayjs';
import { HomeContainer } from './ui';

export const HomeScreen: React.FC = (): JSX.Element => {
  const history = useNavigate();
  const [events, setEvents] = useState<EventCategorySite[]>([]);
  const [eventsShow, setEventsShow] = useState<EventCategorySite[]>([]);
  const [eventsMoreSeen, setEventsMoreSeen] = useState<EventCategorySite[]>([]);
  const [eventsSearch, setEventsSearch] = useState<EventSite[]>([]);
  const [eventsSearchShow, setEventsSearchShow] = useState<EventSite[]>([]);
  const [inputSearch, setInputSearch] = useState<string>('');
  const [inputFilter, setInputFilter] = useState<string>(undefined as unknown as string);
  const [cities, setCities] = useState<Address[]>([]);
  const [citiesShow, setCitiesShow] = useState<Address[]>([]);
  const [selectedCity, setSelectedCity] = useState<Address>({
    city: 'Todas as cidades',
    state: '',
  } as Address);
  const [dataRange, setDateRange] = useState<Range[] | undefined>(undefined);
  const dispatch = useDispatch();

  const [rangeDateSelected, setRangeDateSelected] = useState<Range[] | undefined>(undefined);

  const getEvents = async (): Promise<void> => {
    const eventSites: EventSite[] = [];
    const addresses: Address[] = [];
    dispatch(setLoading(true));
    const response = await api.get<EventCategorySite[]>('/event');
    addresses.push({ city: 'Todas as cidades', state: '' } as Address);
    response.data.forEach(data => {
      data.events.forEach(dataEvent => {
        eventSites.push(dataEvent);
        let found = false;
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < addresses.length; i++) {
          if (dataEvent.city === addresses[i].city && dataEvent.state === addresses[i].state) {
            found = true;
            break;
          }
        }
        if (!found) {
          const address = {
            city: dataEvent.city,
            state: dataEvent.state,
          } as Address;
          addresses.push(address);
        }
      });
    });
    setEventsSearch(eventSites);
    setEventsSearchShow(eventSites);
    setCities(addresses);
    setCitiesShow(addresses);

    const list: EventCategorySite[] = [];
    response.data.forEach(data => {
      list.push(data);
    });

    const sortedList = eventSites.sort((a, b) => {
      if (a.numberAccesses > b.numberAccesses) {
        return -1;
      }
      if (a.numberAccesses < b.numberAccesses) {
        return 1;
      }
      return 0;
    });

    const listEventSite: EventSite[] = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < sortedList.length; i++) {
      if (i < 10) {
        listEventSite.push(sortedList[i]);
      } else {
        break;
      }
    }
    const newCategory: EventCategorySite = {
      id: 'numberAccesses',
      name: 'Festivais mais acessados',
      count: listEventSite.length,
      events: listEventSite,
    };

    const listMoreSeeen: EventCategorySite[] = [];
    listMoreSeeen.push(newCategory);
    setEventsMoreSeen(listMoreSeeen);

    setEvents(response.data);
    dispatch(setEventStore(response.data));
    setEventsShow(list);
    dispatch(setLoading(false));
  };

  const handleChangeInputSearch = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setInputSearch(e.target.value);
    const show: EventSite[] = [];
    eventsSearch.forEach(data => {
      if (data.name.toLowerCase().includes(e.target.value.toLowerCase())) {
        show.push(data);
      }
    });
    setEventsSearchShow(show);
  };

  const mountQueryParam = (value: string): string => {
    if (value && value.trim().length > 0) {
      let newValue = '';
      const split = value.split(' ');
      if (split && split.length > 0) {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < split.length; i++) {
          if (i > 0) {
            newValue += '_';
          }
          newValue += split[i].toLowerCase();
        }
      }
      return newValue;
    }
    return '';
  };

  const handleSelectEvent = (selectedEvent: EventSite): void => {
    const name = mountQueryParam(selectedEvent.name);
    const city = mountQueryParam(selectedEvent.city);
    const state =
      selectedEvent.state && selectedEvent.state.trim().length > 0
        ? selectedEvent.state.toLowerCase()
        : '';
    /*
    const partes = selectedEvent.name.split(' ');

    const partesValidas = partes.filter((parte) => parte.length > 1);

    const novoNome = partesValidas.join('-');
    */
    history(`/event/${name}/${city}/${state}`);
  };

  const handleClearInputSearch = (): void => {
    setInputSearch('');
    setEventsSearchShow(eventsSearch);
  };

  const handleChangeInputFilter = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setInputFilter(e.target.value);
    if (e.target.value.length > 3) {
      const addresses: Address[] = [];
      cities.forEach(data => {
        const value = `${data.city}, ${data.state}`.toLowerCase();
        if (value.includes(e.target.value.toLowerCase())) {
          addresses.push(data);
        }
      });
      setCitiesShow(addresses);
    }
  };

  const handleClearInputFilter = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    setInputFilter('');
    setCitiesShow(cities);
    e.preventDefault();
  };

  const handleSelectDateRange = (dateRange: Range[] | undefined): void => {
    if (dateRange && dateRange[0] && dateRange[0].startDate && dateRange[0].endDate) {
      const { startDate } = dateRange[0];
      let { endDate } = dateRange[0];
      endDate = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), 23, 59, 59);

      const show: EventCategorySite[] = [];
      events.forEach(data => {
        const eventCategorySite: EventCategorySite = {
          id: data.id,
          name: data.name,
          count: data.count,
          events: [],
        };
        data.events.forEach(dataEvent => {
          const tzDateStartString = dayjs(dataEvent.startDate)
            .locale(Intl.DateTimeFormat().resolvedOptions().timeZone)
            .format('YYYY-MM-DD[T]HH:mm:ss.SSS');
          const tzDateStart = new Date(tzDateStartString);
          const tzDateEndString = dayjs(dataEvent.endDate)
            .locale(Intl.DateTimeFormat().resolvedOptions().timeZone)
            .format('YYYY-MM-DD[T]HH:mm:ss.SSS');
          const tzDateEnd = new Date(tzDateEndString);
          if (
            tzDateStart >= startDate &&
            tzDateEnd <= endDate &&
            (selectedCity.city === 'Todas as cidades' ||
              (dataEvent.city === selectedCity.city && dataEvent.state === selectedCity.state))
          ) {
            const eventSite: EventSite = {
              id: dataEvent.id,
              name: dataEvent.name,
              imageUrl: dataEvent.imageUrl,
              city: dataEvent.city,
              state: dataEvent.state,
              startDate: dataEvent.startDate,
              endDate: dataEvent.endDate,
              numberAccesses: dataEvent.numberAccesses,
            };
            eventCategorySite.events.push(eventSite);
          }
        });
        if (eventCategorySite.events.length > 0) {
          show.push(eventCategorySite);
        }
      });
      setEventsShow(show);
    }
    setDateRange(dateRange);
    setRangeDateSelected(dateRange);
  };

  const handleChangeCity = (address: Address): void => {
    if (address.city === 'Todas as cidades') {
      setSelectedCity(address);
      setEventsShow(events);
      if (rangeDateSelected) {
        handleSelectDateRange(rangeDateSelected);
      }
      return;
    }
    const show: EventCategorySite[] = [];
    events.forEach(data => {
      const eventCategorySite: EventCategorySite = {
        id: data.id,
        name: data.name,
        count: data.count,
        events: [],
      };
      data.events.forEach(dataEvent => {
        if (
          address.city === 'Todas as cidades' ||
          (dataEvent.city === address.city && dataEvent.state === address.state)
        ) {
          const eventSite: EventSite = {
            id: dataEvent.id,
            name: dataEvent.name,
            imageUrl: dataEvent.imageUrl,
            city: dataEvent.city,
            state: dataEvent.state,
            startDate: dataEvent.startDate,
            endDate: dataEvent.endDate,
            numberAccesses: dataEvent.numberAccesses,
          };
          eventCategorySite.events.push(eventSite);
        }
      });
      if (eventCategorySite.events.length > 0) {
        show.push(eventCategorySite);
      }
    });
    setEventsShow(show);
    setSelectedCity(address);
    if (rangeDateSelected) {
      handleSelectDateRange(rangeDateSelected);
    }
  };

  const dataRangeInitials: Range[] = [
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
      showDateDisplay: true,
    },
  ];

  const handleClearRangeDateSelected = (): void => {
    setRangeDateSelected(undefined);
    setDateRange(dataRangeInitials);
    getEvents();
  };

  useEffect(() => {
    setDateRange(dataRangeInitials);
    // setRangeDateSelected(dataRangeInitials);
    getEvents();
  }, []);

  return (
    <HomeContainer
      events={eventsShow}
      eventsMoreSeen={eventsMoreSeen}
      inputSearch={inputSearch}
      eventsSearch={eventsSearchShow}
      inputFilter={inputFilter}
      cities={citiesShow}
      selectedCity={selectedCity}
      dateRange={dataRange}
      onChangeInputSearch={handleChangeInputSearch}
      onSelectEvent={handleSelectEvent}
      onClearInputSearch={handleClearInputSearch}
      onChangeInputFilter={handleChangeInputFilter}
      onClearInputFilter={handleClearInputFilter}
      onChangeCity={handleChangeCity}
      onChangeDateRange={handleSelectDateRange}
      rangeDateSelected={rangeDateSelected}
      onClearRangeDateSelected={handleClearRangeDateSelected}
    />
  );
};
