import { Authenticate3DSError } from '@/model/Authenticate3DSError';
import { Authenticate3DSRequest } from '@/model/Authenticate3DSRequest';
import { Authenticate3DSResponse } from '@/model/Authenticate3DSResponse';

export enum ShowModalPayment {
  ADD_CREDIT_CARD_PAYMENT = 'add_credit_card_payment',
  CONFIRM_DELETE_CREDIT_CARD_PAYMENT = 'confirm_delete_credit_card_payment',
  VALIDATE_CREDIT_CARD = 'validate_credit_card',
  VALIDATING_CREDIT_CARD = 'validating_credit_card',
  VALIDATED = 'validated',
  VALIDATED_ERROR = 'validated_error',
  CREDIT_CARD_PAYMENT = 'credit_card_payment',
  TICKET_PAYMENT = 'ticket_payment',
  PIX_PAYMENT = 'pix_payment',
  PAYMENT_ERROR = 'payment_error',
  PAYMENT_NOTMADE = 'payment_notmade',
}

export enum FormInputNameCheckout {
  name = 'name',
  document = 'document',
  email = 'email',
  phone = 'phone',
  birthDate = 'birthDate',
  motherName = 'motherName',
}

export interface CardCheckout {
  cardSelectedInt: number;
  selected: boolean;
  id: string;
  number: string;
  numberError: string;
  expDate: string;
  expDateError: string;
  securityCodeDb: string;
  securityCode: string;
  securityCodeError: string;
  holder: string;
  holderError: string;
  document: string;
  documentError: string;
  value: string;
  valueWithFee: number;
  valueError: string;
  installments: number;
  installmentsError: string;
  validationValue: string;
  validationValueError: string;
}

export type ShouldShowModalProps = {
  value: ShowModalPayment;
  newTitle: string | React.ReactNode;
};

export interface UseModalControl {
  visible: boolean;
  title: string | React.ReactNode;
  shouldShowModal: ShowModalPayment;
  onShouldShowModal: ({ value, newTitle }: ShouldShowModalProps) => void;
  onToggleModal: () => void;
  onSetVisible: (visible: boolean) => void;
}

export enum FormInputNameAddress {
  id = 'id',
  zipCode = 'zipCode',
  state = 'state',
  city = 'city',
  district = 'district',
  street = 'street',
  complement = 'complement',
  number = 'number',
  hasAddress = 'hasAddress',
}

export enum CreditCardValidationStatus {
  NONE = 'NONE',
  CREATE_ORDER = 'CREATE_ORDER',
  VALIDATE_DATA = 'VALIDATE_DATA',
  VALIDATED = 'VALIDATED',
}

export enum Auth3DSStatus {
  NONE = 'NONE',
  START = 'START',
  AUTH_SUCCESS = 'AUTH_SUCCESS',
  AUTH_ERROR = 'AUTH_ERROR',
}

export interface Auth3DSResponse {
  cardId: string;
  session: string;
  installments: number;
  paymentValue: number;
  request: Authenticate3DSRequest;
  response?: Authenticate3DSResponse;
  error?: Authenticate3DSError;
}
