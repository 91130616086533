import React, { InputHTMLAttributes } from 'react';
import { Close, Cupom } from '@/assets/icons';
import { classNames } from '@/helpers/common';
import CheckDiscount from '@/model/CheckDiscount';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name?: string;
  value: string;
  type?: 'text';
  label?: string;
  error?: string | null | undefined;
  className?: string;
  placeholder?: string;
  maxLength?: number;
  discountCoupon?: CheckDiscount;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onApply: () => void;
  onRemove: () => void;
}

export const InputTextCupom: React.FC<InputProps> = ({
  name,
  type = 'text',
  value,
  placeholder,
  error,
  label,
  maxLength,
  className,
  discountCoupon,
  onBlur,
  onChange,
  onApply,
  onRemove,
}) => {
  const openInput = (): boolean => {
    let open = false;
    if (
      !discountCoupon ||
      (discountCoupon.discountCoupon === undefined && discountCoupon.voucher === undefined)
    ) {
      open = true;
    }
    return open;
  };
  return (
    <div className={`relative ${className}`}>
      {openInput() && (
        <React.Fragment>
          {label && (
            <label
              htmlFor={name}
              className="block text-left text-label text-base font-dmsans font-regular mb-2"
            >
              {label}
            </label>
          )}
          <div className="relative">
            <input
              aria-invalid={error ? 'true' : 'false'}
              type={type}
              value={value}
              placeholder={placeholder ?? 'Digite aqui'}
              maxLength={maxLength}
              onBlur={onBlur}
              onChange={onChange}
              className={`uppercase appearance-none h-[60px] border-2 border-neutro-w-100 rounded w-full py-2 px-3
          text-neutro-b-400 text-base leading-[21px] font-dmsans font-normal
          placeholder:text-neutro-w-400
          focus:outline-none focus:border-neutro-w-400 focus:border-2 focus:shadow-outline ${
            error ? 'border-red-500' : ''
          }`}
            />
            <button
              className={classNames(
                value ? 'text-alert-info-soft' : 'text-neutro-w-300',
                discountCoupon ? 'text-neutro-w-300' : '',
                'absolute top-[50%] right-0 transform -translate-x-[25px] -translate-y-[50%]',
              )}
              onClick={() => onApply()}
              disabled={value.length === 0}
            >
              Aplicar
            </button>
          </div>
        </React.Fragment>
      )}

      <div>
        {discountCoupon && discountCoupon.discountCoupon && (
          <div className="flex justify-between items-center mt-5">
            <div className="flex justify-start items-center">
              <img src={Cupom} alt={discountCoupon.discountCoupon.name} />
              <span className="uppercase ml-3 mr-12">{discountCoupon.discountCoupon.name}</span>
            </div>
            <a onClick={() => onRemove()} className="text-neutro-b-400 cursor-pointer">
              <img src={Close} alt="fechar" className="fill-neutro-b-400" />
            </a>
          </div>
        )}
        {discountCoupon && discountCoupon.voucher && (
          <div className="flex justify-between items-center mt-5">
            <div className="flex justify-start items-center">
              <img src={Cupom} alt={discountCoupon.voucher.code} />
              <span className="uppercase ml-3 mr-12">{discountCoupon.voucher.code}</span>
            </div>
            <a onClick={() => onRemove()} className="text-neutro-b-400 cursor-pointer">
              <img src={Close} alt="fechar" className="fill-neutro-b-400" />
            </a>
          </div>
        )}
      </div>
      {error && <p className="text-red-500 text-xs">{error}</p>}
    </div>
  );
};
