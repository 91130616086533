import React from 'react';
import { IconError } from '@/assets/icons';
import { Button } from '@/components';

interface Props {
  messagePayment?: string;
  onOkConfirm: () => void;
}

export const PaymentNotMadeContent: React.FC<Props> = ({ messagePayment, onOkConfirm }) => (
  <div className="w-full mx-auto text-center">
    <div className="flex flex-col justify-center">
      <IconError height="89px" className="self-center text-[#E64F49] mb-[30px]" />
      <h4 className="font-dmsans font-bold text-neutro-b-400 text-[21px] mb-[20px]">
        Ops! Pagamento não realizado
      </h4>
      <p className="font-dmsans text-base font-normal text-neutro-w-600 mb-[70px]">
        Ocorreu um erro durante o processamento do seu pedido. Por favor, tente novamente finalizar
        o seu pedido.
      </p>
      {messagePayment && (
        <p className="font-dmsans text-base font-normal text-neutro-w-600 mb-[70px]">
          {messagePayment}
        </p>
      )}
      <div className="grid grid-cols-1 gap-5">
        <Button
          type="button"
          title="Voltar para forma de pagamento"
          buttonStyle="primary"
          size="lg"
          className="w-full mb-[25px]"
          onClick={() => onOkConfirm()}
        />
      </div>
    </div>
  </div>
);
