import React, { JSX } from 'react';
import { Ring } from '@uiball/loaders';
import CardType from '@/model/CardType';
import logoAmex from '@/assets/images/logo_amex.png';
import logoDinersClub from '@/assets/images/logo_diners_club.png';
import logoDiscover from '@/assets/images/logo_discover.png';
import logoJcb from '@/assets/images/logo_jcb.png';
import logoMaster from '@/assets/images/logo_mastercard.png';
import logoVisa from '@/assets/images/logo_visa.png';
import logoElo from '@/assets/images/logo_elo.png';
import CardClient from '@/model/CardClient';

interface Props {
  validatingCreditCards: CardClient[];
}

export const ValidatingCreditCardContent: React.FC<Props> = ({
  validatingCreditCards,
}): JSX.Element => {
  const getCardLogo = (cardType: CardType): string => {
    let logo = logoMaster;
    switch (cardType) {
      case CardType.MASTER_CARD:
        logo = logoMaster;
        break;
      case CardType.VISA:
        logo = logoVisa;
        break;
      case CardType.AMERICAN_EXPRESS:
        logo = logoAmex;
        break;
      case CardType.DISCOVER:
        logo = logoDiscover;
        break;
      case CardType.DINERS_CLUB:
        logo = logoDinersClub;
        break;
      case CardType.JCB:
        logo = logoJcb;
        break;
      case CardType.ELO:
        logo = logoElo;
        break;
      default:
        break;
    }
    return logo;
  };
  return (
    <div className="w-full mx-auto text-center">
      <div className="flex flex-col justify-center">
        <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
          <div
            className={`relative transform overflow-hidden rounded-lg text-left transition-all sm:w-full md:w-[75px]`}
          >
            <Ring size={75} lineWeight={5} speed={2} color="#ed8936" />
          </div>
        </div>
        <h4 className="font-dmsans font-bold text-neutro-b-400 text-[21px] mb-[20px]">
          {`Validando ${
            validatingCreditCards.length > 1 ? 'seus cartão' : 'seu cartão'
          } de crédito`}
        </h4>
        {validatingCreditCards.map(data => (
          <div
            key={`Validating-credit-card-${data.id}`}
            className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0"
          >
            <img
              style={{ maxWidth: '36px' }}
              src={getCardLogo(data.cardType)}
              alt="logo"
              className="mr-[30px]"
            />
            <label className="ml-[20px]">{data.cardNumber}</label>
          </div>
        ))}
      </div>
    </div>
  );
};
