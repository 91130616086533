import React, { JSX, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { api, AxiosError } from '@/services/api';
import EventSiteGet from '@/model/EventSiteGet';
import Section from '@/model/Section';
import EventSectionProductsAndCombos from '@/model/EventSectionProductsAndCombos';
import CategorySubGroup from '@/model/CategorySubGroup';
import CheckDiscount from '@/model/CheckDiscount';
import { Address } from '@/model/Address';
import Pdv from '@/model/Pdv';
import { CartEventContent } from '@/features/core/cart/components/CartEventContent';
import { SidebarProps } from '@/components/Sidebar';
import { useSidebar } from '@/hooks/useSidebar';
import { useDispatch, useSelector } from 'react-redux';
// import { setCart, useCart } from '@/redux/cart/cartSlice';
import Cart from '@/features/core/cart/components/Cart';
import CartEventTicketUserData from '@/model/CartEventTicketUserData';
import Payment from '@/model/Payment';
import CardFees from '@/model/CardFees';
import { setLoading, useLoading } from '@/redux/loading/loadingSlice';
import EventProductsAndCombos from '@/model/EventProductsAndCombos';
import EventMap from '@/model/EventMap';
import EventSiteHeader from '@/model/EventSiteHeader';
import { useDialog } from '@/hooks/useDialog';
// import { ShouldShowModalProps, ShowModal } from '../../types';
import { getItem, setItem } from '@/helpers/common/localStorage';
import { REACT_CART } from '@/utils/config';
import { SelectableObject, TicketTypeJson } from '@seatsio/seatsio-react';
import { SelectedObject, ShouldShowModalProps, ShowModal } from '../../types';
import { EventContainer } from './ui';

export interface EventTicketSite {
  id: string;
  name: string;
  unitValue: number;
  fee: number;
  totalValue: number;
  isHalfPrice: boolean;
  quantity: number;
  labels: string[];
  count: number;
  allowDiscount: boolean;
  nameBeforePurchase: boolean;
  requestCpf: boolean;
  payment: Payment;
  hasMap: boolean;
  userDatas: CartEventTicketUserData[];
}
export interface EventDateSectionSite {
  section: Section;
  count: number;
  showModal?: boolean;
  tickets: EventTicketSite[];
}
export interface EventDate {
  id: string;
  name: string;
  passportName?: string;
  address: Address;
  imageUrl: string;
  imageDetailUrl: string;
  websiteDescription: string;
  imageDescriptionUrl: string;
  discountDescription: string;
  count: number;
  isPassport: boolean;
  sections: EventDateSectionSite[];
  discountCoupon?: CheckDiscount;
  groups?: EventGroup[];
  pdvs?: Pdv[];
  date?: Date;
  startDate?: Date;
  endDate?: Date;
  allowDiscount: boolean;
  pixel?: string;
}

export interface EventGroup {
  id: string;
  name: string;
  count: number;
  imageBase64?: string;
  products: EventProductSite[];
  combos: EventComboSite[];
}

export interface EventProductSite {
  id: string;
  name: string;
  imageBase64?: string;
  categorySubGroup: CategorySubGroup;
  amount: number;
  unitValue: number;
  fee: number;
  totalValue: number;
  allowDiscountCoupon: boolean;
  quantity: number;
  partialPayment: boolean;
  fees: CardFees;
}

export interface EventComboSite {
  id: string;
  name: string;
  imageBase64?: string;
  categorySubGroup: CategorySubGroup;
  amount: number;
  unitValue: number;
  fee: number;
  totalValue: number;
  allowDiscountCoupon: boolean;
  quantity: number;
  partialPayment: boolean;
  fees: CardFees;
}

interface EventComponentProps {
  getEvents: () => Promise<EventSiteHeader[]>;
  getEvent: (eventId: string) => Promise<EventSiteGet>;
  getProductsAndCombos: (eventId: string) => Promise<EventProductsAndCombos>;
  getSectionProductsAndCombos: (
    eventId: string,
    sectionId: string,
  ) => Promise<EventSectionProductsAndCombos>;
  getPdv: () => string | undefined;
}

export const EventComponent: React.FC<EventComponentProps> = ({
  getEvents,
  getEvent,
  getProductsAndCombos,
  getSectionProductsAndCombos,
  getPdv,
}): JSX.Element => {
  const [dates, setDates] = useState<EventSiteHeader[]>([]);
  const [eventDate, setEventDate] = useState<EventDate>();
  const [eventMap, setEventMap] = useState<EventMap>();
  const [eventGroups, setEventGroups] = useState<EventGroup[]>([]);
  const [groupId, setGroupId] = useState<string>('');
  const [sectionId, setSectionId] = useState<string>('');
  const [shouldShowModal, setShouldShowModal] = useState<ShowModal>(ShowModal.MAP);
  const [eventKey, setEventKey] = useState<string>();
  const [showProducts, setShowProducts] = useState<boolean>(false);
  const [amountItens, setAmountItens] = useState<number>(0);
  const [totalValue, setTotalValue] = useState<number>(0);
  const [totalValueWithFee, setTotalValueWithFee] = useState<number>(0);
  const [coupon, setCoupon] = useState('');
  const [selectedObjects, setSelectedObjects] = useState<SelectedObject[]>([]);
  const [selectedChartObject, setSelectedChartObject] = useState<SelectableObject>();
  const [deselectedChartObject, setDeselectedChartObject] = useState<SelectableObject>();
  const { visible, onSetVisible, onToggle, title, onChangeTitle } = useDialog();
  const [showMap, setShowMap] = useState<boolean>(false);
  // const { cart } = useSelector(useCart);
  const { loading } = useSelector(useLoading);
  const dispatch = useDispatch();
  const history = useNavigate();
  const { onShowSidebar } = useSidebar();

  const handleGetEvents = async (): Promise<void> => {
    try {
      dispatch(setLoading(true));
      const newDates = await getEvents();
      if (newDates && newDates.length > 0) {
        const newEvent = await getEvent(newDates[0].id);

        const sections: EventDateSectionSite[] = [];
        try {
          const responseEventMap = await api.get<EventMap>(`/event/${newEvent.id}/map`);
          setEventMap(responseEventMap.data);

          let eventKeyCount = 0;
          const uniqueEventKeys = new Set<string>();
          const newEventMap = responseEventMap.data;

          if (newEventMap && newEventMap.sections && newEventMap.sections.length > 0) {
            newEventMap.sections.forEach(section => {
              section.tickets.forEach(ticket => {
                if (
                  ticket.eventKey &&
                  ticket.ticket.hasMap === true &&
                  !uniqueEventKeys.has(ticket.eventKey)
                ) {
                  uniqueEventKeys.add(ticket.eventKey);
                  eventKeyCount += 1;
                }
              });
            });
          }

          if (eventKeyCount < 1) {
            setShowMap(true);
          }
        } catch {
          window.console.log('No map');
        }
        if (newEvent.sections && newEvent.sections.length > 0) {
          newEvent.sections.forEach(section => {
            const tickets: EventTicketSite[] = [];
            section.tickets.forEach(data => {
              const eventTicketSite: EventTicketSite = {
                id: data.id,
                name: data.name,
                unitValue: data.unitValue,
                isHalfPrice: data.isHalfPrice,
                quantity: 0,
                fee: data.fee,
                totalValue: data.unitValue,
                count: data.count,
                labels: [],
                allowDiscount: data.allowDiscount,
                nameBeforePurchase: data.nameBeforePurchase,
                requestCpf: data.requestCpf,
                payment: data.payment,
                userDatas: [],
                hasMap: data.hasMap,
              };
              tickets.push(eventTicketSite);
            });
            const eventDateSectionSite: EventDateSectionSite = {
              section: section.section,
              count: 0,
              tickets,
            };
            sections.push(eventDateSectionSite);
          });
        }

        const newEventDate: EventDate = {
          id: newEvent.id,
          name: newEvent.name,
          passportName: newEvent.passportName,
          imageUrl: newEvent.imageDetailUrl,
          imageDetailUrl: newEvent.imageUrl,
          address: newEvent.address,
          websiteDescription: newEvent.websiteDescription,
          imageDescriptionUrl: newEvent.imageDescriptionUrl,
          discountDescription: newEvent.discountDescription,
          count: 0,
          pdvs: newEvent.pdvs,
          sections,
          date: newEvent.startDate,
          startDate: newEvent.startDate,
          endDate: newEvent.endDate,
          isPassport: false,
          allowDiscount: false,
          pixel: newEvent.pixel,
        };

        const productsAndCombos = await getProductsAndCombos(newEvent.id);

        if (productsAndCombos) {
          const listGroups: EventGroup[] = [];

          let allowDiscountProduct = false;

          if (productsAndCombos.products && productsAndCombos.products.length > 0) {
            productsAndCombos.products.forEach(data => {
              let found = false;
              if (data.allowDiscountCoupon && !allowDiscountProduct) {
                allowDiscountProduct = true;
              }
              // eslint-disable-next-line no-plusplus
              for (let g = 0; g < listGroups.length; g++) {
                if (listGroups[g].id === data.categorySubGroup.categoryGroup.id) {
                  const eventProductSite: EventProductSite = {
                    ...data,
                    quantity: 0,
                    fee: data.fee,
                    totalValue: data.unitValue,
                  };
                  listGroups[g].products.push(eventProductSite);
                  found = true;
                  break;
                }
              }
              if (!found) {
                const eventGroup: EventGroup = {
                  id: data.categorySubGroup.categoryGroup.id,
                  name: data.categorySubGroup.categoryGroup.name,
                  count: 0,
                  imageBase64: data.categorySubGroup.categoryGroup.imageBase64,
                  products: [],
                  combos: [],
                };
                const eventProductSite: EventProductSite = {
                  ...data,
                  quantity: 0,
                  fee: data.fee,
                  totalValue: data.unitValue,
                };
                eventGroup.products.push(eventProductSite);
                listGroups.push(eventGroup);
              }
            });
          }
          if (productsAndCombos.combos && productsAndCombos.combos.length > 0) {
            productsAndCombos.combos.forEach(data => {
              let found = false;
              if (data.allowDiscountCoupon && !allowDiscountProduct) {
                allowDiscountProduct = true;
              }
              // eslint-disable-next-line no-plusplus
              for (let c = 0; c < listGroups.length; c++) {
                if (listGroups[c].id === data.categorySubGroup.categoryGroup.id) {
                  const eventComboSite: EventComboSite = {
                    ...data,
                    quantity: 0,
                    fee: data.fee,
                    totalValue: data.unitValue,
                  };
                  listGroups[c].combos.push(eventComboSite);
                  found = true;
                  break;
                }
              }
              if (!found) {
                const eventGroup: EventGroup = {
                  id: data.categorySubGroup.categoryGroup.id,
                  name: data.categorySubGroup.categoryGroup.name,
                  count: 0,
                  imageBase64: data.categorySubGroup.categoryGroup.imageBase64,
                  products: [],
                  combos: [],
                };
                const eventComboSite: EventComboSite = {
                  ...data,
                  quantity: 0,
                  fee: data.fee,
                  totalValue: data.unitValue,
                };
                eventGroup.combos.push(eventComboSite);
                listGroups.push(eventGroup);
              }
            });
          }
          let allowDiscount = false;

          if (newEventDate) {
            newEventDate.groups = listGroups;
            allowDiscount = newEventDate.allowDiscount;
            if (allowDiscountProduct && allowDiscount) {
              allowDiscountProduct = true;
            } else if (allowDiscountProduct && !allowDiscount) {
              allowDiscountProduct = true;
            } else {
              allowDiscountProduct = false;
            }
            newEventDate.allowDiscount = allowDiscountProduct;
          }
          setEventGroups(listGroups);
        }
        setEventDate(newEventDate);
        newDates[0].eventDate = newEventDate;
      }

      if (newDates && newDates.length > 0) {
        const indexs: number[] = [];
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < newDates.length; i++) {
          if (
            newDates[i].eventDate &&
            newDates[i].eventDate?.groups?.length === 0 &&
            newDates[i].eventDate?.sections?.length === 0
          ) {
            indexs.push(i);
          }
          newDates[i].count = 0;
        }
        indexs.forEach(data => {
          newDates.splice(data, 1);
        });

        setDates(newDates);
      }
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleSelectDate = async (eventSiteHeader: EventSiteHeader): Promise<void> => {
    try {
      dispatch(setLoading(true));
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < dates.length; i++) {
        if (dates[i].id === eventSiteHeader.id) {
          if (!dates[i].eventDate) {
            // eslint-disable-next-line no-await-in-loop
            const newEvent = await getEvent(eventSiteHeader.id);
            try {
              // eslint-disable-next-line no-await-in-loop
              const responseEventMap = await api.get<EventMap>(`/event/${newEvent.id}/map`);
              setEventMap(responseEventMap.data);
            } catch {
              window.console.log('No map');
            }
            const sections: EventDateSectionSite[] = [];
            if (newEvent.sections && newEvent.sections.length > 0) {
              newEvent.sections.forEach(section => {
                const tickets: EventTicketSite[] = [];
                section.tickets.forEach(data => {
                  const eventTicketSite: EventTicketSite = {
                    id: data.id,
                    name: data.name,
                    unitValue: data.unitValue,
                    isHalfPrice: data.isHalfPrice,
                    quantity: 0,
                    fee: data.fee,
                    totalValue: data.unitValue,
                    labels: [],
                    count: data.count,
                    allowDiscount: data.allowDiscount,
                    nameBeforePurchase: data.nameBeforePurchase,
                    requestCpf: data.requestCpf,
                    payment: data.payment,
                    hasMap: data.hasMap,
                    userDatas: [],
                  };
                  tickets.push(eventTicketSite);
                });
                const eventDateSectionSite: EventDateSectionSite = {
                  section: section.section,
                  count: 0,
                  tickets,
                };
                sections.push(eventDateSectionSite);
              });
            }
            const newEventDate: EventDate = {
              id: newEvent.id,
              name: newEvent.name,
              imageUrl: newEvent.imageDetailUrl,
              imageDetailUrl: newEvent.imageUrl,
              address: newEvent.address,
              websiteDescription: newEvent.websiteDescription,
              imageDescriptionUrl: newEvent.imageDescriptionUrl,
              discountDescription: newEvent.discountDescription,
              count: 0,
              pdvs: newEvent.pdvs,
              sections,
              date: newEvent.startDate,
              startDate: newEvent.startDate,
              endDate: newEvent.endDate,
              isPassport: false,
              allowDiscount: false,
            };

            // eslint-disable-next-line no-await-in-loop
            const productsAndCombos = await getProductsAndCombos(newEvent.id);

            if (productsAndCombos) {
              const listGroups: EventGroup[] = [];

              let allowDiscountProduct = false;

              if (productsAndCombos.products && productsAndCombos.products.length > 0) {
                productsAndCombos.products.forEach(data => {
                  let found = false;
                  if (data.allowDiscountCoupon && !allowDiscountProduct) {
                    allowDiscountProduct = true;
                  }
                  // eslint-disable-next-line no-plusplus
                  for (let g = 0; g < listGroups.length; g++) {
                    if (listGroups[g].id === data.categorySubGroup.categoryGroup.id) {
                      const eventProductSite: EventProductSite = {
                        ...data,
                        quantity: 0,
                        fee: data.fee,
                        totalValue: data.unitValue,
                      };
                      listGroups[g].products.push(eventProductSite);
                      found = true;
                      break;
                    }
                  }
                  if (!found) {
                    const eventGroup: EventGroup = {
                      id: data.categorySubGroup.categoryGroup.id,
                      name: data.categorySubGroup.categoryGroup.name,
                      count: 0,
                      imageBase64: data.categorySubGroup.categoryGroup.imageBase64,
                      products: [],
                      combos: [],
                    };
                    const eventProductSite: EventProductSite = {
                      ...data,
                      quantity: 0,
                      fee: data.fee,
                      totalValue: data.unitValue,
                    };
                    eventGroup.products.push(eventProductSite);
                    listGroups.push(eventGroup);
                  }
                });
              }
              if (productsAndCombos.combos && productsAndCombos.combos.length > 0) {
                productsAndCombos.combos.forEach(data => {
                  let found = false;
                  if (data.allowDiscountCoupon && !allowDiscountProduct) {
                    allowDiscountProduct = true;
                  }
                  // eslint-disable-next-line no-plusplus
                  for (let c = 0; c < listGroups.length; c++) {
                    if (listGroups[c].id === data.categorySubGroup.categoryGroup.id) {
                      const eventComboSite: EventComboSite = {
                        ...data,
                        quantity: 0,
                        fee: data.fee,
                        totalValue: data.unitValue,
                      };
                      listGroups[c].combos.push(eventComboSite);
                      found = true;
                      break;
                    }
                  }
                  if (!found) {
                    const eventGroup: EventGroup = {
                      id: data.categorySubGroup.categoryGroup.id,
                      name: data.categorySubGroup.categoryGroup.name,
                      count: 0,
                      imageBase64: data.categorySubGroup.categoryGroup.imageBase64,
                      products: [],
                      combos: [],
                    };
                    const eventComboSite: EventComboSite = {
                      ...data,
                      quantity: 0,
                      fee: data.fee,
                      totalValue: data.unitValue,
                    };
                    eventGroup.combos.push(eventComboSite);
                    listGroups.push(eventGroup);
                  }
                });
              }
              let allowDiscount = false;

              if (newEventDate) {
                newEventDate.groups = listGroups;
                allowDiscount = newEventDate.allowDiscount;
                if (allowDiscountProduct && allowDiscount) {
                  allowDiscountProduct = true;
                } else if (allowDiscountProduct && !allowDiscount) {
                  allowDiscountProduct = true;
                } else {
                  allowDiscountProduct = false;
                }
                newEventDate.allowDiscount = allowDiscountProduct;
              }
              setEventGroups(listGroups);
            }
            setEventDate(newEventDate);
            dates[i].eventDate = newEventDate;
          } else {
            setEventDate(dates[i].eventDate);
          }
          break;
        }
      }
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleGetSectionProductsAndCombos = async (
    selectedEventId: string,
    selectedSectionId: string,
  ): Promise<void> => {
    dispatch(setLoading(true));
    try {
      const eventProductsAndCombos = await getSectionProductsAndCombos(
        selectedEventId,
        selectedSectionId,
      );
      const listGroups: EventGroup[] = [];
      eventGroups.forEach(data => {
        listGroups.push(data);
      });
      if (eventProductsAndCombos) {
        let allowDiscountProduct = false;
        if (eventProductsAndCombos.products && eventProductsAndCombos.products.length > 0) {
          eventProductsAndCombos.products.forEach(data => {
            let found = false;
            if (data.allowDiscountCoupon && !allowDiscountProduct) {
              allowDiscountProduct = true;
            }
            // eslint-disable-next-line no-plusplus
            for (let g = 0; g < listGroups.length; g++) {
              if (listGroups[g].id === data.categorySubGroup.categoryGroup.id) {
                const eventProductSite: EventProductSite = {
                  ...data,
                  quantity: 0,
                  fee: data.fee,
                  totalValue: data.unitValue,
                };
                listGroups[g].products.push(eventProductSite);
                found = true;
                break;
              }
            }
            if (!found) {
              const eventGroup: EventGroup = {
                id: data.categorySubGroup.categoryGroup.id,
                name: data.categorySubGroup.categoryGroup.name,
                count: 0,
                imageBase64: data.categorySubGroup.categoryGroup.imageBase64,
                products: [],
                combos: [],
              };
              const eventProductSite: EventProductSite = {
                ...data,
                quantity: 0,
                fee: data.fee,
                totalValue: data.unitValue,
              };
              eventGroup.products.push(eventProductSite);
              listGroups.push(eventGroup);
            }
          });
        }
        if (eventProductsAndCombos.combos && eventProductsAndCombos.combos.length > 0) {
          eventProductsAndCombos.combos.forEach(data => {
            let found = false;
            if (data.allowDiscountCoupon && !allowDiscountProduct) {
              allowDiscountProduct = true;
            }
            // eslint-disable-next-line no-plusplus
            for (let c = 0; c < listGroups.length; c++) {
              if (listGroups[c].id === data.categorySubGroup.categoryGroup.id) {
                const eventComboSite: EventComboSite = {
                  ...data,
                  quantity: 0,
                  fee: data.fee,
                  totalValue: data.unitValue,
                };
                listGroups[c].combos.push(eventComboSite);
                found = true;
                break;
              }
            }
            if (!found) {
              const eventGroup: EventGroup = {
                id: data.categorySubGroup.categoryGroup.id,
                name: data.categorySubGroup.categoryGroup.name,
                count: 0,
                imageBase64: data.categorySubGroup.categoryGroup.imageBase64,
                products: [],
                combos: [],
              };
              const eventComboSite: EventComboSite = {
                ...data,
                quantity: 0,
                fee: data.fee,
                totalValue: data.unitValue,
              };
              eventGroup.combos.push(eventComboSite);
              listGroups.push(eventGroup);
            }
          });
        }

        if (eventDate) {
          const newEvent: EventDate = {
            ...eventDate,
            groups: listGroups,
          };
          let allowDiscount = false;
          allowDiscount = eventDate.allowDiscount;
          if (allowDiscountProduct && allowDiscount) {
            allowDiscountProduct = true;
          } else if (allowDiscountProduct && !allowDiscount) {
            allowDiscountProduct = true;
          } else {
            allowDiscountProduct = false;
          }
          newEvent.allowDiscount = allowDiscountProduct;
          setEventDate(newEvent);

          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < dates.length; i++) {
            if (dates[i].id === newEvent.id) {
              dates[i].eventDate = newEvent;
              break;
            }
          }
          setDates(dates);
        }
      }
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleOnChartObjectSelected = (
    object: SelectableObject,
    _selectedTicketType: TicketTypeJson,
  ): void => {
    setSelectedChartObject(object);
  };

  const handleIncrementTicket = (index: number, chartSectionId?: string, label?: string): void => {
    if (eventDate) {
      // eslint-disable-next-line no-plusplus
      for (let d = 0; d < dates.length; d++) {
        if (dates[d].id === eventDate.id) {
          const newEventDate: EventDate = {
            ...eventDate,
            count: eventDate.count + 1,
          };
          if (eventDate.sections && eventDate.sections.length > 0) {
            // eslint-disable-next-line no-plusplus
            for (let s = 0; s < eventDate.sections.length; s++) {
              const newSection = {
                ...eventDate.sections[s],
              };
              let isSection = false;
              if (chartSectionId) {
                if (chartSectionId === newSection.section.id) {
                  isSection = true;
                }
              } else if (newSection.section.id === sectionId) {
                isSection = true;
              }
              if (isSection) {
                const tickets: EventTicketSite[] = [];
                // eslint-disable-next-line no-plusplus
                for (let i = 0; i < newSection.tickets.length; i++) {
                  const newTicket = newSection.tickets[i];
                  if (i === index) {
                    newTicket.quantity += 1;
                    if (label) {
                      newTicket.labels.push(label);
                      setSelectedChartObject(undefined);
                    }
                    setAmountItens(amountItens + 1);
                    setTotalValue(totalValue + Number(newTicket.unitValue));
                    setTotalValueWithFee(
                      totalValueWithFee + Number(newTicket.unitValue) * Number(newTicket.fee),
                    );
                  }
                  tickets.push(newTicket);
                }
                newSection.count += 1;
                newSection.tickets = tickets;
                eventDate.sections[s] = newSection;
              }
            }
            newEventDate.sections = eventDate.sections;
          }
          setEventDate(newEventDate);
          dates[d].eventDate = newEventDate;
          dates[d].count += 1;
          setDates(dates);
          break;
        }
      }
    }
  };

  const handleOnSelectChartObjectSelected = (): void => {
    if (
      selectedChartObject &&
      selectedChartObject.category &&
      selectedChartObject.category.label &&
      eventMap
    ) {
      const newSelectedObject: SelectedObject = {
        label: selectedChartObject.label,
        ticketType: selectedChartObject.selectedTicketType as string,
      };
      const newSelectedObjects: SelectedObject[] = [];
      let existsSeletedObject = false;
      selectedObjects.forEach(data => {
        if (
          data.label === newSelectedObject.label &&
          data.ticketType === newSelectedObject.ticketType
        ) {
          existsSeletedObject = true;
        }
        newSelectedObjects.push(data);
      });
      if (!existsSeletedObject) {
        newSelectedObjects.push(newSelectedObject);
        setSelectedObjects(newSelectedObjects);
        if (eventMap.sections && eventMap.sections.length > 0) {
          // eslint-disable-next-line no-plusplus
          for (let s = 0; s < eventMap.sections.length; s++) {
            if (eventMap.sections[s].section.name === selectedChartObject.category.label) {
              if (eventMap.sections[s].tickets && eventMap.sections[s].tickets.length > 0) {
                // eslint-disable-next-line no-plusplus
                for (let t = 0; t < eventMap.sections[s].tickets.length; t++) {
                  if (
                    eventMap.sections[s].tickets[t].ticket.name ===
                    selectedChartObject.labels.section
                  ) {
                    handleIncrementTicket(
                      t,
                      eventMap.sections[s].section.id,
                      selectedChartObject.label,
                    );
                  }
                }
              }
              break;
            }
          }
        }
      }
    }
  };

  const handleOnChartObjectDeselected = (
    object: SelectableObject,
    _selectedTicketType: TicketTypeJson,
  ): void => {
    setDeselectedChartObject(object);
  };

  const handleDecrementTicket = (index: number, chartSectionId?: string, label?: string): void => {
    if (eventDate) {
      // eslint-disable-next-line no-plusplus
      for (let d = 0; d < dates.length; d++) {
        if (dates[d].id === eventDate.id) {
          const newEventDate: EventDate = {
            ...eventDate,
            count: eventDate.count - 1,
          };
          if (eventDate.sections && eventDate.sections.length > 0) {
            const sections: EventDateSectionSite[] = [];
            eventDate.sections.forEach(section => {
              const newSection: EventDateSectionSite = {
                ...section,
              };
              if (
                (chartSectionId && chartSectionId === section.section.id) ||
                section.section.id === sectionId
              ) {
                const tickets: EventTicketSite[] = [];
                // eslint-disable-next-line no-plusplus
                for (let i = 0; i < section.tickets.length; i++) {
                  const newTicket = section.tickets[i];
                  if (i === index) {
                    newTicket.quantity -= 1;
                    if (label) {
                      let labelIndex = -1;
                      // eslint-disable-next-line no-plusplus
                      for (let l = 0; l < newTicket.labels.length; l++) {
                        if (label === newTicket.labels[l]) {
                          labelIndex = l;
                          break;
                        }
                      }
                      newTicket.labels.splice(labelIndex, 1);
                      setDeselectedChartObject(undefined);
                    }
                    setAmountItens(amountItens - 1);
                    setTotalValue(totalValue - Number(newTicket.unitValue));
                    setTotalValueWithFee(
                      totalValueWithFee - Number(newTicket.unitValue) * Number(newTicket.fee),
                    );
                  }

                  tickets.push(newTicket);
                }
                newSection.count -= 1;
                newSection.tickets = tickets;
                sections.push(newSection);
              } else {
                sections.push(newSection);
              }
            });
            newEventDate.sections = sections;
          }
          setEventDate(newEventDate);
          dates[d].eventDate = newEventDate;
          dates[d].count -= 1;
          break;
        }
      }
      setDates(dates);
    }
  };

  const handleOnChartObjectRemoveTicket = (): void => {
    if (
      deselectedChartObject &&
      deselectedChartObject.category &&
      deselectedChartObject.category.label &&
      eventMap
    ) {
      const newSelectedObject: SelectedObject = {
        label: deselectedChartObject.label,
        ticketType: deselectedChartObject.selectedTicketType as string,
      };
      const newSelectedObjects: SelectedObject[] = [];
      let existsSeletedObject = false;
      selectedObjects.forEach(data => {
        if (
          data.label === newSelectedObject.label &&
          data.ticketType === newSelectedObject.ticketType
        ) {
          existsSeletedObject = true;
        } else {
          newSelectedObjects.push(data);
        }
      });
      if (existsSeletedObject) {
        setSelectedObjects(newSelectedObjects);
        if (eventMap.sections && eventMap.sections.length > 0) {
          // eslint-disable-next-line no-plusplus
          for (let s = 0; s < eventMap.sections.length; s++) {
            if (eventMap.sections[s].section.name === deselectedChartObject.category.label) {
              if (eventMap.sections[s].tickets && eventMap.sections[s].tickets.length > 0) {
                // eslint-disable-next-line no-plusplus
                for (let t = 0; t < eventMap.sections[s].tickets.length; t++) {
                  if (
                    eventMap.sections[s].tickets[t].ticket.name ===
                    deselectedChartObject.labels.section
                  ) {
                    handleDecrementTicket(
                      t,
                      eventMap.sections[s].section.id,
                      deselectedChartObject.label,
                    );
                  }
                }
              }
              break;
            }
          }
        }
      }
    }
    /*
    const split = selectedTicketType.description.split(' - ');
    const sectionName = split[0];
    const ticketName = selectedTicketType.label;
    let chartSectionId = '';
    let ticketId = '';
    if (eventMap && eventMap.sections && eventMap.sections.length > 0) {
      for (let s = 0; s < eventMap.sections.length; s++) {
        if (eventMap.sections[s].name === sectionName) {
          chartSectionId = eventMap.sections[s].section.id;
          for (let t = 0; t < eventMap.sections[s].tickets.length; t++) {
            if (eventMap.sections[s].tickets[t].ticket.name === ticketName) {
              ticketId = eventMap.sections[s].tickets[t].ticket.id;
              break;
            }
          }
          break;
        }
      }
    }
    let index = -1;
    if (chartSectionId.trim().length > 0 && ticketId.trim().length > 0) {
      if (eventDate) {
        for (let s = 0; s < eventDate.sections.length; s++) {
          if (eventDate.sections[s].section.id === chartSectionId) {
            for (let i = 0; i < eventDate.sections[s].tickets.length; i++) {
              if (
                eventDate.sections[s].tickets[i].id === ticketId &&
                eventDate.sections[s].tickets[i].name === ticketName
              ) {
                index = i;
                break;
              }
            }
            break;
          }
        }
      }
    }
    handleDecrementTicket(index, chartSectionId, object.label);
    */
  };

  const handleSelectSection = async (selectedSection: Section): Promise<void> => {
    setSectionId(selectedSection.id);
    if (eventDate) {
      let allowDiscountTicket = false;
      const newEventDate: EventDate = {
        ...eventDate,
      };

      if (eventDate.sections && eventDate.sections.length > 0) {
        const sections: EventDateSectionSite[] = [];
        eventDate.sections.forEach(section => {
          const newSection: EventDateSectionSite = {
            ...section,
          };
          if (section.section.id === selectedSection.id) {
            const tickets: EventTicketSite[] = [];
            if (section.tickets && section.tickets.length > 0) {
              section.tickets.forEach(ticket => {
                if (ticket.allowDiscount && !allowDiscountTicket) {
                  allowDiscountTicket = true;
                }
                if (!ticket.quantity || !ticket.unitValue) {
                  const newTicket: EventTicketSite = {
                    ...ticket,
                    quantity: 0,
                  };
                  tickets.push(newTicket);
                } else {
                  tickets.push(ticket);
                }
              });
            }
            newSection.tickets = tickets;
          }
          sections.push(newSection);
        });
        newEventDate.sections = sections;
      }

      if (allowDiscountTicket && eventDate.allowDiscount) {
        allowDiscountTicket = true;
      } else if (allowDiscountTicket && !eventDate.allowDiscount) {
        allowDiscountTicket = true;
      } else {
        allowDiscountTicket = false;
      }
      newEventDate.allowDiscount = allowDiscountTicket;

      setEventDate(newEventDate);
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < dates.length; i++) {
        if (dates[i].id === newEventDate.id) {
          dates[i].eventDate = newEventDate;
          break;
        }
      }
      setDates(dates);
      await handleGetSectionProductsAndCombos(eventDate.id, selectedSection.id);
    }
  };

  const handleCheckShowProducts = (checked: boolean): void => {
    setShowProducts(checked);
  };

  const handleSelectGroup = (group: EventGroup): void => {
    setGroupId(group.id);
  };

  const handleIncrementProduct = (index: number): void => {
    if (eventDate) {
      // eslint-disable-next-line no-plusplus
      for (let d = 0; d < dates.length; d++) {
        if (dates[d].id === eventDate.id) {
          const newEventDate: EventDate = {
            ...eventDate,
            count: eventDate.count + 1,
          };
          const groups: EventGroup[] = [];
          if (eventDate.groups && eventDate.groups.length > 0) {
            eventDate.groups.forEach(group => {
              if (group.id === groupId) {
                const newGroup: EventGroup = {
                  ...group,
                  count: group.count + 1,
                };
                if (group.products && group.products.length > 0) {
                  const products: EventProductSite[] = [];
                  // eslint-disable-next-line no-plusplus
                  for (let i = 0; i < group.products.length; i++) {
                    const newProduct = group.products[i];
                    if (i === index) {
                      newProduct.quantity += 1;
                      setAmountItens(amountItens + 1);
                      setTotalValue(totalValue + Number(newProduct.unitValue));
                      setTotalValueWithFee(
                        Number(totalValueWithFee) +
                          Number(newProduct.unitValue) * Number(newProduct.fee),
                      );
                    }
                    products.push(newProduct);
                  }
                  newGroup.products = products;
                }
                groups.push(newGroup);
              } else {
                groups.push(group);
              }
            });
            newEventDate.groups = groups;
          }
          setEventDate(newEventDate);
          dates[d].eventDate = newEventDate;
          dates[d].count += 1;
          break;
        }
      }
      setDates(dates);
    }
  };

  const handleDecrementProduct = (index: number): void => {
    if (eventDate) {
      // eslint-disable-next-line no-plusplus
      for (let d = 0; d < dates.length; d++) {
        if (dates[d].id === eventDate.id) {
          const newEventDate: EventDate = {
            ...eventDate,
            count: eventDate.count - 1,
          };
          const groups: EventGroup[] = [];
          if (eventDate.groups && eventDate.groups.length > 0) {
            eventDate.groups.forEach(group => {
              if (group.id === groupId) {
                const newGroup: EventGroup = {
                  ...group,
                  count: group.count - 1,
                };
                if (group.products && group.products.length > 0) {
                  const products: EventProductSite[] = [];
                  // eslint-disable-next-line no-plusplus
                  for (let i = 0; i < group.products.length; i++) {
                    const newProduct = group.products[i];
                    if (i === index) {
                      newProduct.quantity -= 1;
                      setAmountItens(amountItens - 1);
                      setTotalValue(totalValue - Number(newProduct.unitValue));
                      setTotalValueWithFee(
                        totalValueWithFee - Number(newProduct.unitValue) * Number(newProduct.fee),
                      );
                    }
                    products.push(newProduct);
                  }
                  newGroup.products = products;
                }
                groups.push(newGroup);
              } else {
                groups.push(group);
              }
            });
            newEventDate.groups = groups;
          }
          setEventDate(newEventDate);
          dates[d].eventDate = newEventDate;
          dates[d].count -= 1;
          break;
        }
      }
      setDates(dates);
    }
  };

  const handleIncrementCombo = (index: number): void => {
    if (eventDate) {
      // eslint-disable-next-line no-plusplus
      for (let d = 0; d < dates.length; d++) {
        if (dates[d].id === eventDate.id) {
          const newEventDate: EventDate = {
            ...eventDate,
            count: eventDate.count + 1,
          };
          const groups: EventGroup[] = [];
          if (eventDate.groups && eventDate.groups.length > 0) {
            eventDate.groups.forEach(group => {
              if (group.id === groupId) {
                const newGroup: EventGroup = {
                  ...group,
                  count: group.count + 1,
                };
                if (group.combos && group.combos.length > 0) {
                  const combos: EventComboSite[] = [];
                  // eslint-disable-next-line no-plusplus
                  for (let i = 0; i < group.combos.length; i++) {
                    const newCombo = group.combos[i];
                    if (i === index) {
                      newCombo.quantity += 1;
                      setAmountItens(amountItens + 1);
                      setTotalValue(totalValue + Number(newCombo.unitValue));
                      setTotalValueWithFee(
                        totalValueWithFee + Number(newCombo.unitValue) * Number(newCombo.fee),
                      );
                    }
                    combos.push(newCombo);
                  }
                  newGroup.combos = combos;
                }
                groups.push(newGroup);
              } else {
                groups.push(group);
              }
            });
            newEventDate.groups = groups;
          }
          setEventDate(newEventDate);
          dates[d].eventDate = newEventDate;
          dates[d].count += 1;
          break;
        }
      }
      setDates(dates);
    }
  };

  const handleDecrementCombo = (index: number): void => {
    if (eventDate) {
      // eslint-disable-next-line no-plusplus
      for (let d = 0; d < dates.length; d++) {
        if (dates[d].id === eventDate.id) {
          const newEventDate: EventDate = {
            ...eventDate,
            count: eventDate.count + 1,
          };
          const groups: EventGroup[] = [];
          if (eventDate.groups && eventDate.groups.length > 0) {
            eventDate.groups.forEach(group => {
              if (group.id === groupId) {
                const newGroup: EventGroup = {
                  ...group,
                  count: group.count - 1,
                };
                if (group.combos && group.combos.length > 0) {
                  const combos: EventComboSite[] = [];
                  // eslint-disable-next-line no-plusplus
                  for (let i = 0; i < group.combos.length; i++) {
                    const newCombo = group.combos[i];
                    if (i === index) {
                      newCombo.quantity -= 1;
                      setAmountItens(amountItens - 1);
                      setTotalValue(totalValue - Number(newCombo.unitValue));
                      setTotalValueWithFee(
                        totalValueWithFee - Number(newCombo.unitValue) * Number(newCombo.fee),
                      );
                    }
                    combos.push(newCombo);
                  }
                  newGroup.combos = combos;
                }
                groups.push(newGroup);
              } else {
                groups.push(group);
              }
            });
            newEventDate.groups = groups;
          }
          setEventDate(newEventDate);
          dates[d].eventDate = newEventDate;
          dates[d].count -= 1;
          break;
        }
      }
      setDates(dates);
    }
  };

  const handleChangeCoupon = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setCoupon(e.target.value);
  };

  const handleApplyCoupon = async (): Promise<void> => {
    const cartStorage = getItem(REACT_CART as string) as string;
    let cartVariable: Cart | undefined;

    if (cartStorage && cartStorage.length > 0) {
      cartVariable = JSON.parse(cartStorage) as Cart;
    } else {
      cartVariable = {
        events: [],
        discountCoupon: {} as CheckDiscount,
        amountItens: 0,
        totalValue: 0,
        totalValueWithFee: 0,
        originalValueWithPaymentFee: 0,
        totalValueWithPaymentFee: 0,
        pdvId: undefined,
      };
    }
    const cart = cartVariable as Cart;
    if (cart.discountCoupon && cart.discountCoupon.discountCoupon) {
      toast.warning('Você já possui um desconto no carrinho');
    } else {
      try {
        dispatch(setLoading(true));
        const response = await api.get<CheckDiscount>(
          `/event/${eventDate?.id}/discount-coupon/${coupon}`,
        );
        if (eventDate) {
          const newEvent: EventDate = {
            ...eventDate,
            discountCoupon: response.data,
          };
          setEventDate(newEvent);
          if (response.data.discountCoupon) {
            setTotalValueWithFee(totalValueWithFee - Number(response.data.discountCoupon.discount));
          } else if (response.data.voucher) {
            setTotalValueWithFee(totalValueWithFee - Number(response.data.voucher.value));
          }
        }
      } catch (err) {
        const error = err as AxiosError;
        if (error.response?.status === 404) {
          toast.warning('Código de cupom inválido!');
        }
      } finally {
        dispatch(setLoading(false));
      }
    }
  };

  const handleRemoveCoupon = (): void => {
    if (eventDate) {
      const newEvent: EventDate = {
        ...eventDate,
        discountCoupon: {} as CheckDiscount,
      };
      setEventDate(newEvent);
    }
  };

  const handleClearEvent = (): void => {
    if (eventDate) {
      const newEventDate: EventDate = {
        ...eventDate,
        count: 0,
        discountCoupon: {} as CheckDiscount,
      };
      if (eventDate.sections && eventDate.sections.length > 0) {
        const sections: EventDateSectionSite[] = [];
        eventDate.sections.forEach(section => {
          const newSection: EventDateSectionSite = {
            ...section,
            count: 0,
          };
          if (section.tickets && section.tickets.length > 0) {
            const tickets: EventTicketSite[] = [];
            section.tickets.forEach(ticket => {
              const newTicket: EventTicketSite = {
                ...ticket,
                quantity: 0,
              };
              tickets.push(newTicket);
            });
            newSection.tickets = tickets;
          }
          sections.push(newSection);
          newEventDate.sections = sections;
        });
      }
      if (eventDate.groups && eventDate.groups.length > 0) {
        const groups: EventGroup[] = [];
        eventDate.groups.forEach(group => {
          const newGroup: EventGroup = {
            ...group,
            count: 0,
          };
          if (group.products && group.products.length > 0) {
            const products: EventProductSite[] = [];
            group.products.forEach(product => {
              const newProduct: EventProductSite = {
                ...product,
                quantity: 0,
              };
              products.push(newProduct);
            });
            newGroup.products = products;
          }
          if (group.combos && group.combos.length > 0) {
            const combos: EventComboSite[] = [];
            group.combos.forEach(combo => {
              const newCombo: EventComboSite = {
                ...combo,
                quantity: 0,
              };
              combos.push(newCombo);
            });
            newGroup.combos = combos;
          }
          groups.push(newGroup);
        });
        newEventDate.groups = groups;
      }
      setEventDate(newEventDate);
    }

    setAmountItens(0);
    setTotalValue(0);
    setTotalValueWithFee(0);
  };

  const handleAddCart = (): void => {
    onShowSidebar({
      content: <CartEventContent history={history} />,
      title: 'Meu carrinho',
    } as SidebarProps);
    // const json = JSON.stringify(cart);
    // const newCart = JSON.parse(json) as Cart;
    const cartStorage = getItem(REACT_CART as string) as string;
    let cartVariable: Cart | undefined;

    if (cartStorage && cartStorage.length > 0) {
      cartVariable = JSON.parse(cartStorage) as Cart;
    } else {
      cartVariable = {
        events: [],
        discountCoupon: {} as CheckDiscount,
        amountItens: 0,
        totalValue: 0,
        totalValueWithFee: 0,
        originalValueWithPaymentFee: 0,
        totalValueWithPaymentFee: 0,
        pdvId: undefined,
      };
    }
    const newCart = cartVariable as Cart;
    let discountCoupon: CheckDiscount | undefined;
    dates.forEach(data => {
      if (data.eventDate) {
        const newEventDate = data.eventDate;
        let foundEvent = false;
        newCart.events.forEach(cartEvent => {
          if (cartEvent.discountCoupon) {
            if (!discountCoupon) {
              discountCoupon = cartEvent.discountCoupon;
            }
          }
          if (cartEvent.id === data.id) {
            foundEvent = true;
            if (newEventDate.sections && newEventDate.sections.length > 0) {
              newEventDate.sections.forEach(section => {
                if (section.tickets && section.tickets.length > 0) {
                  section.tickets.forEach(ticket => {
                    if (ticket.quantity > 0) {
                      let found = false;
                      if (cartEvent.sections && cartEvent.sections.length > 0) {
                        // eslint-disable-next-line no-plusplus
                        for (let s = 0; s < cartEvent.sections.length; s++) {
                          if (
                            cartEvent.sections[s].tickets &&
                            cartEvent.sections[s].tickets.length > 0
                          ) {
                            // eslint-disable-next-line no-plusplus
                            for (let t = 0; t < cartEvent.sections[s].tickets.length; t++) {
                              if (ticket.id === cartEvent.sections[s].tickets[t].id) {
                                found = true;
                                const userDatas: CartEventTicketUserData[] = [];
                                // eslint-disable-next-line no-plusplus
                                for (let q = 0; q < ticket.quantity; q++) {
                                  userDatas.push({} as CartEventTicketUserData);
                                }
                                // eslint-disable-next-line no-param-reassign
                                cartEvent.sections[s].tickets[t].quantity += ticket.quantity;
                                if (ticket.labels.length > 0) {
                                  cartEvent.sections[s].tickets[t].labels.concat(ticket.labels);
                                }

                                newCart.amountItens += ticket.quantity;
                                newCart.totalValue =
                                  Number(newCart.totalValue) +
                                  ticket.quantity * Number(ticket.unitValue);
                                newCart.totalValueWithFee =
                                  Number(newCart.totalValueWithFee) +
                                  ticket.quantity * Number(ticket.unitValue) * Number(ticket.fee);
                                // eslint-disable-next-line no-param-reassign
                                cartEvent.sections[s].tickets[t].userDatas = userDatas;
                                break;
                              }
                            }
                            if (found) {
                              break;
                            } else {
                              const userDatas: CartEventTicketUserData[] = [];
                              // eslint-disable-next-line no-plusplus
                              for (let q = 0; q < ticket.quantity; q++) {
                                userDatas.push({} as CartEventTicketUserData);
                              }
                              // eslint-disable-next-line no-param-reassign
                              ticket.userDatas = userDatas;
                              cartEvent.sections[s].tickets.push(ticket);
                              newCart.amountItens += ticket.quantity;
                              newCart.totalValue =
                                Number(newCart.totalValue) +
                                ticket.quantity * Number(ticket.unitValue);
                              newCart.totalValueWithFee =
                                Number(newCart.totalValueWithFee) +
                                ticket.quantity * Number(ticket.unitValue) * Number(ticket.fee);
                            }
                          } else {
                            // eslint-disable-next-line no-param-reassign
                            cartEvent.sections[s].tickets = [];
                            const userDatas: CartEventTicketUserData[] = [];
                            // eslint-disable-next-line no-plusplus
                            for (let q = 0; q < ticket.quantity; q++) {
                              userDatas.push({} as CartEventTicketUserData);
                            }
                            // eslint-disable-next-line no-param-reassign
                            ticket.userDatas = userDatas;
                            cartEvent.sections[s].tickets.push(ticket);
                            newCart.amountItens += ticket.quantity;
                            newCart.totalValue =
                              Number(newCart.totalValue) +
                              ticket.quantity * Number(ticket.unitValue);
                            newCart.totalValueWithFee =
                              Number(newCart.totalValueWithFee) +
                              ticket.quantity * Number(ticket.unitValue) * Number(ticket.fee);
                          }
                        }
                      } else {
                        // eslint-disable-next-line no-param-reassign
                        cartEvent.sections = [];
                        const newSection: EventDateSectionSite = {
                          ...section,
                          tickets: [],
                        };
                        if (section.tickets && section.tickets.length > 0) {
                          section.tickets.forEach(dataTicket => {
                            if (dataTicket.quantity && dataTicket.quantity > 0) {
                              const userDatas: CartEventTicketUserData[] = [];
                              // eslint-disable-next-line no-plusplus
                              for (let q = 0; q < dataTicket.quantity; q++) {
                                userDatas.push({} as CartEventTicketUserData);
                              }
                              // eslint-disable-next-line no-param-reassign
                              dataTicket.userDatas = userDatas;
                              newSection.tickets.push(dataTicket);
                              newCart.amountItens += dataTicket.quantity;
                              newCart.totalValue =
                                Number(newCart.totalValue) +
                                dataTicket.quantity * Number(dataTicket.unitValue);
                              newCart.totalValueWithFee =
                                Number(newCart.totalValueWithFee) +
                                dataTicket.quantity *
                                  Number(dataTicket.unitValue) *
                                  Number(dataTicket.fee);
                            }
                          });
                        }
                        if (newSection.tickets.length > 0) {
                          cartEvent.sections.push(newSection);
                        }
                      }
                    }
                  });
                }
              });
            }

            // groups
            if (newEventDate.groups && newEventDate.groups.length > 0) {
              newEventDate.groups.forEach(eventGroup => {
                let foundGroup = false;
                if (cartEvent.groups && cartEvent.groups.length > 0) {
                  cartEvent.groups.forEach(cardGroup => {
                    if (eventGroup.id === cardGroup.id) {
                      foundGroup = true;
                      // product
                      if (eventGroup.products && eventGroup.products.length > 0) {
                        eventGroup.products.forEach(product => {
                          if (
                            product.quantity &&
                            product.quantity > 0 &&
                            cardGroup.products &&
                            cardGroup.products.length > 0
                          ) {
                            let foundProduct = false;
                            // eslint-disable-next-line no-plusplus
                            for (let p = 0; p < cardGroup.products.length; p++) {
                              if (product.id === cardGroup.products[p].id) {
                                foundProduct = true;
                                // eslint-disable-next-line no-param-reassign
                                cardGroup.products[p].quantity += product.quantity;
                                newCart.amountItens += product.quantity;
                                newCart.totalValue =
                                  Number(newCart.totalValue) +
                                  product.quantity * Number(product.unitValue);
                                newCart.totalValueWithFee =
                                  Number(newCart.totalValueWithFee) +
                                  product.quantity *
                                    Number(product.unitValue) *
                                    Number(product.fee);
                              }
                            }
                            if (!foundProduct) {
                              cardGroup.products.push(product);
                              newCart.amountItens += product.quantity;
                              newCart.totalValue =
                                Number(newCart.totalValue) +
                                product.quantity * Number(product.unitValue);
                              newCart.totalValueWithFee =
                                Number(newCart.totalValueWithFee) +
                                product.quantity * Number(product.unitValue) * Number(product.fee);
                            }
                          }
                        });
                      }

                      // combo

                      if (eventGroup.combos && eventGroup.combos.length > 0) {
                        eventGroup.combos.forEach(combo => {
                          if (
                            combo.quantity &&
                            combo.quantity > 0 &&
                            cardGroup.combos &&
                            cardGroup.combos.length > 0
                          ) {
                            let foundCombo = false;
                            // eslint-disable-next-line no-plusplus
                            for (let c = 0; c < cardGroup.combos.length; c++) {
                              if (combo.id === cardGroup.combos[c].id) {
                                foundCombo = true;
                                // eslint-disable-next-line no-param-reassign
                                cardGroup.combos[c].quantity += combo.quantity;
                                newCart.amountItens += combo.quantity;
                                newCart.totalValue =
                                  Number(newCart.totalValue) +
                                  combo.quantity * Number(combo.unitValue);
                                newCart.totalValueWithFee =
                                  Number(newCart.totalValueWithFee) +
                                  combo.quantity * Number(combo.unitValue) * Number(combo.fee);
                              }
                            }
                            if (!foundCombo) {
                              cardGroup.combos.push(combo);
                              newCart.amountItens += combo.quantity;
                              newCart.totalValue =
                                Number(newCart.totalValue) +
                                combo.quantity * Number(combo.unitValue);
                              newCart.totalValueWithFee =
                                Number(newCart.totalValueWithFee) +
                                combo.quantity * Number(combo.unitValue) * Number(combo.fee);
                            }
                          }
                        });
                      }
                    }
                  });
                  if (!foundGroup) {
                    if (!cartEvent.groups) {
                      // eslint-disable-next-line no-param-reassign
                      cartEvent.groups = [];
                    }
                    const newGroup: EventGroup = {
                      ...eventGroup,
                      products: [],
                      combos: [],
                    };
                    if (eventGroup.products && eventGroup.products.length > 0) {
                      eventGroup.products.forEach(product => {
                        if (product.quantity > 0) {
                          newGroup.products.push(product);
                          newCart.amountItens += product.quantity;
                          newCart.totalValue =
                            Number(newCart.totalValue) +
                            product.quantity * Number(product.unitValue);
                          newCart.totalValueWithFee =
                            Number(newCart.totalValueWithFee) +
                            product.quantity * Number(product.unitValue) * Number(product.fee);
                        }
                      });
                    }
                    if (eventGroup.combos && eventGroup.combos.length > 0) {
                      eventGroup.combos.forEach(combo => {
                        if (combo.quantity > 0) {
                          newGroup.combos.push(combo);
                          newCart.amountItens += combo.quantity;
                          newCart.totalValue =
                            Number(newCart.totalValue) + combo.quantity * Number(combo.unitValue);
                          newCart.totalValueWithFee =
                            Number(newCart.totalValueWithFee) +
                            combo.quantity * Number(combo.unitValue) * Number(combo.fee);
                        }
                      });
                    }
                    if (newGroup.products.length > 0 || newGroup.combos.length > 0) {
                      cartEvent.groups.push(newGroup);
                    }
                  }
                } else {
                  if (!cartEvent.groups) {
                    // eslint-disable-next-line no-param-reassign
                    cartEvent.groups = [];
                  }
                  const newGroup: EventGroup = {
                    ...eventGroup,
                    products: [],
                    combos: [],
                  };
                  if (eventGroup.products && eventGroup.products.length > 0) {
                    eventGroup.products.forEach(product => {
                      if (product.quantity > 0) {
                        newGroup.products.push(product);
                        newCart.amountItens += product.quantity;
                        newCart.totalValue =
                          Number(newCart.totalValue) + product.quantity * Number(product.unitValue);
                        newCart.totalValueWithFee =
                          Number(newCart.totalValueWithFee) +
                          product.quantity * Number(product.unitValue) * Number(product.fee);
                      }
                    });
                  }
                  if (eventGroup.combos && eventGroup.combos.length > 0) {
                    eventGroup.combos.forEach(combo => {
                      if (combo.quantity > 0) {
                        newGroup.combos.push(combo);
                        newCart.amountItens += combo.quantity;
                        newCart.totalValue =
                          Number(newCart.totalValue) + combo.quantity * Number(combo.unitValue);
                        newCart.totalValueWithFee =
                          Number(newCart.totalValueWithFee) +
                          combo.quantity * Number(combo.unitValue) * Number(combo.fee);
                      }
                    });
                  }
                  if (newGroup.products.length > 0 || newGroup.combos.length > 0) {
                    cartEvent.groups.push(newGroup);
                  }
                }
              });
            }
          }
        });
        if (!foundEvent) {
          const newEventDate2: EventDate = {
            ...newEventDate,
            sections: [],
            groups: [],
          };
          if (newEventDate.discountCoupon) {
            if (!discountCoupon) {
              discountCoupon = newEventDate.discountCoupon;
            }
          }
          if (newEventDate.sections && newEventDate.sections.length > 0) {
            newEventDate.sections.forEach(section => {
              if (section.tickets && section.tickets.length > 0) {
                const newSection: EventDateSectionSite = {
                  ...section,
                  tickets: [],
                };
                section.tickets.forEach(ticket => {
                  if (ticket.quantity && ticket.quantity > 0) {
                    const userDatas: CartEventTicketUserData[] = [];
                    // eslint-disable-next-line no-plusplus
                    for (let q = 0; q < ticket.quantity; q++) {
                      userDatas.push({} as CartEventTicketUserData);
                    }
                    // eslint-disable-next-line no-param-reassign
                    ticket.userDatas = userDatas;
                    newSection.tickets.push(ticket);
                    newCart.amountItens += ticket.quantity;
                    newCart.totalValue =
                      Number(newCart.totalValue) + ticket.quantity * Number(ticket.unitValue);
                    newCart.totalValueWithFee =
                      Number(newCart.totalValueWithFee) +
                      ticket.quantity * Number(ticket.unitValue) * Number(ticket.fee);
                  }
                });
                if (newSection.tickets.length > 0) {
                  newEventDate2.sections.push(newSection);
                }
              }
            });
          }
          // podutos aqui
          if (newEventDate.groups && newEventDate.groups.length > 0) {
            const groups: EventGroup[] = [];
            newEventDate.groups.forEach(group => {
              const newGroup: EventGroup = {
                ...group,
                products: [],
                combos: [],
              };
              if (group.products && group.products.length > 0) {
                group.products.forEach(product => {
                  if (product.quantity > 0) {
                    newGroup.products.push(product);
                    newCart.amountItens += product.quantity;
                    newCart.totalValue =
                      Number(newCart.totalValue) + product.quantity * Number(product.unitValue);
                    newCart.totalValueWithFee =
                      Number(newCart.totalValueWithFee) +
                      product.quantity * Number(product.unitValue) * Number(product.fee);
                  }
                });
              }
              if (group.combos && group.combos.length > 0) {
                group.combos.forEach(combo => {
                  if (combo.quantity > 0) {
                    newGroup.combos.push(combo);
                    newCart.amountItens += combo.quantity;
                    newCart.totalValue =
                      Number(newCart.totalValue) + combo.quantity * Number(combo.unitValue);
                    newCart.totalValueWithFee =
                      Number(newCart.totalValueWithFee) +
                      combo.quantity * Number(combo.unitValue) * Number(combo.fee);
                  }
                });
              }

              if (newGroup.products.length > 0 || newGroup.combos.length > 0) {
                groups.push(newGroup);
              }
            });
            if (groups.length > 0) {
              newEventDate2.groups = groups;
            }
          }
          if (
            newEventDate2.sections.length > 0 ||
            (newEventDate2.groups && newEventDate2.groups.length > 0)
          ) {
            newCart.events.push(newEventDate2);
          }
        }
      }
    });

    const originalValueWithPaymentFee =
      // eslint-disable-next-line no-nested-ternary
      newCart && newCart.totalValueWithFee && newCart.totalValueWithFee > 0
        ? discountCoupon && discountCoupon.discountCoupon
          ? newCart.totalValueWithFee - discountCoupon.discountCoupon.discount
          : newCart.totalValueWithFee
        : discountCoupon && discountCoupon.discountCoupon
          ? totalValueWithFee - discountCoupon.discountCoupon.discount
          : totalValueWithFee;

    const newCartStore: Cart = {
      events:
        newCart && newCart.events && newCart.events.length > 0
          ? newCart.events
          : [eventDate as EventDate],
      amountItens:
        newCart && newCart.amountItens && newCart.amountItens > 0
          ? newCart.amountItens
          : amountItens,
      totalValue:
        newCart && newCart.totalValue && newCart.totalValue > 0 ? newCart.totalValue : totalValue,
      totalValueWithFee:
        newCart && newCart.totalValueWithFee && newCart.totalValueWithFee > 0
          ? newCart.totalValueWithFee
          : totalValueWithFee,
      originalValueWithPaymentFee,
      totalValueWithPaymentFee: originalValueWithPaymentFee,
      discountCoupon,
      pdvId: getPdv(),
    };
    // dispatch(setCart(newCartStore));
    setItem(REACT_CART as string, JSON.stringify(newCartStore));
    setTimeout(() => {
      handleClearEvent();
    }, 1000);
  };

  const handleOnGetEventKey = (selectedSectionId: string, ticketId: string): string => {
    let key = '';
    if (eventMap && eventMap.sections && eventMap.sections.length > 0) {
      // eslint-disable-next-line no-plusplus
      for (let s = 0; eventMap.sections.length; s++) {
        if (eventMap.sections[s].section.id === selectedSectionId) {
          // eslint-disable-next-line no-plusplus
          for (let t = 0; t < eventMap.sections[s].tickets.length; t++) {
            if (eventMap.sections[s].tickets[t].ticket.id === ticketId) {
              key = eventMap.sections[s].tickets[t].eventKey;
              break;
            }
          }
          break;
        }
      }
    }
    return key;
  };

  //   const handleMaptest = (
  //     eventMap: EventMap | any,
  //     sections: EventDateSectionSite[]
  //   ): EventDateSectionSite[] => {
  //     const filterSection: EventDateSectionSite[] = [];
  //     if (eventMap && eventMap.sections && eventMap.sections.length > 0) {
  //       eventMap.sections.forEach((eventSection: any) => {
  //         if (
  //           eventSection &&
  //           eventSection.tickets &&
  //           eventSection.tickets.length > 0
  //         ) {
  //           eventSection.tickets.forEach((tickets) => {
  //             sections.forEach((data) => {
  //               // if(data.section.id === eventSection.id){

  //               // }
  //               console.log(data.section.id, eventSection.id);
  //             });
  //           });
  //         }
  //       });
  //     }
  //     return sections;
  //   };

  const handleMapSection = (sections: EventDateSectionSite[]): EventDateSectionSite[] => {
    const withMap = sections.filter(section => section.tickets.some(ticket => ticket.hasMap));
    const withoutMap = sections.filter(section => section.tickets.every(ticket => !ticket.hasMap));
    const groupedSection: EventDateSectionSite = {
      ...withMap[0],
      count: withMap.reduce((acc, section) => acc + section.count, 0),
      tickets: withMap.reduce<EventTicketSite[]>(
        (acc, section) => acc.concat(section.tickets),
        [] as EventTicketSite[],
      ),
    };
    groupedSection.section.name = 'Selecionar Mesa';
    groupedSection.showModal = true;
    return [groupedSection, ...withoutMap];
  };

  const showModal = ({
    value,
    newTitle,
    eventKey: eventKeySelected,
  }: ShouldShowModalProps): void => {
    setShouldShowModal(value);
    onChangeTitle(newTitle);
    if (eventKeySelected) {
      setEventKey(eventKeySelected);
    }
    onSetVisible(true);
  };

  const handleModal = (selectedSectionId: string, tickectId: string, hasMap: boolean): void => {
    if (hasMap) {
      const selectedEventKey = handleOnGetEventKey(selectedSectionId, tickectId);
      showModal({ value: ShowModal.MAP, newTitle: 'Mapa', eventKey: selectedEventKey });
    }
  };

  useEffect(() => {
    if (selectedChartObject !== undefined) {
      handleOnSelectChartObjectSelected();
    }
  }, [selectedChartObject]);

  useEffect(() => {
    if (deselectedChartObject !== undefined) {
      handleOnChartObjectRemoveTicket();
    }
  }, [deselectedChartObject]);

  useEffect(() => {
    handleGetEvents();
  }, []);

  return (
    <>
      {dates && dates.length > 0 && (
        <EventContainer
          key="event"
          state={loading}
          events={dates}
          event={eventDate}
          eventMap={eventMap}
          sectionId={sectionId}
          shouldShowModal={shouldShowModal}
          eventKey={eventKey}
          selectedObjects={selectedObjects}
          showProducts={showProducts}
          groupId={groupId}
          coupon={coupon}
          amountItens={amountItens}
          totalValue={totalValue}
          totalValueWithFee={totalValueWithFee}
          visible={visible}
          title={title}
          onSelectDate={handleSelectDate}
          onSelectSection={handleSelectSection}
          onIncrementTicket={handleIncrementTicket}
          onDecrementTicket={handleDecrementTicket}
          onCheckShowProducts={handleCheckShowProducts}
          onSelectGroup={handleSelectGroup}
          onIncrementProduct={handleIncrementProduct}
          onDecrementProduct={handleDecrementProduct}
          onIncrementCombo={handleIncrementCombo}
          onDecrementCombo={handleDecrementCombo}
          onChangeCoupon={handleChangeCoupon}
          onApplyCoupon={handleApplyCoupon}
          onRemoveCoupon={handleRemoveCoupon}
          onClearEvent={handleClearEvent}
          onSubmitAddCart={handleAddCart}
          getEventKey={handleOnGetEventKey}
          showModal={showModal}
          onToggle={onToggle}
          onChartObjectSelected={handleOnChartObjectSelected}
          onChartObjectDeselected={handleOnChartObjectDeselected}
          onHandleMapSection={handleMapSection}
          showMap={showMap}
          onModal={handleModal}
        />
      )}
    </>
  );
};

export const EventScreen: React.FC = (): JSX.Element => {
  const { nameEvent, city, state } = useParams();

  const handleGetEvents = async (): Promise<EventSiteHeader[]> => {
    const { data } = await api.get<EventSiteHeader[]>(`/event/list/${nameEvent}/${city}/${state}`);
    return data;
  };

  const handleGetEvent = async (eventId: string): Promise<EventSiteGet> => {
    const { data } = await api.get<EventSiteGet>(`/event/${eventId}/detail`);
    return data;
  };

  const handleProductsAndCombos = async (eventId: string): Promise<EventProductsAndCombos> => {
    const { data } = await api.get<EventProductsAndCombos>(`/event/product-combo/${eventId}`);
    return data;
  };

  const handleGetSectionProductsAndCombos = async (
    eventId: string,
    sectionId: string,
  ): Promise<EventSectionProductsAndCombos> => {
    const { data } = await api.get<EventSectionProductsAndCombos>(
      `/event/${eventId}/section/${sectionId}/product-combo`,
    );
    return data;
  };
  return (
    <EventComponent
      getEvents={handleGetEvents}
      getEvent={handleGetEvent}
      getProductsAndCombos={handleProductsAndCombos}
      getSectionProductsAndCombos={handleGetSectionProductsAndCombos}
      getPdv={() => undefined}
    />
  );
};
