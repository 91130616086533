import React, { JSX, ReactNode } from 'react';
import { Button } from '@/components';
import { CardValidationResponse } from '@/model/CardValidationResponse';
import logoAmex from '@/assets/images/logo_amex.png';
import logoDinersClub from '@/assets/images/logo_diners_club.png';
import logoDiscover from '@/assets/images/logo_discover.png';
import logoJcb from '@/assets/images/logo_jcb.png';
import logoMaster from '@/assets/images/logo_mastercard.png';
import logoVisa from '@/assets/images/logo_visa.png';
import logoElo from '@/assets/images/logo_elo.png';
import CardType from '@/model/CardType';
import { IconCheckSuccess, IconError, IconExclamation } from '@/assets/icons';
import { IconWarning } from '@/assets/icons/IconWarning';
import CardValidationResponseStatus from '@/model/CardValidationResponseStatus';

interface StateProps {
  cardValidationResponses: CardValidationResponse[];
}

interface DispatchProps {
  onCancel: () => void;
}

type Props = StateProps & DispatchProps;

export const ValidatedErrorCreditCardContent: React.FC<Props> = ({
  cardValidationResponses,
  onCancel,
}): JSX.Element => {
  const getCardLogo = (cardType: CardType): string => {
    let logo = logoMaster;
    switch (cardType) {
      case CardType.MASTER_CARD:
        logo = logoMaster;
        break;
      case CardType.VISA:
        logo = logoVisa;
        break;
      case CardType.AMERICAN_EXPRESS:
        logo = logoAmex;
        break;
      case CardType.DISCOVER:
        logo = logoDiscover;
        break;
      case CardType.DINERS_CLUB:
        logo = logoDinersClub;
        break;
      case CardType.JCB:
        logo = logoJcb;
        break;
      case CardType.ELO:
        logo = logoElo;
        break;
      default:
        break;
    }
    return logo;
  };

  const renderError = (messages: string[]): ReactNode => (
    <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
      <IconError height="30px" className="self-center text-[#E64F49]" />
      {messages && messages.length > 0 && (
        <div className="ml-[20px] text-left text-[#E64F49]">
          {messages.map(message => (
            <p key={`messageError-${message}`} className="text-sm">
              {message}
            </p>
          ))}
        </div>
      )}
    </div>
  );

  const renderNotBelong = (): ReactNode => (
    <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
      <IconError height="30px" className="self-center text-[#E64F49]" />
      <div className="ml-[20px] text-left text-[#E64F49]">
        <p className="text-sm">Esse cartão não está vinculado a você</p>
      </div>
    </div>
  );

  const renderAwaitingValidation = (): ReactNode => (
    <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
      <IconWarning height="30px" className="self-center text-[#FFE249]" />
      <div className="ml-[20px] text-left">
        <p className="text-sm">Existe processo de validação pendente</p>
      </div>
    </div>
  );

  const renderValidated = (): ReactNode => (
    <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
      <IconWarning height="30px" className="self-center text-[#FFE249]" />
      <div className="ml-[20px] text-left">
        <p className="text-sm">Este cartão já se encontra validado</p>
      </div>
    </div>
  );

  const renderApproved = (): ReactNode => (
    <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
      <IconCheckSuccess height="30px" className="self-center text-[#7AD81B]" />
      <div className="ml-[20px] text-left text-[#E64F49]">
        <p className="text-sm">Cartão validado</p>
      </div>
    </div>
  );

  const renderAwaitingPayment = (): ReactNode => (
    <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
      <IconWarning height="30px" className="self-center text-[#FFE249]" />
      <div className="ml-[20px] text-left">
        <p className="text-sm">Aguardando análise do cartão de crédito</p>
      </div>
    </div>
  );

  const renderDisapproved = (): ReactNode => (
    <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
      <IconError height="30px" className="self-center text-[#E64F49]" />
      <div className="ml-[20px] text-left text-[#E64F49]">
        <p className="text-sm">O banco não autorizou o pedido</p>
      </div>
    </div>
  );

  return (
    <div className="w-[411px] mx-auto">
      <div className="flex flex-col justify-center">
        <IconExclamation height="50px" className="self-center text-[#E64F49] mb-[30px]" />
        <h4 className="font-dmsans self-center font-bold text-neutro-b-400 text-[21px] mb-[20px]">
          {`Ops! Erro ao validar ${
            cardValidationResponses.length > 1 ? 'seus cartões' : 'seu cartão'
          }`}
        </h4>
        {cardValidationResponses.map(data => (
          <React.Fragment key={`Validating-credit-card-${data.card.id}`}>
            <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
              <img
                style={{ maxWidth: '36px' }}
                src={getCardLogo(data.card.cardType)}
                alt="logo"
                className="mr-[30px]"
              />
              <label className="ml-[20px]">{data.card.cardNumber}</label>
            </div>

            {data.status === CardValidationResponseStatus.NOT_BELONG && renderNotBelong()}
            {data.status === CardValidationResponseStatus.AWAITING_VALIDATION &&
              renderAwaitingValidation()}
            {data.status === CardValidationResponseStatus.VALIDATED && renderValidated()}
            {data.status === CardValidationResponseStatus.APPROVED && renderApproved()}
            {data.status === CardValidationResponseStatus.AWAITING_PAYMENT &&
              renderAwaitingPayment()}
            {data.status === CardValidationResponseStatus.DISAPPROVED && renderDisapproved()}
            {data.status === CardValidationResponseStatus.ERROR &&
              renderError(data.messages as string[])}
          </React.Fragment>
        ))}
        <div className={`mt-[35px]`}>
          <Button
            type="button"
            title="Cancelar"
            buttonStyle="primary"
            size="xlg"
            className="w-full mb-[25px]"
            onClick={() => onCancel()}
          />
        </div>
      </div>
    </div>
  );
};
