export enum ShowModal {
  MAP = 'MAP',
}

export type ShouldShowModalProps = {
  value: ShowModal;
  newTitle: string | React.ReactNode;
  eventKey?: string;
};

export interface SelectedObject {
  label: string;
  ticketType: string;
}
