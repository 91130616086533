import React from 'react';
import { DateRange as ReactDateRange, Range } from 'react-date-range';
import pt from 'date-fns/locale/pt';
import { Popover } from '../Popover';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

interface DateRangePickerProps {
  trigger: React.ReactNode;
  dateRange: Range[] | undefined;
  onChangeDateRange: (range: Range[]) => void;
  open?: boolean;
  setOpen?: (open: boolean) => void;
  className?: string;
}

export type { Range };

export const DateRangePicker: React.FC<DateRangePickerProps> = ({
  trigger,
  dateRange,
  onChangeDateRange,
  open,
  setOpen,
  className,
}) => (
  <Popover
    open={open}
    setOpen={setOpen}
    trigger={trigger}
    className="bg-white shadow-pop p-4 rounded-md w-full"
  >
    <ReactDateRange
      editableDateInputs={false}
      onChange={item => {
        onChangeDateRange([item.selection]);
      }}
      moveRangeOnFirstSelection={false}
      ranges={dateRange}
      locale={pt}
      dateDisplayFormat={`dd/MM/yyyy`}
      className={className}
    />
  </Popover>
);
