import React, { useState, FormEvent, useEffect, JSX } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { AxiosError } from 'axios';
import { ReactFacebookLoginInfo, ReactFacebookFailureResponse } from 'react-facebook-login';
import { GoogleLoginResponse, GoogleLoginResponseOffline } from 'react-google-login';
import cep from 'cep-promise';
import { useDialog } from '@/hooks/useDialog';
import useForm from '@/hooks/useForm';
import { getBoolean, getItem, removeItem, setItem } from '@/helpers/common/localStorage';
import validators from '@/helpers/validators';
import { Auth } from '@/model/Auth';
import ChangePassword from '@/model/ChangePassword';
import Client from '@/model/Client';
import ClientBasic from '@/model/ClientBasic';
import ClientConfirmData from '@/model/ClientConfirmData';
import RecoverEmail from '@/model/RecoverEmail';
import RecoverPassword from '@/model/RecoverPassword';
import SocialNetworkAuth from '@/model/SocialNetworkAuth';
import { api } from '@/services/api';
import { REACT_APP_AUTH, REACT_APP_USER, REACT_CART } from '@/utils/config';
import { updateMask as updateMaskCPF } from '@/helpers/masks/cpf';
import { updateMask as updateMaskPhone } from '@/helpers/masks/mobilePhone';
import { unmask, updateMask as updateMaskDate } from '@/helpers/masks/generalDate';
import { Buffer } from 'buffer';
import { toast } from 'react-toastify';
import { useSidebar } from '@/hooks/useSidebar';
import { SidebarProps } from '@/components/Sidebar';
import { CartEventContent } from '@/features/core/cart/components/CartEventContent';
import { useDispatch, useSelector } from 'react-redux';
import { CartEmptyContent } from '@/features/core/cart/components/CartEmptyContent';
import ClientNotification from '@/model/ClientNotification';
import { useLoading, setLoading } from '@/redux/loading/loadingSlice';
// import { setCart, useCart } from '@/redux/cart/cartSlice';
import Cart from '@/features/core/cart/components/Cart';
import CheckDiscount from '@/model/CheckDiscount';
import { Address } from '@/model/Address';
import { updateMask as updateMaskCEP } from '@/helpers/masks/cep';
import { CheckClientDocument } from '@/model/CheckClientDocument';
import ChangeClientData from '@/model/ChangeClientData';
import {
  FormInputNameChangeClientData,
  FormInputNameLogin,
  FormInputNameRegister,
  FormInputNameRegisterConfirm,
  ShouldShowModal,
  ShouldShowModalProps,
} from '../../types';
import { AuthWrapperContainer } from './ui';

interface AuthWrapperProps {
  children: React.ReactNode;
  isHomepage: boolean;
}

export const AuthWrapper: React.FC<AuthWrapperProps> = ({ children, isHomepage }): JSX.Element => {
  const [notifications, setNotifications] = useState<ClientNotification[]>([]);
  const [emailConfirmation, setEmailConfirmation] = useState('');
  const [documentClient, setDocumentClient] = useState('');

  const { visible, onSetVisible, onToggle, title, onChangeTitle } = useDialog();
  const { onShowSidebar } = useSidebar();

  const [shouldShowModal, setShouldShowModal] = useState<ShouldShowModal>(ShouldShowModal.LOGIN);
  const [enableLoginPassword, setEnableLoginPassword] = useState<boolean>(false);
  const [shouldShowPasswordToText, setShouldShowPasswordToText] = useState(false);
  const [shouldShowPasswordToText2, setShouldShowPasswordToText2] = useState(false);
  const [pwd, setPwd] = useState(undefined as unknown as string);
  const [client, setClient] = useState({} as Client);
  const [emailRecoverPassword, setEmailRecoverPassword] = useState(undefined as unknown as string);
  const [token, setToken] = useState(undefined as unknown as string);
  const [nameRecoverPassword, setNameRecoverPassword] = useState(undefined as unknown as string);
  const [needCheckEmailCode, setNeedCheckEmailCode] = useState<boolean>(false);

  const [cart, setCart] = useState<Cart>({
    events: [],
    discountCoupon: {} as CheckDiscount,
    amountItens: 0,
    totalValue: 0,
    totalValueWithFee: 0,
    originalValueWithPaymentFee: 0,
    totalValueWithPaymentFee: 0,
    pdvId: undefined,
  });
  const { loading } = useSelector(useLoading);

  const location = useLocation();
  const codeRecieve = new URLSearchParams(location.search).get('code');
  if (codeRecieve && codeRecieve.trim().length > 0) {
    if (!token) {
      setToken(codeRecieve);
    }
  }

  const history = useNavigate();
  const dispatch = useDispatch();

  const loadCart = (): void => {
    const cartStorage = getItem(REACT_CART as string) as string;
    let cartVariable: Cart | undefined;

    if (cartStorage && cartStorage.length > 0) {
      cartVariable = JSON.parse(cartStorage) as Cart;
      setCart(cartVariable);
    }
  };

  const handleOnGoTo = (href: string): void => {
    history(href);
  };

  const {
    formData: formDataRegister,
    formErrors: formErrorsRegister,
    setErrors: setErrorsRegister,
    onChangeFormInput: onChangeFormInputRegister,
    isFormValid: isFormValidRegister,
    resetForm: resetFormRegister,
  } = useForm({
    initialData: {
      name: '',
      document: '',
      email: '',
      phone: '',
      date: '',
      motherName: '',
      zipCode: '',
      state: '',
      city: '',
      district: '',
      street: '',
      complement: '',
      addressnumber: '',
      password: '',
      confirmPassword: '',
      terms: '',
    },
    validators: {
      name: [validators.required],
      document: [validators.required, validators.cpf],
      email: [validators.required, validators.email],
      phone: [validators.required, validators.mobilePhone],
      date: [validators.required, validators.birthday, validators.maxLength(10)],
      motherName: [validators.required],
      zipCode: [validators.required],
      state: [validators.required],
      city: [validators.required],
      district: [validators.required],
      street: [validators.required],
      password: [
        validators.required,
        validators.minLength(8),
        validators.maxLength(15),
        validators.hasPasswordOnlyNumberCharacteres,
      ],
      confirmPassword: [
        validators.required,
        validators.minLength(8),
        validators.maxLength(15),
        validators.hasPasswordOnlyNumberCharacteres,
      ],
      terms: [validators.required],
    },
    formatters: {
      document: updateMaskCPF,
      phone: updateMaskPhone,
      date: updateMaskDate,
      zipCode: updateMaskCEP,
    },
  });

  const showModal = ({ value, newTitle }: ShouldShowModalProps): void => {
    setShouldShowModal(value);
    onChangeTitle(newTitle);
    onSetVisible(true);
  };

  const handleOnSignUp = (): void => {
    onSetVisible(false);
    if (shouldShowModal !== ShouldShowModal.TERMS) {
      resetFormRegister();
    }

    showModal({
      value: ShouldShowModal.REGISTER,
      newTitle: 'Crie a sua conta',
    });
  };

  const handleOnToggle = (): void => {
    onToggle();
    if (shouldShowModal === ShouldShowModal.TERMS) {
      handleOnSignUp();
      setTimeout(() => {
        const element = document.getElementById('termsAndConditions');
        if (element) {
          element.scrollIntoView();
        }
      }, 300);
    }
  };

  const getClientNotifications = async (): Promise<void> => {
    const response = await api.get<ClientNotification[]>('/client/notification');
    setNotifications(response.data);
  };

  const {
    formData: formDataLogin,
    formErrors: formErrorsLogin,
    setErrors: setErrorsLogin,
    onChangeFormInput: onChangeFormInputLogin,
    isFormValid: isFormValidLogin,
    resetForm: resetFormLogin,
  } = useForm({
    initialData: {
      document: '',
      password: '',
    },
    validators: {
      document: [validators.required, validators.cpf],
      password: [
        validators.required,
        /*
        validators.minLength(8),
        validators.maxLength(15),
        validators.hasPasswordOnlyNumberCharacteres,
        */
      ],
    },
    formatters: {
      document: updateMaskCPF,
    },
  });

  const {
    formData: formDataForgotPassword,
    formErrors: formErrorsForgotPassword,
    setErrors: setErrorsForgotPassword,
    onChangeFormInput: onChangeFormInputForgotPassword,
    isFormValid: isFormValidForgotPassword,
    resetForm: resetFormForgotPassword,
  } = useForm({
    initialData: {
      document: '',
    },
    validators: {
      document: [validators.required, validators.cpf],
    },
    formatters: {
      document: updateMaskCPF,
    },
  });

  const {
    formData: formDataChangePassword,
    formErrors: formErrorsChangePassword,
    setErrors: setErrorsChangePassword,
    onChangeFormInput: onChangeFormInputChangePassword,
    isFormValid: isFormValidChangePassword,
    resetForm: resetFormChangePassword,
  } = useForm({
    initialData: {
      password: '',
      confirmPassword: '',
    },
    validators: {
      password: [
        validators.required,
        validators.minLength(8),
        validators.maxLength(15),
        validators.hasPasswordOnlyNumberCharacteres,
      ],
      confirmPassword: [
        validators.required,
        validators.minLength(8),
        validators.maxLength(15),
        validators.hasPasswordOnlyNumberCharacteres,
      ],
    },
  });

  const {
    formData: formDataRegisterConfirm,
    formErrors: formErrorsRegisterConfirm,
    setErrors: setErrorsRegisterConfirm,
    onChangeFormInput: onChangeFormInputRegisterConfirm,
    isFormValid: isFormValidRegisterConfirm,
    resetForm: resetFormRegisterConfirm,
  } = useForm({
    initialData: {
      code: '',
    },
    validators: {
      code: [validators.required, validators.isNumeric, validators.minLength(6)],
    },
  });

  const {
    formData: formDataChangeClientData,
    formErrors: formErrorsChangeClientData,
    onChangeFormInput: onChangeFormInputChangeClientData,
    isFormValid: isFormValidChangeClientData,
    resetForm: resetFormChangeClientData,
  } = useForm({
    initialData: {
      name: '',
      email: '',
      phone: '',
      date: '',
      motherName: '',
    },
    validators: {
      name: [validators.required],
      email: [validators.required, validators.email],
      phone: [validators.required, validators.mobilePhone],
      date: [validators.required, validators.birthday, validators.maxLength(10)],
      motherName: [validators.required],
    },
    formatters: {
      phone: updateMaskPhone,
      date: updateMaskDate,
    },
  });

  const handleOnSignIn = (): void => {
    onSetVisible(false);
    resetFormLogin();
    showModal({
      value: ShouldShowModal.LOGIN,
      newTitle: '',
    });
  };

  const handleOnLogout = (): void => {
    onSetVisible(false);
    showModal({
      value: ShouldShowModal.LOGOUT,
      newTitle: '',
    });
  };

  const handleOnChangeCEP = async (value: string): Promise<void> => {
    if (value.length === 9) {
      const cepResponse = await cep(value);
      onChangeFormInputRegister(FormInputNameRegister.state)(cepResponse.state);
      onChangeFormInputRegister(FormInputNameRegister.city)(cepResponse.city);
      onChangeFormInputRegister(FormInputNameRegister.district)(cepResponse.neighborhood);
      onChangeFormInputRegister(FormInputNameRegister.street)(cepResponse.street);
    }
  };

  const handleOnCart = (): void => {
    onShowSidebar({
      content:
        cart && cart.amountItens > 0 ? (
          <CartEventContent history={history} />
        ) : (
          <CartEmptyContent />
        ),
      title: 'Meu carrinho',
    } as SidebarProps);
  };

  const login = async (cpf: string, password: string): Promise<void> => {
    const payload = Buffer.from(`${cpf}:${password}`, 'utf8').toString('base64');

    const { data } = await api.post<Auth>(
      '/auth',
      {
        grant_type: 'client_credentials',
      },
      {
        headers: {
          Authorization: `Basic ${payload}`,
        },
      },
    );
    setItem(String(REACT_APP_AUTH), data.token);
    setItem(String(REACT_APP_USER), data.user);
  };

  const handleOnCheckCPF = async (): Promise<void> => {
    try {
      dispatch(setLoading(true));
      if (formDataLogin[FormInputNameLogin.document].length === 14) {
        const { data } = await api.get<CheckClientDocument>(
          `/auth/old/${formDataLogin[FormInputNameLogin.document]}`,
        );
        if (data.changePassword) {
          setEmailRecoverPassword(data.client.email);
          showModal({
            value: ShouldShowModal.CONFIRM_EMAIL_FORGOT_PASSWORD,
            newTitle: '',
          });
        } else if (data.checkConfirmationCode) {
          // alert(`Informar o código que está no e-mail: ${response.data.email}`);
          setClient(data.client);
          setNeedCheckEmailCode(true);
          setEmailConfirmation(data.client.email);
          showModal({
            value: ShouldShowModal.CONFIRM_REGISTER,
            newTitle: '',
          });
        } else {
          setEnableLoginPassword(true);
          setTimeout(() => {
            document.getElementById('loginPWD')?.focus();
          }, 200);
        }
      }
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleOnSubmit = async (e: FormEvent): Promise<void> => {
    try {
      e.preventDefault();

      if (isFormValidLogin()) {
        dispatch(setLoading(true));
        await login(
          formDataLogin[FormInputNameLogin.document],
          formDataLogin[FormInputNameLogin.password],
        );
        onToggle();
      }
    } catch (error) {
      const err = error as AxiosError;
      if (err.code && err.code === 'ERR_BAD_REQUEST') {
        setErrorsLogin({
          document: ['CPF ou Senha inválida'],
          password: ['CPF ou Senha inválida'],
        });
      } else {
        toast.warn('Falha ao realizar login, tentar novamente mais tarde');
      }
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleOnSubmitFacebook = async (
    userInfo: ReactFacebookLoginInfo | ReactFacebookFailureResponse,
  ): Promise<void> => {
    try {
      const facebookResponse = userInfo as ReactFacebookLoginInfo;
      if (facebookResponse.userID) {
        dispatch(setLoading(true));
        const payload: SocialNetworkAuth = {
          userID: facebookResponse.userID,
          email: facebookResponse.email,
          name: facebookResponse.name,
          imageUrl:
            facebookResponse.picture &&
            facebookResponse.picture.data &&
            facebookResponse.picture.data.url
              ? facebookResponse.picture.data.url
              : undefined,
        };
        const json = JSON.stringify(payload);
        const authorization = Buffer.from(json, 'utf8').toString('base64');
        const { data } = await api.post<Auth>(
          '/auth/facebook',
          {
            grant_type: 'social_networkAuth_credentials',
          },
          {
            headers: {
              Authorization: `Basic ${authorization}`,
            },
          },
        );
        setItem(String(REACT_APP_AUTH), data.token);
        setItem(String(REACT_APP_USER), data.user);
        onToggle();
      } else {
        const facebookFailureResponse = userInfo as ReactFacebookFailureResponse;
        if (facebookFailureResponse.status) {
          throw Error(`Não autorizado pelo FaceBook, Status: ${facebookFailureResponse.status}`);
        } else {
          throw Error('Não autorizado pelo FaceBook');
        }
      }
    } catch (error) {
      const err = error as AxiosError;
      setErrorsLogin({
        document: [err.message],
      });
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleOnSubmitGoogle = async (
    response: GoogleLoginResponse | GoogleLoginResponseOffline,
  ): Promise<void> => {
    try {
      const googleLoginResponse = response as GoogleLoginResponse;
      if (googleLoginResponse.profileObj) {
        dispatch(setLoading(true));
        const payload: SocialNetworkAuth = {
          userID: googleLoginResponse.googleId,
          email: googleLoginResponse.profileObj.email,
          name: googleLoginResponse.profileObj.name,
          imageUrl: googleLoginResponse.profileObj.imageUrl,
        };
        const json = JSON.stringify(payload);
        const authorization = Buffer.from(json, 'utf8').toString('base64');
        const { data } = await api.post<Auth>(
          '/auth/google',
          {
            grant_type: 'social_networkAuth_credentials',
          },
          {
            headers: {
              Authorization: `Basic ${authorization}`,
            },
          },
        );
        setItem(String(REACT_APP_AUTH), data.token);
        setItem(String(REACT_APP_USER), data.user);
        onToggle();
      } else {
        const googleLoginResponseOffline = response as GoogleLoginResponseOffline;
        if (googleLoginResponseOffline.code) {
          throw Error(`Google Offline: ${googleLoginResponseOffline.code}`);
        } else {
          throw Error('Não autorizado pelo Google');
        }
      }
    } catch (error) {
      const err = error as AxiosError;
      setErrorsLogin({
        document: [err.message],
      });
    } finally {
      dispatch(setLoading(false));
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleOnGoogleError = (error: any): void => {
    throw Error(`Não autorizado pelo Google: ${error}`);
  };

  const handleOnSubmitRegister = async (e: FormEvent): Promise<void> => {
    try {
      e.preventDefault();
      if (isFormValidRegister()) {
        dispatch(setLoading(true));

        const dateString = unmask(formDataRegister[FormInputNameRegister.date]);
        if (!dateString) {
          const fieldName = FormInputNameRegister.date.toString();
          setErrorsRegister({
            [fieldName]: ['Data de nascimento Inválida!'],
          });
          return;
        }
        if (
          formDataRegister[FormInputNameRegister.password] !==
          formDataRegister[FormInputNameRegister.confirmPassword]
        ) {
          setErrorsRegister({
            [FormInputNameRegister.password]: ['Senhas divergentes!'],
            [FormInputNameRegister.confirmPassword]: ['Senhas divergentes!'],
          });
          return;
        }
        const date = new Date(`${dateString}T12:00:00.000Z`);
        const address = {
          zipCode: formDataRegister[FormInputNameRegister.zipCode],
          state: formDataRegister[FormInputNameRegister.state],
          city: formDataRegister[FormInputNameRegister.city],
          district: formDataRegister[FormInputNameRegister.district],
          street: formDataRegister[FormInputNameRegister.street],
          complement: formDataRegister[FormInputNameRegister.complement],
          number: formDataRegister[FormInputNameRegister.addressnumber],
        } as Address;
        const payload: ClientBasic = {
          name: formDataRegister[FormInputNameRegister.name],
          cpf: formDataRegister[FormInputNameRegister.document],
          email: formDataRegister[FormInputNameRegister.email],
          cellPhone: formDataRegister[FormInputNameRegister.phone],
          birthDate: date,
          motherName: formDataRegister[FormInputNameRegister.motherName],
          password: formDataRegister[FormInputNameRegister.password],
          address,
          acceptedTerms: !!(
            formDataRegister[FormInputNameRegister.terms] &&
            formDataRegister[FormInputNameRegister.terms] === 'true'
          ),
        };

        const response = await api.post<Client>('/client', payload);
        setPwd(formDataRegister[FormInputNameRegister.password]);
        setClient(response.data);

        const split = response.data.email.split('@');
        const splitDot = split[1].split('.');
        const result = `${split[0].substring(0, 2)}***@${splitDot[0].substring(0, 1)}***.${
          splitDot[splitDot.length - 1]
        }`;

        setEmailConfirmation(result);
        showModal({
          value: ShouldShowModal.CONFIRM_REGISTER,
          newTitle: '',
        });
      }
    } catch {
      setErrorsRegister({
        document: ['Falha ao realizar cadastro, tentar novamente mais tarde'],
      });
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleOnSubmitForgotPassword = async (e: FormEvent): Promise<void> => {
    try {
      e.preventDefault();

      if (isFormValidForgotPassword()) {
        dispatch(setLoading(true));

        setDocumentClient(formDataForgotPassword[FormInputNameRegister.document]);

        const payload: RecoverPassword = {
          login: formDataForgotPassword[FormInputNameRegister.document],
        };

        const response = await api.post<RecoverEmail>('/client/email', payload);

        setEmailRecoverPassword(response.data.email);
        showModal({
          value: ShouldShowModal.CHECk_EMAIL,
          newTitle: '',
        });

        resetFormForgotPassword();
      }
    } catch (error) {
      const err = error as AxiosError;
      setErrorsForgotPassword({
        document: [err.message],
      });
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleOnConfirmEmail = async (): Promise<void> => {
    try {
      dispatch(setLoading(true));
      const payload: RecoverPassword = {
        login: documentClient,
      };

      const { data } = await api.post<RecoverEmail>('/auth/recover-password', payload);
      setEmailRecoverPassword(data.email);
      showModal({
        value: ShouldShowModal.CONFIRM_EMAIL_FORGOT_PASSWORD,
        newTitle: '',
      });
    } catch (error) {
      const err = error as AxiosError;
      setErrorsForgotPassword({
        document: [err.message],
      });
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleOnChangePersonData = (): void => {
    showModal({
      value: ShouldShowModal.CHANGE_CLIENT_DATA,
      newTitle: '',
    });
  };

  const handleOnSubmitChangeClientData = async (e: FormEvent): Promise<void> => {
    try {
      e.preventDefault();
      if (isFormValidChangeClientData()) {
        dispatch(setLoading(true));
        const dateString = unmask(formDataChangeClientData[FormInputNameChangeClientData.date]);
        const date = new Date(`${dateString}T12:00:00.000Z`);
        const payload: ChangeClientData = {
          name: formDataChangeClientData[FormInputNameChangeClientData.name],
          cpf: documentClient,
          email: formDataChangeClientData[FormInputNameChangeClientData.email],
          cellPhone: formDataChangeClientData[FormInputNameChangeClientData.phone],
          birthDate: date,
          motherName: formDataChangeClientData[FormInputNameChangeClientData.motherName],
        };

        const { data } = await api.patch<Client>('/client/change', payload);
        setNeedCheckEmailCode(true);
        setClient(data);

        const split = formDataChangeClientData[FormInputNameChangeClientData.email].split('@');
        const splitDot = split[1].split('.');
        const result = `${split[0].substring(0, 2)}***@${splitDot[0].substring(0, 1)}***.${
          splitDot[splitDot.length - 1]
        }`;

        setEmailConfirmation(result);
        showModal({
          value: ShouldShowModal.CONFIRM_REGISTER,
          newTitle: '',
        });
        resetFormChangeClientData();
      }
    } catch (error) {
      const err = error as AxiosError;
      setErrorsRegisterConfirm({
        document: [err.message],
      });
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleOnSubmitRegisterConfirm = async (e: FormEvent): Promise<void> => {
    try {
      e.preventDefault();
      if (isFormValidRegisterConfirm()) {
        dispatch(setLoading(true));
        const payload: ClientConfirmData = {
          id: client.id,
          emailConfirmationCode: formDataRegisterConfirm[FormInputNameRegisterConfirm.code],
        };
        const response = await api.patch<Client>('/client/confirm-data', payload);
        setClient(response.data);
        if (!needCheckEmailCode) {
          await login(response.data.cpf, pwd);
          resetFormRegisterConfirm();
          onToggle();
        } else {
          showModal({ value: ShouldShowModal.LOGIN, newTitle: '' });
        }
      }
    } catch (error) {
      const err = error as AxiosError;
      setErrorsRegisterConfirm({
        document: [err.message],
      });
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleOnTogglePasswordToText = (): void =>
    setShouldShowPasswordToText(!shouldShowPasswordToText);

  const handleOnTogglePasswordToText2 = (): void =>
    setShouldShowPasswordToText2(!shouldShowPasswordToText2);

  const handleGoToLoginGoogle = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    const div = document.getElementById('googleLogin') as HTMLDivElement;
    const buttons = div.getElementsByTagName('button') as HTMLCollectionOf<HTMLButtonElement>;
    // eslint-disable-next-line no-plusplus, no-unreachable-loop
    for (let i = 0; i < buttons.length; i++) {
      buttons[i].click();
      break;
    }
    e.stopPropagation();
  };

  const handleGoToLoginFacebook = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    (document.getElementsByClassName('kep-login-facebook')[0] as HTMLButtonElement).click();
    e.stopPropagation();
  };

  const handleGoToLoginAppleId = (): void => {
    // TODO: implement logic
  };

  const handleGoToRegisterAppleId = (): void => {
    // TODO: implement logic
  };

  const handleGoToCallChangePassword = async (): Promise<void> => {
    dispatch(setLoading(true));
    const response = await api.get<ClientBasic>(`/client/recover-password/${token}`);
    setNameRecoverPassword(response.data.name);
    showModal({
      value: ShouldShowModal.CALL_CHANGE_PASSWORD,
      newTitle: 'Alteração de senha',
    });
    dispatch(setLoading(false));
  };

  const handleChangePassword = async (e: FormEvent): Promise<void> => {
    e.preventDefault();
    try {
      if (isFormValidChangePassword()) {
        dispatch(setLoading(true));
        if (
          formDataChangePassword[FormInputNameRegister.password] !==
          formDataChangePassword[FormInputNameRegister.confirmPassword]
        ) {
          setErrorsChangePassword({
            password: ['Senhas divergentes!'],
            confirmPassword: ['Senhas divergentes!'],
          });
          return;
        }

        const payload: ChangePassword = {
          token,
          password: formDataChangePassword[FormInputNameRegister.password],
          confirmPassword: formDataChangePassword[FormInputNameRegister.confirmPassword],
        };

        const response = await api.post<RecoverPassword>('/auth/change-password', payload);

        await login(
          response.data.login,
          formDataChangePassword[FormInputNameRegister.confirmPassword],
        );
        dispatch(setLoading(false));
        resetFormChangePassword();
        onToggle();
      }
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleOnSubmitResendCode = async (e: FormEvent): Promise<void> => {
    try {
      e.preventDefault();
      dispatch(setLoading(true));
      await api.patch(`/client/resend-code/${client.id}`);
      toast.success('Código reenviado com sucesso!');
      resetFormRegisterConfirm();
    } catch (error) {
      const err = error as AxiosError;
      setErrorsRegisterConfirm({
        code: [err.message],
      });
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleOnSubmitResendEmail = async (e: FormEvent): Promise<void> => {
    try {
      e.preventDefault();

      dispatch(setLoading(true));

      if (documentClient !== '') {
        const payload: RecoverPassword = {
          login: documentClient,
        };

        const response = await api.post<RecoverEmail>('/auth/recover-password', payload);
        setEmailRecoverPassword(response.data.email);
        toast.success('Mensagem de recuperação de senha enviada com sucesso!');
      } else {
        toast.error(
          'Não foi possível enviar a mensagem de recuperação de senha enviada! Informe o dados novamente.',
        );
        showModal({
          value: ShouldShowModal.FORGOT_PASSWORD,
          newTitle: 'Esqueceu a sua senha?',
        });
      }
    } catch (error) {
      const err = error as AxiosError;
      setErrorsForgotPassword({
        document: [err.message],
      });
    } finally {
      dispatch(setLoading(false));
    }
  };

  const onSubmitLogout = (): void => {
    removeItem(String(REACT_APP_AUTH));
    removeItem(String(REACT_APP_USER));
    const newCart: Cart = {
      events: [],
      discountCoupon: {} as CheckDiscount,
      amountItens: 0,
      totalValue: 0,
      totalValueWithFee: 0,
      originalValueWithPaymentFee: 0,
      totalValueWithPaymentFee: 0,
    };
    setItem(REACT_CART as string, JSON.stringify(newCart));
    setCart(newCart);
    history('/');
    onSetVisible(false);
  };

  const onCloseModal = (): void => {
    onSetVisible(false);
  };

  const handleOnSupportClick = (): void => {
    window.open('https://suporte.bancadoingresso.com.br/open', '_blank');
  };

  useEffect(() => {
    if (token && token.trim().length > 0) {
      handleGoToCallChangePassword();
    }
    const signed = getBoolean(String(REACT_APP_AUTH));
    if (signed) {
      getClientNotifications();
    }
    // eslint-disable-next-line
  }, [token]);

  useEffect(() => {
    loadCart();
  }, []);

  return (
    <AuthWrapperContainer
      state={loading}
      homepage={isHomepage}
      notifications={notifications}
      onGoTo={handleOnGoTo}
      handleOnSignIn={handleOnSignIn}
      handleOnSignUp={handleOnSignUp}
      handleOnLogout={handleOnLogout}
      onSubmitLogout={onSubmitLogout}
      onCloseModal={onCloseModal}
      handleOnCart={handleOnCart}
      visibleModal={visible}
      onToggleModal={handleOnToggle}
      titleModal={title}
      shouldShowModal={shouldShowModal}
      onShouldShowModal={showModal}
      shouldShowPasswordToText={shouldShowPasswordToText}
      shouldShowPasswordToText2={shouldShowPasswordToText2}
      onTogglePasswordToText={handleOnTogglePasswordToText}
      onTogglePasswordToText2={handleOnTogglePasswordToText2}
      formDataLogin={formDataLogin}
      formErrorsLogin={formErrorsLogin}
      onChangeFormInputLogin={onChangeFormInputLogin}
      enableLoginPassword={enableLoginPassword}
      onCheckCPF={handleOnCheckCPF}
      onSubmitLogin={handleOnSubmit}
      onGoToLoginGoogle={handleGoToLoginGoogle}
      onSubmitGoogle={handleOnSubmitGoogle}
      onGoogleError={handleOnGoogleError}
      onGoToLoginFacebook={handleGoToLoginFacebook}
      onSubmitFacebook={handleOnSubmitFacebook}
      onGoToLoginAppleId={handleGoToLoginAppleId}
      formDataRegister={formDataRegister}
      formErrorsRegister={formErrorsRegister}
      onChangeFormInputRegister={onChangeFormInputRegister}
      onChangeCEP={handleOnChangeCEP}
      onSubmitRegister={handleOnSubmitRegister}
      onGoToRegisterAppleId={handleGoToRegisterAppleId}
      formDataForgotPassword={formDataForgotPassword}
      formErrorsForgotPassword={formErrorsForgotPassword}
      onChangeFormInputForgotPassword={onChangeFormInputForgotPassword}
      onSubmitForgotPassword={handleOnSubmitForgotPassword}
      onSubmitChangeClientData={handleOnSubmitChangeClientData}
      document={documentClient}
      formDataChangeClientData={formDataChangeClientData}
      formErrorsChangeClientData={formErrorsChangeClientData}
      onChangeFormInputChangeClientData={onChangeFormInputChangeClientData}
      formDataChangePassword={formDataChangePassword}
      formErrorsChangePassword={formErrorsChangePassword}
      onChangeFormInputChangePassword={onChangeFormInputChangePassword}
      onSubmitChangePassword={handleChangePassword}
      formDataRegisterConfirm={formDataRegisterConfirm}
      formErrorsRegisterConfirm={formErrorsRegisterConfirm}
      onChangeFormInputRegisterConfirm={onChangeFormInputRegisterConfirm}
      onSubmitRegisterConfirm={handleOnSubmitRegisterConfirm}
      emailConfirmation={emailConfirmation}
      email={emailRecoverPassword}
      name={nameRecoverPassword}
      onSubmitResendCode={handleOnSubmitResendCode}
      onSubmitResendEmail={handleOnSubmitResendEmail}
      onConfirmEmail={handleOnConfirmEmail}
      onChangePersonData={handleOnChangePersonData}
      onSupportClick={handleOnSupportClick}
    >
      {children}
    </AuthWrapperContainer>
  );
};
