import React from 'react';
import * as CheckboxPrimitive from '@radix-ui/react-checkbox';
import { Check } from '@/assets/icons';

interface CheckProps {
  name?: string;
  value: string;
  checked?: boolean;
  label?: string;
  error?: string | null | undefined;
  className?: string;
  renderForward?: React.ReactNode;
  onChange: (e: string) => void;
}

export const Checkbox: React.FC<CheckProps> = ({
  name,
  value,
  checked,
  error,
  label,
  className,
  onChange,
  renderForward,
}) => (
  <>
    <div className={`relative flex items-center ${className || ''}`}>
      <CheckboxPrimitive.Root
        aria-invalid={error ? 'true' : 'false'}
        value={value}
        checked={checked}
        onCheckedChange={e => onChange(e ? 'true' : 'false')}
        className={`absolute -top-[1px] -left-[1px] border border-[#C4C4C4] w-[27px] h-[27px] rounded`}
        id={name}
      >
        <CheckboxPrimitive.Indicator className="absolute -top-[1px] -left-[1px] flex items-center justify-center rounded bg-red-500  w-[27px] h-[27px]">
          <img src={Check} alt="Checked" />
        </CheckboxPrimitive.Indicator>
      </CheckboxPrimitive.Root>
      {label && (
        <div className="block">
          <label
            htmlFor={name}
            className="block font-normal text-[21px] cursor-pointer ml-[42px] text-neutro-b-500 leading-[27px]"
          >
            {label}
          </label>
        </div>
      )}
      {renderForward && <div className="ml-[27px]">{renderForward}</div>}
    </div>
    {error && <p className="text-red-500 text-xs">{error}</p>}
  </>
);
