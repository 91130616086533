import validators from '@/helpers/validators';
import React, { useState, useEffect } from 'react';

import useForm from '@/hooks/useForm';
import { useDialog } from '@/hooks/useDialog';
import { UseFormControl } from 'src/types';
import {
  getCreditCardLabel,
  updateHolderName,
  updateMaskCard,
  updateMaskCVV,
  validateCVV,
} from '@/helpers/masks/cardNumber';
import { updateMask as cepMask } from '@/helpers/masks/cep';
import { updateMask as expirationDateMask } from '@/helpers/masks/expirationDate';
import { updateMask as cpfCnpjMask } from '@/helpers/masks/cpfCnpj';
import { updateMask as updateMaskPhone } from '@/helpers/masks/mobilePhone';
import { updateMask as updateMaskDate } from '@/helpers/masks/generalDate';
import { api } from '@/services/api';
import Client from '@/model/Client';
import { Address } from '@/model/Address';
import { encrypt } from '@/helpers/crypt/crypt';
import Card from '@/model/Card';
import { onlyNumbers } from '@/helpers/common';
import { toast } from 'react-toastify';
import CardClientCrypt from '@/model/CardClientCrypt';
import CardClient from '@/model/CardClient';
import ChangeClientPassword from '@/model/ChangeClientPassword';
import { removeItem } from '@/helpers/common/localStorage';
import { REACT_APP_AUTH, REACT_APP_USER } from '@/utils/config';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading, useLoading } from '@/redux/loading/loadingSlice';
import cep from 'cep-promise';
import dayjs from 'dayjs';
import {
  ControllerCreditCard,
  ControllerDeleteProfile,
  FormInputName,
  FormInputNameAddress,
  FormInputNameCreditCard,
  FormInputNamePassword,
  ShouldShowModalProps,
  ShowModalProfile,
  UseModalControl,
} from '../../types';
import { ProfileContainer } from './ui';

export const ProfileScreen: React.FC = () => {
  const { visible, onSetVisible, onToggle, title, onChangeTitle } = useDialog();
  const [shouldShowModal, setShouldShowModal] = useState<ShowModalProfile>(
    ShowModalProfile.ADD_CREDIT_CARD,
  );
  const [client, setClient] = useState<Client>({} as Client);
  const [disableName, setDisableName] = useState<boolean>(false);
  const [disableBirthDate, setDisableBirthDate] = useState<boolean>(false);
  const [disableMotherName, setDisableMotherName] = useState<boolean>(false);
  const [cardId, setCardId] = useState<string>(undefined as unknown as string);

  const { loading } = useSelector(useLoading);
  const dispatch = useDispatch();
  const history = useNavigate();

  const {
    formData: formDataPersonalInfo,
    formErrors: formErrorsPersonalInfo,
    setErrors: setErrorsPersonalInfo,
    onChangeFormInput: onChangeFormInputPersonalInfo,
    isFormValid: isFormValidPersonalInfo,
    resetForm: resetFormPersonalInfo,
  } = useForm({
    initialData: {
      name: '',
      email: '',
      phone: '',
      birthDate: '',
      motherName: '',
    },
    validators: {
      name: [validators.required],
      email: [validators.required, validators.email],
      phone: [validators.required, validators.mobilePhone],
      birthDate: [validators.required, validators.birthday],
      motherName: [validators.required],
    },
    formatters: {
      phone: updateMaskPhone,
      birthDate: updateMaskDate,
    },
  });

  const {
    formData: formDataAddress,
    formErrors: formErrorsAddress,
    setErrors: setErrorsAddress,
    onChangeFormInput: onChangeFormInputAddress,
    isFormValid: isFormValidAddress,
    resetForm: resetFormAddress,
  } = useForm({
    initialData: {
      zipCode: '',
      state: '',
      city: '',
      district: '',
      street: '',
      complement: '',
      number: '',
    },
    validators: {
      zipCode: [validators.required, validators.maxLength(9)],
      state: [validators.required],
      city: [validators.required],
      district: [validators.required],
      street: [validators.required],
      number: [validators.required],
    },
    formatters: {
      zipCode: cepMask,
    },
  });

  const getUserData = async (): Promise<void> => {
    dispatch(setLoading(true));
    const { data } = await api.get<Client>('client/my-account');
    setClient(data);
    if (data.checkData) {
      setDisableName(true);
      setDisableBirthDate(true);
      setDisableMotherName(true);
    }
    onChangeFormInputPersonalInfo(FormInputName.name)(data.name ? data.name : '');
    onChangeFormInputPersonalInfo(FormInputName.email)(data.email ? data.email : '');
    onChangeFormInputPersonalInfo(FormInputName.phone)(data.cellPhone ? data.cellPhone : '');
    onChangeFormInputPersonalInfo(FormInputName.birthDate)(
      data.birthDate ? dayjs(data.birthDate).format('DD/MM/YYYY') : '',
    );
    onChangeFormInputPersonalInfo(FormInputName.motherName)(data.motherName ? data.motherName : '');
    if (data.address) {
      const address = data.address as Address;
      onChangeFormInputAddress(FormInputNameAddress.id)(address.id);
      onChangeFormInputAddress(FormInputNameAddress.zipCode)(address.zipCode);
      onChangeFormInputAddress(FormInputNameAddress.state)(address.state);
      onChangeFormInputAddress(FormInputNameAddress.city)(address.city);
      onChangeFormInputAddress(FormInputNameAddress.district)(address.district);
      onChangeFormInputAddress(FormInputNameAddress.street)(address.street);
      onChangeFormInputAddress(FormInputNameAddress.number)(address.number);
      onChangeFormInputAddress(FormInputNameAddress.complement)(address.complement);
    }

    dispatch(setLoading(false));
  };

  const handleOnChangeCEP = async (value: string): Promise<void> => {
    if (value.length === 9) {
      const cepResponse = await cep(value);
      onChangeFormInputAddress(FormInputNameAddress.state)(cepResponse.state);
      onChangeFormInputAddress(FormInputNameAddress.city)(cepResponse.city);
      onChangeFormInputAddress(FormInputNameAddress.district)(cepResponse.neighborhood);
      onChangeFormInputAddress(FormInputNameAddress.street)(cepResponse.street);
    }
  };

  const showModal = ({ value, newTitle }: ShouldShowModalProps): void => {
    setShouldShowModal(value);
    onChangeTitle(newTitle);
    onSetVisible(true);
  };

  const onSubmitPersonalInfo = async (): Promise<void> => {
    if (isFormValidPersonalInfo()) {
      try {
        dispatch(setLoading(true));
        const birthDateSplit = formDataPersonalInfo[FormInputName.birthDate].split('/');
        const birthDateString = `${birthDateSplit[2]}-${birthDateSplit[1]}-${birthDateSplit[0]}T12:00:00.000Z`;
        const clientPersonalData = {
          name: formDataPersonalInfo[FormInputName.name],
          cellPhone: formDataPersonalInfo[FormInputName.phone],
          email: formDataPersonalInfo[FormInputName.email],
          birthDate: new Date(birthDateString),
          motherName: formDataPersonalInfo[FormInputName.motherName],
        } as Client;
        await api.patch('client/my-account', clientPersonalData);
        dispatch(setLoading(false));
        toast.success('Dados pessoais alterados com sucesso!');
      } catch {
        dispatch(setLoading(false));
      }
    }
  };

  const controllerPersonalinfo: UseFormControl = {
    formData: formDataPersonalInfo,
    formErrors: formErrorsPersonalInfo,
    setErrors: setErrorsPersonalInfo,
    onChangeFormInput: onChangeFormInputPersonalInfo,
    isFormValid: isFormValidPersonalInfo,
    resetForm: resetFormPersonalInfo,
  };

  const controllerModalPayment: UseModalControl = {
    visible,
    title,
    shouldShowModal,
    onShouldShowModal: showModal,
    onToggleModal: onToggle,
    onSetVisible,
  };

  const {
    formData: formDataChangePassword,
    formErrors: formErrorsChangePassword,
    setErrors: setErrorsChangePassword,
    onChangeFormInput: onChangeFormInputChangePassword,
    isFormValid: isFormValidChangePassword,
    resetForm: resetFormChangePassword,
  } = useForm({
    initialData: {
      password: '',
      confirmPassword: '',
    },
    validators: {
      password: [
        validators.required,
        validators.minLength(8),
        validators.maxLength(15),
        validators.hasPasswordOnlyNumberCharacteres,
      ],
      confirmPassword: [
        validators.required,
        validators.minLength(8),
        validators.maxLength(15),
        validators.hasPasswordOnlyNumberCharacteres,
      ],
    },
    formatters: {},
  });

  const onSubmitChangePassword = async (): Promise<void> => {
    if (isFormValidChangePassword()) {
      try {
        dispatch(setLoading(true));

        const change: ChangeClientPassword = {
          password: formDataChangePassword[FormInputNamePassword.password],
          confirmPassword: formDataChangePassword[FormInputNamePassword.confirmPassword],
        };
        await api.patch('client/my-account/password', change);
        resetFormChangePassword();
        dispatch(setLoading(false));
        toast.success('Senha alterada com sucesso!');
      } catch {
        dispatch(setLoading(false));
      }
    }
  };

  const controllerChangePassoword: UseFormControl = {
    formData: formDataChangePassword,
    formErrors: formErrorsChangePassword,
    setErrors: setErrorsChangePassword,
    onChangeFormInput: onChangeFormInputChangePassword,
    isFormValid: isFormValidChangePassword,
    resetForm: resetFormChangePassword,
  };

  const onSubmitAddress = async (): Promise<void> => {
    if (isFormValidAddress()) {
      try {
        dispatch(setLoading(true));
        const address: Address = {
          id: formDataAddress[FormInputNameAddress.id],
          zipCode: formDataAddress[FormInputNameAddress.zipCode],
          state: formDataAddress[FormInputNameAddress.state],
          city: formDataAddress[FormInputNameAddress.city],
          district: formDataAddress[FormInputNameAddress.district],
          street: formDataAddress[FormInputNameAddress.street],
          complement: formDataAddress[FormInputNameAddress.complement],
          number: formDataAddress[FormInputNameAddress.number],
        };
        await api.patch('client/my-account/address', address);
        await getUserData();
        dispatch(setLoading(false));
        toast.success('Endereço alterado com sucesso!');
      } catch {
        dispatch(setLoading(false));
      }
    }
  };

  const controllerAddress: UseFormControl = {
    formData: formDataAddress,
    formErrors: formErrorsAddress,
    setErrors: setErrorsAddress,
    onChangeFormInput: onChangeFormInputAddress,
    isFormValid: isFormValidAddress,
    resetForm: resetFormAddress,
  };

  const {
    formData: formDataAddCreditCard,
    formErrors: formErrorsAddCreditCard,
    setErrors: setErrorsAddCreditCard,
    onChangeFormInput: onChangeFormInputAddCreditCard,
    isFormValid: isFormValidAddCreditCard,
    resetForm: resetFormAddCreditCard,
  } = useForm({
    initialData: {
      number: '',
      date: '',
      cvv: '',
      name: '',
      document: '',
    },
    validators: {
      number: [validators.required, validators.cardNumber],
      date: [validators.required, validators.cardExpirationDate],
      cvv: [validators.required, validators.cvv],
      name: [validators.required],
      document: [validators.required, validators.cpforcnpj],
    },
    formatters: {
      number: updateMaskCard,
      date: expirationDateMask,
      cvv: updateMaskCVV,
      name: updateHolderName,
      document: cpfCnpjMask,
    },
  });

  const onSubmitAddCreditCard = async (): Promise<void> => {
    if (isFormValidAddCreditCard()) {
      if (
        validateCVV(
          formDataAddCreditCard[FormInputNameCreditCard.number],
          formDataAddCreditCard[FormInputNameCreditCard.cvv],
        )
      ) {
        try {
          dispatch(setLoading(true));

          const expDate = formDataAddCreditCard[FormInputNameCreditCard.date].split('/');

          const card: Card = {
            holder: formDataAddCreditCard[FormInputNameCreditCard.name],
            number: onlyNumbers(formDataAddCreditCard[FormInputNameCreditCard.number]),
            expMonth: expDate[0],
            expYear: `20${expDate[1]}`,
            securityCode: formDataAddCreditCard[FormInputNameCreditCard.cvv],
            document: onlyNumbers(formDataAddCreditCard[FormInputNameCreditCard.document]),
            cardType: getCreditCardLabel(formDataAddCreditCard[FormInputNameCreditCard.number]),
          };

          const encrypted = encrypt(JSON.stringify(card));

          const cardClientCrypt: CardClientCrypt = {
            data: encrypted,
          };
          const response = await api.post<CardClient>('client/my-account/card', cardClientCrypt);
          const cards =
            client.cards && client.cards.length > 0 ? client.cards : ([] as CardClient[]);
          cards.push(response.data);
          const newClient = {
            ...client,
            cards,
          };
          setClient(newClient);
          resetFormAddCreditCard();
          onSetVisible(false);
          dispatch(setLoading(false));
          toast.success('Cartão adicionado com sucesso!');
        } catch {
          dispatch(setLoading(false));
        }
      } else {
        toast.warn('Código de segurança inválido!');
      }
    }
  };

  const controllerAddCreditCard: UseFormControl = {
    formData: formDataAddCreditCard,
    formErrors: formErrorsAddCreditCard,
    setErrors: setErrorsAddCreditCard,
    onChangeFormInput: onChangeFormInputAddCreditCard,
    isFormValid: isFormValidAddCreditCard,
    resetForm: resetFormAddCreditCard,
  };

  const onShowAddCreditCard = (): void => {
    showModal({
      value: ShowModalProfile.ADD_CREDIT_CARD,
      newTitle: 'Adicionar novo cartão',
    });
  };

  const onShowDeleteCreditCard = (id: string): void => {
    setCardId(id);
    showModal({
      value: ShowModalProfile.CONFIRM_DELETE_CREDIT_CARD,
      newTitle: '',
    });
  };

  const onSubmitDeleteCreditCard = async (): Promise<void> => {
    try {
      dispatch(setLoading(true));
      await api.delete<CardClient>(`client/my-account/card/${cardId}`);
      await getUserData();
      onSetVisible(false);
      dispatch(setLoading(false));
      toast.success('Cartão removido com sucesso!');
    } catch {
      dispatch(setLoading(false));
    }
  };

  const controllerCreditCard: ControllerCreditCard = {
    list: client.cards ? client.cards : [],
    onShowAddCreditCard,
    onShowDeleteCreditCard,
    onSubmitDeleteCreditCard,
    controllerAddCreditCard,
  };

  const handleOnShowDeleteProfile = (): void => {
    showModal({
      value: ShowModalProfile.CONFIRM_DELETE_PROFILE,
      newTitle: '',
    });
  };

  const handleOnSubmitDeleteProfile = async (): Promise<void> => {
    try {
      dispatch(setLoading(true));
      await api.delete<CardClient>('client/my-account');
      onSetVisible(false);
      dispatch(setLoading(false));
      toast.success('Conta excluida com sucesso!');

      removeItem(String(REACT_APP_AUTH));
      removeItem(String(REACT_APP_USER));
      history('/');
    } catch {
      dispatch(setLoading(false));
    }
  };

  const controllerDeleteProfile: ControllerDeleteProfile = {
    onShowDeleteProfile: handleOnShowDeleteProfile,
    onSubmitDeleteProfile: handleOnSubmitDeleteProfile,
  };

  useEffect(() => {
    getUserData();
  }, []);

  return (
    <ProfileContainer
      state={loading}
      controllerPersonalInfo={controllerPersonalinfo}
      disableName={disableName}
      disableBirthDate={disableBirthDate}
      disableMotherName={disableMotherName}
      controllerChangePassword={controllerChangePassoword}
      controllerAddress={controllerAddress}
      controllerModalProfile={controllerModalPayment}
      controllerCreditCard={controllerCreditCard}
      controllerDeleteProfile={controllerDeleteProfile}
      onSubmitPersonalInfo={onSubmitPersonalInfo}
      onSubmitChangePassword={onSubmitChangePassword}
      onChangeCEP={handleOnChangeCEP}
      onSubmitAddress={onSubmitAddress}
      onSubmitAddCreditCard={onSubmitAddCreditCard}
    />
  );
};
