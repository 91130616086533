import React from 'react';
import { SliderEvent, Range } from '@/components';
import { FilterEventContent, HeroSearchContent } from '@/features/home/components';
import { AuthWrapper } from '@/features/core/auth/screens/AuthWrapper';
import EventCategorySite from '@/model/EventCategorySite';
import { Address } from '@/model/Address';
import EventSite from '@/model/EventSite';

interface StateProps {
  inputSearch: string;
  eventsSearch: EventSite[];
  inputFilter: string;
  cities: Address[];
  selectedCity: Address;
  dateRange: Range[] | undefined;
  events: EventCategorySite[];
  eventsMoreSeen: EventCategorySite[];
  rangeDateSelected: Range[] | undefined;
}

interface DispathProps {
  onChangeInputSearch: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSelectEvent: (event: EventSite) => void;
  onClearInputSearch: () => void;
  onChangeInputFilter: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClearInputFilter: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onChangeCity: (address: Address) => void;
  onChangeDateRange: (dataRange: Range[]) => void;
  onClearRangeDateSelected: () => void;
}

type HomeContainerProps = StateProps & DispathProps;

export const HomeContainer: React.FC<HomeContainerProps> = ({
  inputSearch,
  eventsSearch,
  inputFilter,
  cities,
  selectedCity,
  dateRange,
  onChangeInputSearch,
  onSelectEvent,
  onClearInputSearch,
  onChangeInputFilter,
  onClearInputFilter,
  onChangeCity,
  onChangeDateRange,
  events,
  eventsMoreSeen,
  rangeDateSelected,
  onClearRangeDateSelected,
}) => (
  <AuthWrapper isHomepage>
    <HeroSearchContent
      search={inputSearch}
      events={eventsSearch}
      onChangeInputSearch={onChangeInputSearch}
      onSelectEvent={onSelectEvent}
      onClearInputSearch={onClearInputSearch}
    />
    <FilterEventContent
      inputFilter={inputFilter}
      cities={cities}
      selectedCity={selectedCity}
      dateRange={dateRange}
      onChangeInputFilter={onChangeInputFilter}
      onClearInputFilter={onClearInputFilter}
      onChangeCity={onChangeCity}
      onChangeDateRange={onChangeDateRange}
      rangeDateSelected={rangeDateSelected}
      onClearRangeDateSelected={onClearRangeDateSelected}
    />

    <section className="space-y-[70px] mt-[60px] mb-[100px]">
      {events &&
        events.length > 0 &&
        events.map(data => (
          <SliderEvent
            key={data.id}
            title={data.name}
            total={data.events.length}
            routePath={`/categoria/${data.name}/${data.id}`}
            events={data.events}
            onSelectEvent={onSelectEvent}
          />
        ))}
      {eventsMoreSeen &&
        eventsMoreSeen.length > 0 &&
        eventsMoreSeen.map(data => (
          <SliderEvent
            key={data.id}
            title={data.name}
            total={data.events.length}
            routePath={`/categoria/${data.name}/${data.id}`}
            events={data.events}
            onSelectEvent={onSelectEvent}
          />
        ))}
    </section>
  </AuthWrapper>
);
