import React from 'react';
import { HomeScreen } from '@/features/home/screens/Home';
import { AboutScreen } from '@/features/home/screens/About';
import { ContactsScreen } from '@/features/home/screens/Contacts';
import { PoliticsScreen } from '@/features/home/screens/Politics';
import { PoliticsFacebookContent } from '@/features/core/auth/components/PoliticsFacebookContent';
import { TermsContent } from '@/features/core/auth/components';
import { EventByCategoryScreen } from '@/features/home/screens/EventByCategory';
import { EventScreen } from '@/features/event/screens/Event';
import { PdvScreen } from '@/features/pdv/screens/Pdv';
import { CheckoutScreen } from '@/features/checkout/screens/Checkout';
import { ProfileScreen } from '@/features/profile/screens/Profile';
import { TicketsScreen } from '@/features/tickets/screens/Tickets';
import { TicketsHistoryScreen } from '@/features/tickets/screens/TicketsHistory';
import { TicketPrintScreen } from '@/features/tickets/screens/TicketPrint';
import { TicketTransferScreen } from '@/features/tickets/screens/TicketTransfer';
import { TicketDetailScreen } from '@/features/tickets/screens/TicketDetail';
import { TicketsOrderScreen } from '@/features/tickets/screens/TicketOrder';
import { OrderScreen } from '@/features/order/screens/Order';

export const routes = [
  { path: `/`, Component: <HomeScreen /> },
  { path: `/quemsomos`, Component: <AboutScreen /> },
  { path: `/contato-suporte`, Component: <ContactsScreen /> },
  { path: `/politicas`, Component: <PoliticsScreen /> },
  { path: `/politics`, Component: <PoliticsFacebookContent /> },
  { path: `/termos`, Component: <TermsContent /> },
  { path: `/categoria/:name/:id`, Component: <EventByCategoryScreen /> },
  { path: `/event/:nameEvent/:city/:state`, Component: <EventScreen /> },
  { path: `/pdv/:pdvId/event/:eventId`, Component: <PdvScreen /> },
  { path: `/checkout`, Component: <CheckoutScreen /> },
  { path: `/profile`, Component: <ProfileScreen /> },
  { path: '/ingressos', Component: <TicketsScreen /> },
  { path: '/ingressos/historico', Component: <TicketsHistoryScreen /> },
  { path: '/ingressos/imprimir/:id', Component: <TicketPrintScreen /> },
  { path: '/ingressos/tranferir/:id', Component: <TicketTransferScreen /> },
  { path: '/ingressos/detalhe/:id', Component: <TicketDetailScreen /> },
  { path: '/ingressos/pedidos', Component: <TicketsOrderScreen /> },
  { path: '/order/:orderId', Component: <OrderScreen /> },
];
