import { Ring } from '@uiball/loaders';
import React, { JSX } from 'react';

export const Loading3DSValidation: React.FC = (): JSX.Element => (
  <div className="h-screen flex items-center justify-center  align-middle">
    <div className="w-full container mx-auto h-full">
      <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
        <div
          className={`relative transform overflow-hidden rounded-lg text-left transition-all sm:w-full md:w-[75px]`}
        >
          <Ring size={75} lineWeight={5} speed={2} color="#ed8936" />
        </div>
      </div>
    </div>
  </div>
);
