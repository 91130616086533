import React from 'react';
import * as PopoverRoot from '@radix-ui/react-popover';
import { classNames } from '@/helpers/common';
import * as S from './styles';

interface PopoverProps {
  trigger: React.ReactNode;
  children: React.ReactNode;
  className: string;
  open?: boolean;
  setOpen?: (open: boolean) => void;
}

export const Popover: React.FC<PopoverProps> = ({
  trigger,
  children,
  className,
  open,
  setOpen,
}) => (
  <PopoverRoot.Root open={open} onOpenChange={setOpen}>
    <PopoverRoot.Trigger>{trigger}</PopoverRoot.Trigger>
    <PopoverRoot.Portal>
      <PopoverRoot.Content>
        <S.StyledContent className={classNames(className)}>
          {children}
          <S.StyledArrow />
        </S.StyledContent>
      </PopoverRoot.Content>
    </PopoverRoot.Portal>
  </PopoverRoot.Root>
);
