import dayjs from 'dayjs';
import { IconWarning } from '@/assets/icons/IconWarning';
import { Button, TextArea } from '@/components';
import Order from '@/model/Order';
import { UseFormControl } from 'src/types';
import React from 'react';
import { FormInputName } from '../types';

interface Props {
  state: boolean;
  order: Order;
  toCurrency: (value: number) => string;
  onSubmit: () => void;
  controllerCancelTicketAfter: UseFormControl;
}

export const ConfirmCancelTicketAfterContent: React.FC<Props> = ({
  state,
  order,
  controllerCancelTicketAfter,
  toCurrency,
  onSubmit,
}) => (
  <div className="w-full mx-auto font-dmsans ">
    <div className="flex flex-col justify-center">
      <IconWarning height="63px" className="self-center text-[#D8231B] mb-[30px]" />
      <h4 className="font-bold text-center text-neutro-b-400 text-[21px] mb-[5px]">
        Você tem certeza que quer cancelar o seu pedido {order.id}?
      </h4>
      <ul className="mb-[20px]">
        {/*
          <li className="list-disc list-inside text-base font-normal text-neutro-w-600 mb-[30px]">
            <span className="font-bold text-primary-400">
              Você ultrapassou o prazo de 7 dias
            </span>{' '}
            para solicitar o cancelamento do seu pedido;
          </li>
           */}
        <li className="list-disc list-inside text-base font-normal text-neutro-w-600">
          Preencha abaixo o motivo do seu cancelamento que iremos analisar a sua solicitação.
        </li>
      </ul>

      <div className="mb-[20px]">
        <TextArea
          name="reason"
          placeholder="Digite aqui o motivo da sua solicitação de cancelamento"
          maxLength={100}
          rows={6}
          value={controllerCancelTicketAfter.formData[FormInputName.reason]}
          onChange={e =>
            controllerCancelTicketAfter.onChangeFormInput(FormInputName.reason)(e.target.value)
          }
          error={
            controllerCancelTicketAfter.formErrors.reason &&
            controllerCancelTicketAfter.formErrors.reason[0]
          }
        />
      </div>
      <div className="flex justify-end">
        <Button
          type="submit"
          title="Enviar"
          buttonStyle="primary"
          size="xlg"
          className=""
          disabled={state || controllerCancelTicketAfter.formData[FormInputName.reason] === ''}
          onClick={() => onSubmit()}
        />
      </div>
      <div className="flex flex-wrap justify-between items-center">
        <h5 className="text-[21px] font-medium leading-normal text-neutro-b-400">Seu pedido</h5>
      </div>
      <div className="cart-itens w-[380px]">
        {order.events.map(event => (
          <div key={event.event.id} className=" cart-item w-full border-b border-neutro-w-200 py-5">
            <header className="grid grid-cols-[115px_1fr] items-center gap-5 mb-[40px]">
              <div className="relative">
                <img className="w-[115px] rounded-md" src={event.event.imageUrl} alt="Event" />
                <div className="absolute rounded-md top-0 w-full h-full bg-card-default hover:bg-card-hover transition-all duration-200 ease-in-out"></div>
              </div>
              <div className="text-left">
                <h4 className="text-neutral-b-400 font-dmsans font-medium text-[21px]">
                  {event.event.name}
                </h4>
                <div className="flex items-center text-base">
                  <span className="font-dmsans font-normal text-neutro-w-600 uppercase">
                    {dayjs(event.event.startDate).locale('pt-br').format('DD MMM')} {'>'}{' '}
                    {dayjs(event.event.endDate).locale('pt-br').format('DD MMM')}
                  </span>
                  <span className="w-[6px] h-[6px] mx-2 bg-gray-300 rounded-full"></span>
                  <span className="font-dmsans font-normal text-neutro-w-600">
                    {event.event.city}/{event.event.state}
                  </span>
                </div>
              </div>
            </header>
            {event.items.map((data, index) => (
              <div key={index}>
                <div
                  className="font-dmsans font-normal text-[21px]
                          leading-[27px] text-neutro-b-400"
                >
                  {data.name} ({data.amount} unidades)
                </div>
                <div className="font-dmsans text-base text-neutro-w-600">
                  Subtotal: <span className="font-bold">{toCurrency(data.unitValue)}</span>
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
      <div className="w-[380px] mt-[40px]">
        <div className="flex justify-between items-center mb-5">
          <div className="text-heading font-dmsans font-normal">
            Total de itens: <span className="font-bold">{order.amount}</span>
          </div>
          <div className="text-heading font-dmsans font-normal">
            Valor total: <span className="font-bold">{toCurrency(order.totalValue)}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
);
