import React, { JSX, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { api } from '@/services/api';
import OrderSite from '@/model/OrderSite';
import { EventDate, EventDateSectionSite, EventTicketSite } from '@/features/event/screens/Event';
import CheckDiscount from '@/model/CheckDiscount';
import Payment from '@/model/Payment';
import SendPayment from '@/model/SendPayment';
import OrderResponse from '@/model/OrderResponse';
import CartStorage from '@/features/core/cart/components/Cart';
import { CheckoutMain } from '@/features/checkout/screens/Checkout';
import { setItem } from '@/helpers/common/localStorage';
import { REACT_CART } from '@/utils/config';
import CartPayment from '@/model/CartPayment';
import { AxiosError, AxiosResponse } from 'axios';
import Cart from '@/model/Cart';

export const OrderScreen: React.FC = (): JSX.Element => {
  const { orderId } = useParams();

  const [mountCart, setMountCart] = useState<boolean>(false);

  const handleOnGetOrder = async (): Promise<void> => {
    const { data } = await api.get<OrderSite>(`/order/${orderId}`);
    const events: EventDate[] = [];
    if (data.items && data.items.length > 0) {
      data.items.forEach(item => {
        let foundEvent = false;
        // eslint-disable-next-line no-plusplus
        for (let e = 0; e < events.length; e++) {
          if (item.event.id === events[e].id) {
            foundEvent = true;
            let foundSection = false;
            // eslint-disable-next-line no-plusplus
            for (let s = 0; s < events[e].sections.length; s++) {
              if (item.orderTicket.section.id === events[e].sections[s].section.id) {
                foundSection = true;
                let foundTicket = false;
                // eslint-disable-next-line no-plusplus
                for (let t = 0; t < events[e].sections[s].tickets.length; t++) {
                  if (item.orderTicket.id === events[e].sections[s].tickets[t].id) {
                    foundTicket = true;
                    events[e].sections[s].tickets[t].quantity += 1;
                    events[e].sections[s].tickets[t].count += 1;
                    break;
                  }
                }
                if (!foundTicket) {
                  let totalValue = Number(item.unitValue);
                  if (item.fee && Number(item.fee) > 0) {
                    totalValue *= Number(item.fee);
                  }
                  const labels: string[] = [];
                  if (item.itemLabel) {
                    labels.push(item.itemLabel);
                  }
                  const payment: Payment = {
                    installmentLimit: item.orderTicket.ticketPayment.websiteSale.installments,
                    allowFractionalPayment: item.orderTicket.ticketPayment.allowFractionalPayment,
                    allowPaymentBankSlip: item.orderTicket.ticketPayment.allowPaymentBankSlip,
                    allowPaymentPIX: item.orderTicket.ticketPayment.allowPaymentPIX,
                    allowContactlessPayment: item.orderTicket.ticketPayment.allowContactlessPayment,
                    fees: item.orderTicket.ticketPayment.websiteSale,
                    allowDiscount: item.orderTicket.ticketPayment.allowDiscountCoupon,
                  };
                  const newTicket: EventTicketSite = {
                    id: item.orderTicket.id,
                    name: item.orderTicket.name,
                    unitValue: item.unitValue,
                    fee: item.fee,
                    totalValue,
                    isHalfPrice: item.halfPrice,
                    quantity: 1,
                    labels,
                    count: 1,
                    allowDiscount: false,
                    nameBeforePurchase: false,
                    requestCpf: false,
                    payment,
                    hasMap: false,
                    userDatas: [],
                  };
                  events[e].sections[s].tickets.push(newTicket);
                }
                break;
              }
            }
            if (!foundSection) {
              const tickets: EventTicketSite[] = [];
              let totalValue = Number(item.unitValue);
              if (item.fee && Number(item.fee) > 0) {
                totalValue *= Number(item.fee);
              }
              const labels: string[] = [];
              if (item.itemLabel) {
                labels.push(item.itemLabel);
              }
              const payment: Payment = {
                installmentLimit: item.orderTicket.ticketPayment.websiteSale.installments,
                allowFractionalPayment: item.orderTicket.ticketPayment.allowFractionalPayment,
                allowPaymentBankSlip: item.orderTicket.ticketPayment.allowPaymentBankSlip,
                allowPaymentPIX: item.orderTicket.ticketPayment.allowPaymentPIX,
                allowContactlessPayment: item.orderTicket.ticketPayment.allowContactlessPayment,
                fees: item.orderTicket.ticketPayment.websiteSale,
                allowDiscount: item.orderTicket.ticketPayment.allowDiscountCoupon,
              };
              const newTicket: EventTicketSite = {
                id: item.orderTicket.id,
                name: item.orderTicket.name,
                unitValue: item.unitValue,
                fee: item.fee,
                totalValue,
                isHalfPrice: item.halfPrice,
                quantity: 1,
                labels,
                count: 1,
                allowDiscount: false,
                nameBeforePurchase: false,
                requestCpf: false,
                payment,
                hasMap: false,
                userDatas: [],
              };
              tickets.push(newTicket);
              const section: EventDateSectionSite = {
                section: item.orderTicket.section,
                count: 0,
                tickets,
              };
              events[e].sections.push(section);
            }
            break;
          }
        }
        if (!foundEvent) {
          const sections: EventDateSectionSite[] = [];
          const tickets: EventTicketSite[] = [];
          let totalValue = Number(item.unitValue);
          if (item.fee && Number(item.fee) > 0) {
            totalValue *= Number(item.fee);
          }
          const labels: string[] = [];
          if (item.itemLabel) {
            labels.push(item.itemLabel);
          }
          const payment: Payment = {
            installmentLimit: item.orderTicket.ticketPayment.websiteSale.installments,
            allowFractionalPayment: item.orderTicket.ticketPayment.allowFractionalPayment,
            allowPaymentBankSlip: item.orderTicket.ticketPayment.allowPaymentBankSlip,
            allowPaymentPIX: item.orderTicket.ticketPayment.allowPaymentPIX,
            allowContactlessPayment: item.orderTicket.ticketPayment.allowContactlessPayment,
            fees: item.orderTicket.ticketPayment.websiteSale,
            allowDiscount: item.orderTicket.ticketPayment.allowDiscountCoupon,
          };
          const newTicket: EventTicketSite = {
            id: item.orderTicket.id,
            name: item.orderTicket.name,
            unitValue: item.unitValue,
            fee: item.fee,
            totalValue,
            isHalfPrice: item.halfPrice,
            quantity: 1,
            labels,
            count: 1,
            allowDiscount: false,
            nameBeforePurchase: false,
            requestCpf: false,
            payment,
            hasMap: false,
            userDatas: [],
          };
          tickets.push(newTicket);
          const section: EventDateSectionSite = {
            section: item.orderTicket.section,
            count: 0,
            tickets,
          };
          sections.push(section);
          const event: EventDate = {
            id: item.event.id,
            name: item.event.name,
            address: item.event.address,
            imageUrl: item.event.imageUrl,
            imageDetailUrl: item.event.imageDetailUrl,
            websiteDescription: item.event.websiteDescription,
            imageDescriptionUrl: item.event.imageDescriptionUrl,
            discountDescription: item.event.discountDescription,
            count: 0,
            isPassport: false,
            sections,
            date: item.event.startDate,
            startDate: item.event.startDate,
            endDate: item.event.endDate,
            allowDiscount: false,
          };
          events.push(event);
        }
      });
    }
    let discountCoupon: CheckDiscount | undefined;
    if (data.discountCoupon || data.voucher) {
      discountCoupon = {
        discountCoupon: data.discountCoupon,
        voucher: data.voucher,
      };
    }
    const newCart: CartStorage = {
      events,
      amountItens: Number(data.amount),
      totalValue: Number(data.totalValue),
      totalValueWithFee: Number(data.totalValue),
      originalValueWithPaymentFee: Number(data.totalValue),
      totalValueWithPaymentFee: Number(data.totalValue),
      discountCoupon,
    };
    let amountItens = 0;
    let totalValue = 0;
    let totalValueWithFee = 0;
    newCart.events.forEach(event => {
      event.sections.forEach(section => {
        section.tickets.forEach(ticket => {
          amountItens += Number(ticket.quantity);
          totalValue += Number(ticket.unitValue) * Number(ticket.quantity);
          if (ticket.fee && Number(ticket.fee) > 0) {
            totalValueWithFee +=
              Number(ticket.unitValue) * Number(ticket.fee) * Number(ticket.quantity);
          } else {
            totalValueWithFee += Number(ticket.unitValue) * Number(ticket.quantity);
          }
        });
      });
    });
    newCart.amountItens = amountItens;
    newCart.totalValue = totalValue;
    newCart.totalValueWithFee = totalValueWithFee;
    setItem(REACT_CART as string, JSON.stringify(newCart));
    setMountCart(true);
  };

  const handleOnSendOrderToAPIAdm = async (
    payments: CartPayment[],
  ): Promise<AxiosResponse<OrderResponse[]>> => {
    const newOrder: SendPayment = {
      orderId: orderId as string,
      payments,
    };

    const response = await api.post<OrderResponse[]>('/order', newOrder);
    return response;
  };

  const handleOnSendOrderToAPIEcommerce = async (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    order: Cart,
  ): Promise<AxiosResponse<OrderResponse[]>> => {
    throw new AxiosError('Not Implemented!');
  };

  useEffect(() => {
    handleOnGetOrder();
  }, [orderId]);

  return (
    <>
      {mountCart && (
        <CheckoutMain
          cameFromAdm={true}
          sendOrderToAPIEcommerce={handleOnSendOrderToAPIEcommerce}
          sendOrderToAPIAdm={handleOnSendOrderToAPIAdm}
        />
      )}
    </>
  );
};
