import * as Dialog from '@radix-ui/react-dialog';
import React from 'react';
// import { useState } from 'react';
import {
  // CaretDown,
  Close,
  // Sizes
} from '@/assets/icons';
import { classNames } from '@/helpers/common';
import { getBoolean } from '@/helpers/common/localStorage';
// import { colors } from '@/styles/colors';
import { REACT_APP_AUTH } from '@/utils/config';

export interface SidebarProps {
  visible: boolean;
  onSetVisible?: (open: boolean) => void;
  handleOnSignIn?: () => void;
  handleOnSignUp?: () => void;
  handleOnLogout?: () => void;
  goTo: (href: string) => void;
}

export const Menu: React.FC<SidebarProps> = ({
  visible,
  onSetVisible,
  handleOnSignIn,
  handleOnSignUp,
  handleOnLogout,
  goTo,
}) => {
  // const [open, setOpen] = useState(false);
  const signed = getBoolean(String(REACT_APP_AUTH));

  return (
    <Dialog.Root open={visible} onOpenChange={onSetVisible}>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed inset-0 bg-modal-overlay">
          <Dialog.Content className="fixed inset-0 z-10">
            <div
              className="flex h-full items-start justify-end text-left sm:items-center sm:p-0
            md:radix-state-closed:translate-x-[244px]
            md:radix-state-open:translate-x-0 animate-enter-from-right"
            >
              <div
                className="relative z-50 pt-[46px] w-full h-full md:w-[244px]
                bg-white font-dmsans shadow-xl rounded-lg"
              >
                <div className="flex flex-col justify-between items-center">
                  <div className="self-end p-0 pr-[40px]">
                    <Dialog.Close asChild>
                      <img src={Close} className="cursor-pointer" alt="button closer" />
                    </Dialog.Close>
                  </div>
                </div>
                <nav className="flex flex-col mx-10 font-dmsans">
                  <a
                    href="#"
                    tabIndex={-1}
                    onClick={() => goTo('/profile')}
                    className="text-base font-bold mb-[17px] cursor-pointer"
                  >
                    Meu perfil
                  </a>
                  <a
                    href="#"
                    tabIndex={-1}
                    onClick={() => goTo('/ingressos/pedidos')}
                    className="text-base font-bold mb-[17px] cursor-pointer"
                  >
                    Meus Pedidos
                  </a>
                  <a
                    className="text-base font-bold mb-[17px] cursor-pointer"
                    onClick={() => {
                      goTo('/quemsomos');
                      if (onSetVisible) {
                        onSetVisible(false);
                      }
                    }}
                  >
                    Quem somos
                  </a>
                  <a
                    className="text-base font-bold mb-[17px] cursor-pointer"
                    onClick={() => {
                      goTo('/contato-suporte');
                      if (onSetVisible) {
                        onSetVisible(false);
                      }
                    }}
                  >
                    Contato e Suporte
                  </a>
                  <a
                    className="text-base font-bold mb-[17px] cursor-pointer"
                    onClick={() => {
                      goTo('/politicas');
                      if (onSetVisible) {
                        onSetVisible(false);
                      }
                    }}
                  >
                    Políticas
                  </a>
                  <a
                    href="#"
                    tabIndex={-1}
                    onClick={handleOnLogout}
                    className="text-base font-bold mb-[17px] cursor-pointer"
                  >
                    Sair
                  </a>
                </nav>

                {/* (
                  <nav className="flex flex-col mx-10 font-dmsans">
                    <a
                      href="#"
                      className="text-base font-bold mb-[17px] flex"
                      onClick={() => setOpen(!open)}
                    >
                      <span className="mr-3">Minha conta</span>
                      <CaretDown
                        style={{
                          transform: open ? 'rotate(-180deg)' : 'rotate(0deg)',
                        }}
                        size={Sizes.xmedium}
                        color={colors.graylight}
                      />
                    </a>
                    {open && (
                      <nav className="flex flex-col mx-5 font-dmsans"></nav>
                    )}
                  </nav>
                ) : */}

                {!signed && (
                  <nav className="flex flex-col text-center mx-10 font-dmsans mt-[40px]">
                    <button
                      id="btnDoLogin"
                      className={classNames(
                        'px-10 py-2 transition-colors duration-150 rounded-lg focus:shadow-outline bg-neutro-b-400 hover:bg-neutro-b-600 active:bg-neutro-b-400 text-base font-bold font-dmsans text-white',
                      )}
                      onClick={handleOnSignIn}
                    >
                      Entrar
                    </button>

                    <a
                      href="#"
                      className={classNames('font-dmsans bg-white text-black font-bold p-5')}
                      onClick={handleOnSignUp}
                    >
                      Criar conta
                    </a>
                  </nav>
                )}
              </div>
            </div>
          </Dialog.Content>
        </Dialog.Overlay>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
