import React from 'react';

interface IconProps extends React.SVGProps<SVGSVGElement> {
  children?: React.ReactNode;
}

export const IconWarning: React.FC<IconProps> = props => (
  <svg
    viewBox="0 0 76 63"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="xMaxYMax"
    {...props}
  >
    <path
      d="M0.5 62.4471L38 0.218262L75.5 62.4471H0.5ZM9.36364 57.5343H66.6364L38 10.0439L9.36364 57.5343ZM38.3409 52.8671C39.0795 52.8671 39.6903 52.6351 40.1733 52.1711C40.6562 51.7072 40.8977 51.1203 40.8977 50.4107C40.8977 49.7011 40.6562 49.1143 40.1733 48.6503C39.6903 48.1863 39.0795 47.9543 38.3409 47.9543C37.6023 47.9543 36.9915 48.1863 36.5085 48.6503C36.0256 49.1143 35.7841 49.7011 35.7841 50.4107C35.7841 51.1203 36.0256 51.7072 36.5085 52.1711C36.9915 52.6351 37.6023 52.8671 38.3409 52.8671ZM35.7841 43.7784H40.8977V25.4373H35.7841V43.7784Z"
      fill="currentColor"
    />
    {props.children}
  </svg>
);
