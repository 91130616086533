import dayjs from 'dayjs';
import {
  Close,
  Cupom,
  IconCreditCard,
  IconPix,
  IconSecurity,
  IconTicketPayment,
} from '@/assets/icons';
import { AuthWrapper } from '@/features/core/auth/screens/AuthWrapper';
import { UseFormControl } from 'src/types';
import { Button, InputText, Modal } from '@/components';
import { TabTypePayments } from '@/components/TabTypePayments';
import {
  CardCheckout,
  FormInputNameAddress,
  FormInputNameCheckout,
  ShowModalPayment,
  UseModalControl,
} from '@/features/checkout/types';
import { classNames } from '@/helpers/common';
import { CreditCardPaymentContent } from '@/features/checkout/components/CreditCardPaymentContent';
import { PixPaymentContent } from '@/features/checkout/components/PixPaymentContent';
import { TicketPaymentContent } from '@/features/checkout/components/TicketPaymentContent';
import Cart from '@/features/core/cart/components/Cart';
import { TicketIncrementResume } from '@/features/core/auth/components/TicketIncrementResume';
import { ProductIncrementResume } from '@/features/core/auth/components/ProductIncrementResume';
import PaymentType from '@/model/PaymentType';
import { CreditCardPaymentRequestContent } from '@/features/checkout/components/CreditCardPaymentRequestContent';
import Payment from '@/model/Payment';
import React, { useEffect, useState } from 'react';
import { CreditCardPaymentAddContent } from '@/features/checkout/components/CreditCardPaymentAddContent';
import { AddressContent } from '@/features/checkout/components/AddressContent';
import { ConfirmDeleteCreditCardContent } from '@/features/profile/components/ConfirmDeleteCreditCardContent';
import { ControllerCreditCard } from '@/features/profile/types';
import CardDb from '@/model/CardDb';
import { PaymentErroContent } from '@/features/checkout/components/PaymentError';
import { PaymentNotMadeContent } from '@/features/checkout/components/PaymentNotMade';
import { ValidateCreditCardContent } from '@/features/checkout/components/ValidateCreditCard';
import { ValidatingCreditCardContent } from '@/features/checkout/components/ValidatingCreditCard';
import CardClient from '@/model/CardClient';
import { CardValidationResponse } from '@/model/CardValidationResponse';
import { ValidatedCreditCardContent } from '@/features/checkout/components/ValidatedCreditCard';
import { ValidatedErrorCreditCardContent } from '@/features/checkout/components/ValidatedErrorCreditCard';
import { InputTicketsTransfer } from '@/features/checkout/components/InputTicketsTransfer';
import { EventTicketSite } from '@/features/event/screens/Event';

interface PaymentTab {
  text: string;
  icon: React.ReactNode;
  type: PaymentType;
}

interface PaymentContent {
  content: React.ReactNode;
  type: PaymentType;
}
interface PaymentTabAndContent {
  tabs: PaymentTab[];
  contents: PaymentContent[];
}

interface StateProps {
  cart: Cart;
  loadedUserData: boolean;
  isWithin24Hours: boolean;
  cameFromAdm: boolean;
  controllerPersonalinfo: UseFormControl;
  paymentType: PaymentType;
  cards: CardCheckout[];
  payment: Payment;
  controllerModalPayment: UseModalControl;
  qrCodeImage: string;
  qrCodeText: string;
  boleto: string;
  barcode: string;
  messagePayment?: string;
  messageError: string[];
  controllerAddress: UseFormControl;
  controllerCreditCard: ControllerCreditCard;
  cardSelected: CardDb | undefined;
  validatingCreditCards: CardClient[];
  cardValidationResponses: CardValidationResponse[];
  enableSendOrder: boolean;
}
interface DispatchProps {
  toCurrency: (value: number) => string;
  onChangeTransferDocument: (
    eventId: string,
    sectionId: string,
    ticketId: string,
    index: number,
    value: string,
  ) => void;
  onCheckCpfTransfer: (
    eventId: string,
    sectionId: string,
    ticketId: string,
    index: number,
    value: string,
  ) => void;
  incrementTicket: (item: EventTicketSite) => void;
  decrementTicket: (item: EventTicketSite) => void;
  incrementProduct: (eventId: string, groupId: string, index: number) => void;
  decrementProduct: (eventId: string, groupId: string, index: number) => void;
  incrementCombo: (eventId: string, groupId: string, index: number) => void;
  decrementCombo: (eventId: string, groupId: string, index: number) => void;
  removeCupom: () => void;
  onSetPaymentType: (type: PaymentType) => void;
  onChangeCreditCard: (
    index: number,
    e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>,
  ) => void;
  onCheckCpf: (cpf: string) => void;
  onSendOrder: () => void;
  onGoToTickets: () => void;
  onCopyText: (text: string) => void;
  onChangeCEP: (value: string) => void;
  onSubmitAddress: () => void;
  onSubmitAddCard: () => void;
  onCardSelected: (id: string, index: number) => void;
  setCardSelectedInt: (indexList: number, index: number) => void;
  onValidateCreditCard: () => void;
  onChangeInputValidate: (index: number, value: string) => void;
  checkoutValidateCreditCard: (index: number) => void;
}

type CheckoutContainerProps = StateProps & DispatchProps;

export const CheckoutContainer: React.FC<CheckoutContainerProps> = ({
  cart,
  loadedUserData,
  isWithin24Hours,
  cameFromAdm,
  controllerPersonalinfo,
  onChangeTransferDocument,
  onCheckCpfTransfer,
  incrementTicket,
  decrementTicket,
  incrementProduct,
  decrementProduct,
  incrementCombo,
  decrementCombo,
  removeCupom,
  paymentType,
  cards,
  payment,
  controllerModalPayment,
  qrCodeImage,
  qrCodeText,
  boleto,
  barcode,
  messagePayment,
  messageError,
  toCurrency,
  onSetPaymentType,
  onChangeCreditCard,
  onCheckCpf,
  onSendOrder,
  onGoToTickets,
  onCopyText,
  controllerAddress,
  onChangeCEP,
  onSubmitAddress,
  onSubmitAddCard,
  controllerCreditCard,
  cardSelected,
  onCardSelected,
  setCardSelectedInt,
  onValidateCreditCard,
  validatingCreditCards,
  enableSendOrder,
  cardValidationResponses,
  onChangeInputValidate,
  checkoutValidateCreditCard,
}) => {
  const [paymentTabAndContent, setPaymentTabAndContent] = useState<PaymentTabAndContent>({
    tabs: [],
    contents: [],
  });
  const [openPersonalData, setOpenPersonalData] = useState<boolean>(false);
  const [openAddress, setOpenAddress] = useState<boolean>(false);

  const toDate = (date: Date | undefined): Date | undefined => {
    if (date) {
      const newDate = new Date(date);
      newDate.setHours(newDate.getHours() + 3);
      return newDate;
    }
    return date;
  };

  const mountTotalValue = (): string => {
    let value = cart.totalValueWithFee;
    if (cart.discountCoupon && cart.discountCoupon.discountCoupon) {
      value -= cart.discountCoupon.discountCoupon.discount;
    } else if (cart.discountCoupon && cart.discountCoupon.voucher) {
      value -= cart.discountCoupon.voucher.value;
    }

    return toCurrency(value);
  };

  const showPersonalData = (): void => {
    let show = false;
    if (
      !controllerPersonalinfo.formData[FormInputNameCheckout.document] ||
      controllerPersonalinfo.formData[FormInputNameCheckout.document].trim().length < 14
    ) {
      show = true;
    }
    if (
      !controllerPersonalinfo.formData[FormInputNameCheckout.name] ||
      controllerPersonalinfo.formData[FormInputNameCheckout.name].trim().length < 10
    ) {
      show = true;
    }
    if (
      !controllerPersonalinfo.formData[FormInputNameCheckout.phone] ||
      controllerPersonalinfo.formData[FormInputNameCheckout.phone].trim().length < 15
    ) {
      show = true;
    }
    if (
      !controllerPersonalinfo.formData[FormInputNameCheckout.birthDate] ||
      controllerPersonalinfo.formData[FormInputNameCheckout.birthDate].trim().length < 10
    ) {
      show = true;
    }
    if (
      !controllerPersonalinfo.formData[FormInputNameCheckout.motherName] ||
      controllerPersonalinfo.formData[FormInputNameCheckout.motherName].trim().split(' ').length < 2
    ) {
      show = true;
    }
    setOpenPersonalData(show);
  };

  const showAddress = (): void => {
    let show = false;
    if (
      !controllerAddress.formData[FormInputNameAddress.zipCode] ||
      controllerAddress.formData[FormInputNameAddress.zipCode].trim().length < 9
    ) {
      show = true;
    }
    setOpenAddress(show);
  };

  const getPaymentFormNumber = (): string => {
    let s = '4';

    switch (true) {
      case openPersonalData && openAddress && isWithin24Hours:
        s = '5';
        break;
      case !openPersonalData && openAddress && isWithin24Hours:
        s = '4';
        break;
      case openPersonalData && !openAddress && isWithin24Hours:
        s = '4';
        break;
      case openPersonalData && openAddress && !isWithin24Hours:
        s = '4';
        break;
      case openPersonalData && !openAddress && !isWithin24Hours:
        s = '3';
        break;
      case !openPersonalData && openAddress && !isWithin24Hours:
        s = '3';
        break;
      case !openPersonalData && !openAddress && isWithin24Hours:
        s = '3';
        break;
      case !openPersonalData && !openAddress && !isWithin24Hours:
        s = '2';
        break;
      default:
        break;
    }

    return s;
  };

  const getTransferFormNumber = (): string => {
    let s = '4';
    if (openPersonalData && openAddress) {
      s = '4';
    } else if (openPersonalData && !openAddress) {
      s = '3';
    } else if (!openPersonalData && openAddress) {
      s = '3';
    } else if (!openPersonalData && !openAddress) {
      s = '2';
    }
    return s;
  };

  const mountPayment = (): void => {
    const tabsAndContents: PaymentTabAndContent = {
      tabs: [],
      contents: [],
    };
    if (payment && payment.fees) {
      if (payment.fees.allowCreditCardPayment) {
        tabsAndContents.tabs.push({
          text: 'Cartão de Crédito',
          icon: (
            <IconCreditCard
              className={classNames(
                paymentType === PaymentType.CREDIT_CARD ? 'text-white' : 'text-neutro-w-600',
                'mb-[16px] h-[15px]',
              )}
            />
          ),
          type: PaymentType.CREDIT_CARD,
        });
        tabsAndContents.contents.push({
          content: (
            <div key={1} className="mt-[50px]">
              <div className="flex">
                <IconCreditCard
                  className={classNames(
                    paymentType === PaymentType.CREDIT_CARD ? 'text-black' : 'text-neutro-w-600',
                    'mt-[10px] h-[20px]',
                  )}
                />
                <h1 className="ml-[10px] font-dmsans font-normal text-neutro-b-400 text-[28px] mb-[10px ]">
                  <span>Selecione o cartão</span>
                </h1>
              </div>

              {cards.length > 0 && cards.length === 1 && (
                <CreditCardPaymentRequestContent
                  key={`one-card-${PaymentType.CREDIT_CARD.toString()}`}
                  id={PaymentType.CREDIT_CARD.toString()}
                  index={0}
                  card={cards[0]}
                  payment={payment}
                  totalValue={cart.originalValueWithPaymentFee}
                  showValue={false}
                  onChange={onChangeCreditCard}
                  toCurrency={toCurrency}
                  controllerCreditCard={controllerCreditCard}
                  cardSelected={cardSelected}
                  onCardSelected={onCardSelected}
                  cardSelectedInt={cards[0].cardSelectedInt}
                  setCardSelectedInt={setCardSelectedInt}
                  validateCreditCard={checkoutValidateCreditCard}
                />
              )}
              <Button
                type="button"
                title="+ Adicionar novo cartão"
                buttonStyle="dashed"
                size="xlg"
                className="mt-[40px] w-full lg:w-[411px]"
                onClick={controllerCreditCard.onShowAddCreditCard}
              />
            </div>
          ),
          type: PaymentType.CREDIT_CARD,
        });
      }

      if (payment.allowFractionalPayment) {
        tabsAndContents.tabs.push({
          text: 'Dois cartões',
          icon: (
            <div style={{ display: 'inherit' }}>
              <IconCreditCard
                style={{ marginRight: '3px' }}
                className={classNames(
                  paymentType === PaymentType.TWO_CREDIT_CARDS ? 'text-white' : 'text-neutro-w-600',
                  'mb-[16px] h-[15px]',
                )}
              />
              <IconCreditCard
                style={{ marginLeft: '3px' }}
                className={classNames(
                  paymentType === PaymentType.TWO_CREDIT_CARDS ? 'text-white' : 'text-neutro-w-600',
                  'mb-[16px] h-[15px]',
                )}
              />
            </div>
          ),
          type: PaymentType.TWO_CREDIT_CARDS,
        });

        tabsAndContents.contents.push({
          content: (
            <>
              <div key={2} className="md:w-[411px] mt-[50px]">
                {cards.length > 1 &&
                  cards.map((data, index) => (
                    <div className="mb-[40px]" key={`two-cards-${index}`}>
                      <label className="block mb-[30px] text-[21px] font-dmsans font-normal text-neutro-b-400 text-base">
                        {index === 0 ? 'Primeiro' : 'Segundo'} cartão
                      </label>
                      <CreditCardPaymentRequestContent
                        id={PaymentType.TWO_CREDIT_CARDS.toString()}
                        index={index}
                        card={data}
                        payment={payment}
                        totalValue={data.valueWithFee ? data.valueWithFee : cart.totalValueWithFee}
                        showValue={true}
                        onChange={onChangeCreditCard}
                        toCurrency={toCurrency}
                        controllerCreditCard={controllerCreditCard}
                        cardSelected={cardSelected}
                        onCardSelected={onCardSelected}
                        cardSelectedInt={data.cardSelectedInt}
                        setCardSelectedInt={setCardSelectedInt}
                        validateCreditCard={checkoutValidateCreditCard}
                      />
                    </div>
                  ))}
              </div>
              <Button
                type="button"
                title="+ Adicionar novo cartão"
                buttonStyle="dashed"
                size="xlg"
                className="mt-[40px] w-full lg:w-[411px]"
                onClick={controllerCreditCard.onShowAddCreditCard}
              />
            </>
          ),
          type: PaymentType.TWO_CREDIT_CARDS,
        });
      }

      if (payment.allowPaymentBankSlip) {
        tabsAndContents.tabs.push({
          text: 'Boleto',
          icon: (
            <IconTicketPayment
              className={classNames(
                paymentType === PaymentType.BANK_SLIP ? 'text-white' : 'text-neutro-w-600',
                'mb-[16px] h-[22px]',
              )}
            />
          ),
          type: PaymentType.BANK_SLIP,
        });

        tabsAndContents.contents.push({
          content: (
            <div key={3} className="md:w-[470px] mt-[50px]">
              <IconTicketPayment className="text-neutro-b-400 h-[36px] mb-[25px]" />
              <h4 className="font-dmsans font-bold text-neutro-b-400 text-base mb-[25px]">
                O ingresso já é quase seu!
              </h4>
              <p className="font-dmsans text-base font-normal text-neutro-w-600 mb-[40px]">
                Ao clicar em <strong>“Finalizar compra”</strong>, o boleto aparecerá na tela e você
                terá 3 dias para efetuar o pagamento.
              </p>
              <div className="flex justify-start items-center">
                <div className="w-[26px] h-[5px] bg-neutro-w-600 mr-2"></div>
                <IconSecurity className="text-neutro-w-600 h-[15px]" />
              </div>
              <p className="font-dmsans text-base font-normal text-neutro-w-600">
                Pagamento 110% Seguro
              </p>
            </div>
          ),
          type: PaymentType.BANK_SLIP,
        });
      }

      if (payment.allowPaymentPIX) {
        tabsAndContents.tabs.push({
          text: 'PIX',
          icon: (
            <IconPix
              className={classNames(
                paymentType === PaymentType.PIX ? 'text-white' : 'text-neutro-w-600',
                'mb-[16px] h-[20px]',
              )}
            />
          ),
          type: PaymentType.PIX,
        });

        tabsAndContents.contents.push({
          content: (
            <div key={4} className="md:w-[411px] mt-[50px]">
              <IconPix className="text-neutro-b-400 h-[36px] mb-[25px]" />
              <h4 className="font-dmsans font-bold text-neutro-b-400 text-base mb-[25px]">
                O ingresso já é quase seu!
              </h4>
              <p className="font-dmsans text-base font-normal text-neutro-w-600 mb-[40px]">
                Ao clicar em <strong>“Finalizar compra”</strong>, o código PIX aparecerá na tela
                para você poder fazer o pagamento instantâneo.
              </p>
              <div className="flex justify-start items-center">
                <div className="w-[26px] h-[5px] bg-neutro-w-600 mr-2"></div>
                <IconSecurity className="text-neutro-w-600 h-[15px]" />
              </div>
              <p className="font-dmsans text-base font-normal text-neutro-w-600">
                Pagamento 110% Seguro
              </p>
            </div>
          ),
          type: PaymentType.PIX,
        });
      }
      setPaymentTabAndContent(tabsAndContents);
    }
  };

  useEffect(() => {
    if (payment) {
      mountPayment();
    }
  }, [payment]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (cards && cards.length > 0) {
        mountPayment();
      }
    }, 1000); // Atraso de 1 segundo (você pode ajustar o tempo conforme necessário)

    return () => clearTimeout(timer); // Limpa o timeout se o componente for desmontado antes do tempo definido
  }, [cards]);

  useEffect(() => {
    if (loadedUserData) {
      showPersonalData();
      showAddress();
    }
  }, [loadedUserData]);

  useEffect(() => {
    setTimeout(() => {
      const element = window.document.getElementById('selectPrincipal') as HTMLElement;
      element.scrollIntoView();
    }, 200);
  }, []);

  return (
    <AuthWrapper isHomepage={false}>
      <div
        className="w-full container mx-auto"
        style={{ paddingTop: '20px', paddingBottom: '6rem' }}
      >
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
          <section
            className="mb-[20px] sm:p-[15px] p-[0]"
            id="selectPrincipal"
            // style={{ borderRight: '1px solid rgba(128, 128, 128, 0.1)' }}
          >
            <h2 className="font-dmsans font-normal text-neutro-b-400 text-[28px] ">
              <span className="font-bold">1.</span> Confira seu pedido
            </h2>
            <p className="font-dmsans text-base font-normal text-neutro-w-600 leading-[28px]">
              Confirme se o seu pedido esta correto
            </p>
            <div className="md:w-[481px]">
              {cart.events.map(event => (
                <div
                  key={event.id}
                  style={{ paddingTop: '10px' }}
                  className=" cart-item w-full border-b border-neutro-w-200"
                >
                  <header className="grid grid-cols-[115px_1fr] items-center gap-5">
                    <div className="relative">
                      <img
                        className="w-[115px] rounded-md"
                        src={event.imageDetailUrl}
                        alt="Event"
                      />
                      <div className="absolute rounded-md top-0 w-full h-full bg-card-default hover:bg-card-hover transition-all duration-200 ease-in-out"></div>
                    </div>
                    <div className="text-left">
                      <h4 className="text-neutral-b-400 font-dmsans font-medium text-[18px] sm:text-[21px]">
                        {event.name}
                      </h4>
                      <div className="flex items-center text-base ">
                        <span className="font-dmsans font-normal text-neutro-w-600 uppercase text-[12px] sm:text-[18px]">
                          {dayjs(toDate(event.startDate)).format('DD MMM')}{' '}
                        </span>
                        <span className="w-[6px] h-[6px] mx-2 bg-gray-300 rounded-full"></span>
                        <span className="font-dmsans font-normal text-neutro-w-600 uppercase text-[12px] sm:text-[18px]">
                          {dayjs(toDate(event.startDate)).format('HH:mm')}
                        </span>
                        <span className="w-[6px] h-[6px] mx-2 bg-gray-300 rounded-full text-[12px] sm:text-[18px]"></span>
                        <span className="font-dmsans font-normal text-neutro-w-600">
                          {event.address.city}/{event.address.state}
                        </span>
                      </div>
                    </div>
                  </header>
                  <div>
                    <>
                      {event.sections &&
                        event.sections.length > 0 &&
                        event.sections.map(section => (
                          <div key={`empty-tag-${section.section.id}`}>
                            {section.tickets &&
                              section.tickets.length > 0 &&
                              section.tickets.map(ticket => (
                                <TicketIncrementResume
                                  key={ticket.id + ticket.isHalfPrice}
                                  item={ticket}
                                  cameFromAdm={cameFromAdm}
                                  increment={incrementTicket}
                                  decrement={decrementTicket}
                                />
                              ))}
                          </div>
                        ))}

                      {event.groups &&
                        event.groups.length > 0 &&
                        event.groups.map(group => (
                          <>
                            {group.name}
                            {group.products &&
                              group.products.length > 0 &&
                              group.products.map((product, index) => (
                                <ProductIncrementResume
                                  key={product.id}
                                  index={index}
                                  eventId={event.id}
                                  groupId={group.id}
                                  name={product.name}
                                  count={product.amount}
                                  quantity={product.quantity}
                                  unitValue={product.unitValue}
                                  fee={product.fee}
                                  increment={incrementProduct}
                                  decrement={decrementProduct}
                                />
                              ))}
                            {group.combos &&
                              group.combos.length > 0 &&
                              group.combos.map((combo, index) => (
                                <ProductIncrementResume
                                  key={combo.id}
                                  index={index}
                                  eventId={event.id}
                                  groupId={group.id}
                                  name={combo.name}
                                  count={combo.amount}
                                  quantity={combo.quantity}
                                  unitValue={combo.unitValue}
                                  fee={combo.fee}
                                  increment={incrementCombo}
                                  decrement={decrementCombo}
                                />
                              ))}
                          </>
                        ))}
                    </>
                  </div>
                </div>
              ))}
            </div>
            <div className="md:w-[481px] text-left">
              <div className="mb-[20px]">
                {cart.discountCoupon && cart.discountCoupon.discountCoupon && (
                  <div className="flex justify-between items-center mt-5">
                    <div className="flex justify-start items-center">
                      <img src={Cupom} alt={cart.discountCoupon.discountCoupon.name} />
                      <span className="uppercase ml-3 mr-12">
                        {cart.discountCoupon.discountCoupon.code}
                      </span>
                    </div>
                    <a onClick={() => removeCupom()} className="text-neutro-b-400 cursor-pointer">
                      <img src={Close} alt="fechar" className="fill-neutro-b-400" />
                    </a>
                  </div>
                )}
                {cart.discountCoupon && cart.discountCoupon.voucher && (
                  <div className="flex justify-between items-center mt-5">
                    <div className="flex justify-start items-center">
                      <img src={Cupom} alt={cart.discountCoupon.voucher.code} />
                      <span className="uppercase ml-3 mr-12">
                        {cart.discountCoupon.voucher.code}
                      </span>
                    </div>
                    <a onClick={() => removeCupom()} className="text-neutro-b-400 cursor-pointer">
                      <img src={Close} alt="fechar" className="fill-neutro-b-400" />
                    </a>
                  </div>
                )}
              </div>
              <div className="flex justify-between items-center mb-1">
                <div className="text-heading font-dmsans font-normal">
                  Total de itens: <span className="font-bold">{cart.amountItens}</span>
                </div>
              </div>
              <div className="flex justify-between items-center mb-1">
                <div className="text-heading font-dmsans font-normal">
                  Sub total: <span className="font-bold">{toCurrency(cart.totalValue)}</span>
                </div>
              </div>
              {cart.discountCoupon && cart.discountCoupon.discountCoupon && (
                <div className="flex justify-between items-center mb-1">
                  <div className="text-heading font-dmsans font-normal">
                    Desconto:{' '}
                    <span className="font-bold">
                      - {toCurrency(cart.discountCoupon.discountCoupon.discount)}
                    </span>
                  </div>
                </div>
              )}
              {cart.discountCoupon && cart.discountCoupon.voucher && (
                <div className="flex justify-between items-center mb-1">
                  <div className="text-heading font-dmsans font-normal">
                    Desconto:{' '}
                    <span className="font-bold">
                      - {toCurrency(cart.discountCoupon.voucher.value)}
                    </span>
                  </div>
                </div>
              )}
              <div className="flex justify-between items-center mb-1">
                <div className="text-heading font-dmsans font-normal">
                  Taxa:{' '}
                  <span className="font-bold">
                    {toCurrency(cart.totalValueWithFee - cart.totalValue)}
                  </span>
                </div>
              </div>
              <hr className="my-5" />
              <div className="flex justify-between items-center mb-1">
                <div className="text-heading font-dmsans font-normal">
                  Valor total: <span className="font-bold">{mountTotalValue()}</span>
                </div>
              </div>
            </div>
          </section>
          {openPersonalData && (
            <section className="mb-[20px]">
              <h2 className="font-dmsans font-normal text-neutro-b-400 text-[28px]">
                <span className="font-bold">2.</span> Informações pessoais
              </h2>
              <p className="font-dmsans text-base font-normal text-neutro-w-600 leading-[28px]">
                Confirme se os seus dados estão corretos
              </p>
              <div className="md:w-[500px] mt-[20px]">
                <form onSubmit={controllerPersonalinfo.onSubmit}>
                  <div className="mb-[20px]" id="divCPF">
                    <InputText
                      name="document"
                      label="CPF"
                      placeholder="123.456.789-00"
                      className={`appearance-none w-full
                rounded-md text-heading leading-tight focus:outline-none
                focus:shadow-outline text-xs font-dmsans font-normal`}
                      maxLength={14}
                      value={controllerPersonalinfo.formData[FormInputNameCheckout.document]}
                      onBlur={e => onCheckCpf(e.target.value)}
                      onChange={e =>
                        controllerPersonalinfo.onChangeFormInput(FormInputNameCheckout.document)(
                          e.target.value,
                        )
                      }
                      error={
                        controllerPersonalinfo.formErrors.document &&
                        controllerPersonalinfo.formErrors.document[0]
                      }
                    />
                  </div>
                  <div className="mb-[20px]">
                    <InputText
                      name="name"
                      label="Nome completo"
                      placeholder="Maria da Silva"
                      className={`appearance-none w-full
                rounded-md text-heading leading-tight focus:outline-none
                focus:shadow-outline text-xs font-dmsans font-normal`}
                      value={controllerPersonalinfo.formData[FormInputNameCheckout.name]}
                      onChange={e =>
                        controllerPersonalinfo.onChangeFormInput(FormInputNameCheckout.name)(
                          e.target.value,
                        )
                      }
                      error={
                        controllerPersonalinfo.formErrors.name &&
                        controllerPersonalinfo.formErrors.name[0]
                      }
                    />
                  </div>

                  <div className="mb-[20px]">
                    <InputText
                      name="email"
                      label="E-mail"
                      placeholder="meuemail@email.com"
                      className={`appearance-none w-full
                rounded-md text-heading leading-tight focus:outline-none
                focus:shadow-outline text-xs font-dmsans font-normal`}
                      value={controllerPersonalinfo.formData[FormInputNameCheckout.email]}
                      onChange={e =>
                        controllerPersonalinfo.onChangeFormInput(FormInputNameCheckout.email)(
                          e.target.value,
                        )
                      }
                      error={
                        controllerPersonalinfo.formErrors.email &&
                        controllerPersonalinfo.formErrors.email[0]
                      }
                    />
                  </div>
                  <div className="mb-[20px]">
                    <InputText
                      name="phone"
                      label="Telefone"
                      placeholder="(00) 0 0000-0000"
                      className={`appearance-none w-full
                rounded-md text-heading leading-tight focus:outline-none
                focus:shadow-outline text-xs font-dmsans font-normal`}
                      value={controllerPersonalinfo.formData[FormInputNameCheckout.phone]}
                      onChange={e =>
                        controllerPersonalinfo.onChangeFormInput(FormInputNameCheckout.phone)(
                          e.target.value,
                        )
                      }
                      error={
                        controllerPersonalinfo.formErrors.phone &&
                        controllerPersonalinfo.formErrors.phone[0]
                      }
                    />
                  </div>
                  <div className="mb-[20px]">
                    <InputText
                      name="birthDate"
                      label="Data de nascimento"
                      placeholder="DD/MM/AAAA"
                      className={`appearance-none w-full
                rounded-md text-heading leading-tight focus:outline-none
                focus:shadow-outline text-xs font-dmsans font-normal`}
                      value={controllerPersonalinfo.formData[FormInputNameCheckout.birthDate]}
                      onChange={e =>
                        controllerPersonalinfo.onChangeFormInput(FormInputNameCheckout.birthDate)(
                          e.target.value,
                        )
                      }
                      error={
                        controllerPersonalinfo.formErrors.birthDate &&
                        controllerPersonalinfo.formErrors.birthDate[0]
                      }
                    />
                  </div>

                  <div className="mb-[20px]">
                    <InputText
                      name="motherName"
                      label="Nome da mãe"
                      placeholder="Ex: Maria da Silva"
                      className={`appearance-none w-full
                rounded-md text-heading leading-tight focus:outline-none
                focus:shadow-outline text-xs font-dmsans font-normal`}
                      value={controllerPersonalinfo.formData[FormInputNameCheckout.motherName]}
                      onChange={e =>
                        controllerPersonalinfo.onChangeFormInput(FormInputNameCheckout.motherName)(
                          e.target.value,
                        )
                      }
                      error={
                        controllerPersonalinfo.formErrors.motherName &&
                        controllerPersonalinfo.formErrors.motherName[0]
                      }
                    />
                  </div>
                </form>
              </div>
            </section>
          )}
          {openAddress && (
            <section className="mb-[20px]">
              <h2 className="font-dmsans font-normal text-neutro-b-400 text-[28px]">
                <span className="font-bold">{openPersonalData ? '3' : '2'}.</span> Endereço
              </h2>
              <p className="font-dmsans text-base font-normal text-neutro-w-600 leading-[28px]">
                Precisamos do seu endereço apenas para confirmação de pagamento
              </p>
              <div className="md:w-[500px] mt-[20px]" id="divEndereco">
                <AddressContent
                  controllerAddress={controllerAddress}
                  onChangeCEP={onChangeCEP}
                  onSubmitAddress={onSubmitAddress}
                />
              </div>
            </section>
          )}
          {isWithin24Hours &&
            cart.events.map(
              data =>
                data.sections &&
                data.sections.length > 0 &&
                data.sections.map(
                  section =>
                    section.tickets &&
                    section.tickets.length > 0 &&
                    section.tickets.map(
                      ticket =>
                        ticket.userDatas &&
                        ticket.userDatas.length > 0 && (
                          <div key={ticket.id}>
                            <h2 className="font-dmsans font-normal text-neutro-b-400 text-[28px]">
                              <span className="font-bold">{getTransferFormNumber()}.</span>
                              Informe os utilziadores
                            </h2>
                            <div className="lg:w-[567px] py-[20px]">
                              <p className="text-base text-neutro-b-500 mb-[20px]">
                                Digite os dados do novo(a) utilizador(a)
                              </p>
                              <InputTicketsTransfer
                                eventId={data.id}
                                eventName={data.name}
                                sectionId={section.section.id}
                                item={ticket}
                                // controllerTransferTicket={
                                //   controllerTransferTicket
                                // }
                                onChange={onChangeTransferDocument}
                                onCheck={onCheckCpfTransfer}
                              />
                            </div>
                          </div>
                        ),
                    ),
                ),
            )}

          <section className="mb-[20px] ml-[10px] md:shadow-xs sm:p-[15px] p-[0]">
            <h2 className="font-dmsans font-normal text-neutro-b-400 text-[28px]">
              <span className="font-bold">{getPaymentFormNumber()}.</span>
              Escolha a forma de pagamento
            </h2>
            <p className="font-dmsans text-base font-normal text-neutro-w-600 leading-[28px]">
              Selecione abaixo a sua forma de pagamento
            </p>
            <div className="mt-[20px]">
              <TabTypePayments
                typePayment={paymentType}
                onSetTypePayment={onSetPaymentType}
                tabs={paymentTabAndContent.tabs}
                contents={paymentTabAndContent.contents}
              />
              <div className="md:w-[500px] mt-[70px]">
                <Button
                  type="submit"
                  title={`Finalizar compra de ${toCurrency(cart.totalValueWithPaymentFee)}`}
                  buttonStyle="primary"
                  size="xlg"
                  className="w-full mb-[25px]"
                  onClick={onSendOrder}
                  disabled={!enableSendOrder}
                />
              </div>
            </div>
          </section>
        </div>
        <Modal
          visible={controllerModalPayment.visible}
          title={controllerModalPayment.title}
          onSetVisible={controllerModalPayment.onToggleModal}
          actions={[]}
          className="md:w-[750px]"
          showCloseButton={
            controllerModalPayment.shouldShowModal !== ShowModalPayment.VALIDATING_CREDIT_CARD
          }
        >
          {
            {
              [ShowModalPayment.CREDIT_CARD_PAYMENT]: (
                <CreditCardPaymentContent onGoToTickets={onGoToTickets} />
              ),
              [ShowModalPayment.TICKET_PAYMENT]: (
                <TicketPaymentContent boleto={boleto} barcode={barcode} onCopyText={onCopyText} />
              ),
              [ShowModalPayment.PIX_PAYMENT]: (
                <PixPaymentContent
                  qrCodeImage={qrCodeImage}
                  qrCodeText={qrCodeText}
                  onCopyText={onCopyText}
                />
              ),
              [ShowModalPayment.ADD_CREDIT_CARD_PAYMENT]: (
                <>
                  <CreditCardPaymentAddContent
                    controllerAddCreditCard={controllerCreditCard.controllerAddCreditCard}
                    onSubmit={onSubmitAddCard}
                  />
                </>
              ),
              [ShowModalPayment.CONFIRM_DELETE_CREDIT_CARD_PAYMENT]: (
                <ConfirmDeleteCreditCardContent
                  onCancelConfirm={controllerModalPayment.onToggleModal}
                  onOkConfirm={controllerCreditCard.onSubmitDeleteCreditCard}
                />
              ),
              [ShowModalPayment.VALIDATING_CREDIT_CARD]: (
                <ValidatingCreditCardContent validatingCreditCards={validatingCreditCards} />
              ),
              [ShowModalPayment.VALIDATE_CREDIT_CARD]: (
                <ValidateCreditCardContent
                  cardValidationResponses={cardValidationResponses}
                  onChangeInputValidate={onChangeInputValidate}
                  onConfirm={onValidateCreditCard}
                  onCancel={controllerModalPayment.onToggleModal}
                />
              ),
              [ShowModalPayment.VALIDATED]: (
                <ValidatedCreditCardContent
                  cardValidationResponses={cardValidationResponses}
                  onContinue={onSendOrder}
                />
              ),
              [ShowModalPayment.VALIDATED_ERROR]: (
                <ValidatedErrorCreditCardContent
                  cardValidationResponses={cardValidationResponses}
                  onCancel={controllerModalPayment.onToggleModal}
                />
              ),
              [ShowModalPayment.PAYMENT_ERROR]: (
                <PaymentErroContent
                  messages={messageError}
                  onOkConfirm={() => {
                    controllerModalPayment.onToggleModal();
                    onSendOrder();
                  }}
                />
              ),
              [ShowModalPayment.PAYMENT_NOTMADE]: (
                <PaymentNotMadeContent
                  messagePayment={messagePayment}
                  onOkConfirm={controllerModalPayment.onToggleModal}
                />
              ),
            }[controllerModalPayment.shouldShowModal]
          }
        </Modal>
      </div>
    </AuthWrapper>
  );
};
