import React, { SelectHTMLAttributes } from 'react';

export interface OptionProps {
  value?: string | number;
  label: string;
  target?: HTMLInputElement;
}

interface SelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  name?: string;
  label?: string;
  error?: string | null | undefined;
  className?: string;
  placeholder?: string;
  maxLength?: number;
  onBlur?: (e: React.FocusEvent<HTMLSelectElement>) => void;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  options: OptionProps[];
}

export const Select: React.FC<SelectProps> = ({
  name,
  value,
  placeholder,
  error,
  label,
  className,
  onBlur,
  onChange,
  options,
}) => (
  <div className={`relative ${className}`}>
    {label && (
      <label htmlFor={name} className="block text-label text-base font-dmsans font-regular mb-2">
        {label}
      </label>
    )}
    <select
      name={name}
      aria-invalid={error ? 'true' : 'false'}
      // placeholder={placeholder ?? 'Digite aqui!'}
      onBlur={onBlur}
      value={value}
      onChange={onChange}
      className={`relative h-[60px] border rounded w-full py-2 px-3 text-input text-base leading-tight focus:outline-none focus:shadow-outline ${
        error ? 'border-red-500' : ''
      }`}
      style={{
        color: '#C4C4C4',
      }}
    >
      <option value="">{placeholder || 'Selecione...'}</option>
      {options.map(item => (
        <option key={item.value} value={item.value}>
          {item.label}
        </option>
      ))}
    </select>
    {error && <p className="text-red-500 text-xs">{error}</p>}
  </div>
);
