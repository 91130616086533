import { Link } from 'react-router-dom';

import Flickity from 'react-flickity-component';
import EventSite from '@/model/EventSite';
import React from 'react';
import { SliderEventItem } from './SliderEventItem';

const flickityOptions = {
  freeScroll: true,
  groupCells: 1,
  contain: true,
  cellAlign: 'left',
};

interface SliderEventProps {
  title: string;
  total: number;
  events: EventSite[];
  routePath: string;
  onSelectEvent(event: EventSite): void;
}

export const SliderEvent: React.FC<SliderEventProps> = ({
  title,
  total,
  events,
  routePath,
  onSelectEvent,
}) => (
  <div className="w-full relative">
    <div className="container mx-auto">
      <div className="flex justify-between items-center">
        <h3 className="text-heading text-[1.75rem] font-dmsans font-medium truncate text-ellipsis overflow-hidden">
          {title}
        </h3>
        <Link className="text-link font-dmsans font-normal whitespace-nowrap" to={routePath}>
          Ver todos
        </Link>
      </div>
      <div className="text-paragraph font-dmsans font-normal mt-2">
        {total} {total > 1 ? 'resultados' : 'resultado'}
      </div>
    </div>
    <div className="w-full">
      <Flickity options={flickityOptions} className={'container mx-auto event-slider mt-8'}>
        {events.map(event => (
          <div key={event.id} className="carousel-cell">
            <SliderEventItem event={event} onSelectEvent={onSelectEvent} />
          </div>
        ))}
      </Flickity>
    </div>
  </div>
);
