import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import EventCategorySite from '@/model/EventCategorySite';

type EventState = {
  events: EventCategorySite[];
};

const INITIAL_STATE: EventState = {
  events: [],
};

const eventSlice = createSlice({
  name: 'event',
  initialState: INITIAL_STATE,
  reducers: {
    setEventStore(state, { payload }: PayloadAction<EventCategorySite[]>) {
      // eslint-disable-next-line no-param-reassign
      state.events = payload;
    },
  },
});

export const { setEventStore } = eventSlice.actions;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useEventStore = (state: any): EventState => state.events as EventState;

export default eventSlice.reducer;
