import React, { FormEvent, useEffect } from 'react';
import {
  /* GoogleLogin, */ GoogleLoginResponse,
  GoogleLoginResponseOffline,
} from 'react-google-login';
import {
  /* FacebookLogin, */ ReactFacebookFailureResponse,
  ReactFacebookLoginInfo,
} from 'react-facebook-login';
import {
  // Google,
  IconEye,
  IconEyeClose /* , IconFacebook */,
} from '@/assets/icons';
import { Button, InputText } from '@/components';
import { UseFormReturn } from 'src/hooks';
import { gapi } from 'gapi-script';
import { classNames } from '@/helpers/common';
import logoFull from '../../../../assets/images/logo-full.png';
import { ShouldShowModalProps, ShouldShowModal, FormInputNameLogin } from '../types';

interface StateProps extends Pick<UseFormReturn, 'formData' | 'formErrors' | 'onChangeFormInput'> {
  state: boolean;
  enableLoginPassword: boolean;
  shouldShowPasswordToText: boolean;
  shouldShowPasswordToText2: boolean;
}

interface DispathProps {
  onTogglePasswordToText: () => void;
  onCheckCPF: () => void;
  onSubmit: (e: FormEvent) => void;
  onShouldShowModal: ({ value, newTitle }: ShouldShowModalProps) => void;
  onGoToGoogle: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onSubmitGoogle: (response: GoogleLoginResponse | GoogleLoginResponseOffline) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onGoogleError: (error: any) => void;
  onGoToFacebook: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onSubmitFacebook: (userInfo: ReactFacebookLoginInfo | ReactFacebookFailureResponse) => void;
  onGoToAppleId: () => void;
}

type LoginContentProps = StateProps & DispathProps;

export const LoginContent: React.FC<LoginContentProps> = ({
  state,
  shouldShowPasswordToText,
  formData,
  formErrors,
  enableLoginPassword,
  onChangeFormInput,
  onCheckCPF,
  onSubmit,
  onTogglePasswordToText,
  onShouldShowModal,
  // onGoToGoogle,
  // onSubmitGoogle,
  // onGoogleError,
  // onGoToFacebook,
  // onSubmitFacebook,
}) => {
  useEffect(() => {
    gapi.load('client:auth2', () => {
      gapi.client.init({
        clientId: '784726485697-uutejg2oolluqsfdm73n1nm6acdr0ntg.apps.googleusercontent.com',
        plugin_name: 'chat',
      });
    });
  }, []);

  return (
    <div>
      <div className="flex flex-col justify-center items-center">
        <img className="w-auto h-[80px]" src={logoFull} alt="Banca do Ingresso" />
      </div>

      <div className="mt-8 w-full">
        <form onSubmit={onSubmit}>
          <div className="mb-[20px]">
            <InputText
              name="document"
              label="CPF"
              placeholder="123.456.789-00"
              className={`appearance-none w-full
                rounded-md text-heading leading-tight focus:outline-none
                focus:shadow-outline text-xs font-dmsans font-normal`}
              maxLength={14}
              value={formData[FormInputNameLogin.document]}
              onChange={e => onChangeFormInput(FormInputNameLogin.document)(e.target.value)}
              onBlur={() => onCheckCPF()}
              error={formErrors.document && formErrors.document[0]}
            />
          </div>

          <div className="mb-0">
            <InputText
              id="loginPWD"
              name="password"
              label="Senha"
              disabled={!enableLoginPassword}
              type={shouldShowPasswordToText ? 'text' : 'password'}
              placeholder="**********"
              value={formData[FormInputNameLogin.password]}
              maxLength={15}
              onChange={e => onChangeFormInput(FormInputNameLogin.password)(e.target.value)}
              error={formErrors.password && formErrors.password[0]}
              renderForward={
                <button
                  className={classNames(
                    formErrors.password && formErrors.password[0]
                      ? 'right-0 bottom-7'
                      : 'right-0 bottom-3',
                    'absolute cursor-pointer mr-4',
                  )}
                  onClick={onTogglePasswordToText}
                  type="button"
                >
                  <div className="h-8 flex flex-col justify-center items-center">
                    {shouldShowPasswordToText ? (
                      <img src={IconEye} alt="Mostrar senha" />
                    ) : (
                      <img src={IconEyeClose} alt="Ocultar senha" />
                    )}
                  </div>
                </button>
              }
            />
          </div>
          <div className="flex flex-col items-start justify-between">
            <a
              className="mb-[20px] mt-4 underline underline-offset-2 inline-block
              align-baseline text-label text-base font-normal font-dmsans"
              href="#"
              onClick={() =>
                onShouldShowModal({
                  value: ShouldShowModal.FORGOT_PASSWORD,
                  newTitle: 'Esqueceu a sua senha?',
                })
              }
            >
              Esqueci minha senha?
            </a>
            <Button
              type="submit"
              title="Entrar"
              buttonStyle="primary"
              size="xlg"
              className="w-full"
              disabled={
                formData[FormInputNameLogin.document] === '' ||
                formData[FormInputNameLogin.password] === '' ||
                state
              }
            />
          </div>
          {/*
          <div className="flex my-8 items-center justify-between">
            <hr className="block w-full h-px bg-label" />
            <span className="mx-2 text-neutro-b-400 font-dmsans">ou</span>
            <hr className="block w-full h-px bg-label" />
          </div>
          <div className="flex flex-col items-center justify-between">
            <Button
              type="button"
              title="Entrar com o Google"
              buttonStyle="outlined"
              size="xlg"
              className="w-full mb-[20px]"
              disabled={state}
              iconLeft={<img src={Google} alt="logo do google" />}
              onClick={(e) => onGoToGoogle(e)}
            />
            <div id="googleLogin" style={{ display: 'none' }}>
              <GoogleLogin
                clientId="726656258444-662p1ueuqrs9k6j86dq9k772jla17am9.apps.googleusercontent.com"
                buttonText="LOGIN WITH GOOGLE"
                onSuccess={(response) => onSubmitGoogle(response)}
                onFailure={(error) => onGoogleError(error)}
              />
            </div>
            <Button
              type="button"
              title="Entrar com o Facebook"
              buttonStyle="outlined"
              size="xlg"
              className="w-full mb-[20px]"
              disabled={state}
              iconLeft={<img src={IconFacebook} alt="logo do facebook" />}
              onClick={onGoToFacebook}
            />
            <FacebookLogin
              appId="3401894420124137"
              fields="name,email,picture"
              callback={(userInfo) => onSubmitFacebook(userInfo)}
            />
            <Button
              type="button"
              title="Entrar com a Apple"
              buttonStyle="outlined"
              size="xlg"
              className="w-full mb-[30px]"
              disabled={state === States.loading}
              iconLeft={<img src={Apple} alt="logo da apple id" />}
              onClick={onGoToAppleId}
            /> 
          </div>
          */}
          <div className="flex items-baseline justify-center">
            <span className=" text-neutro-w-500 text-base font-normal font-dmsans">
              Ainda não tem uma conta?
            </span>
            <a
              className="mx-2 underline underline-offset-2 inline-block
              align-baseline text-neutro-w-600 text-base font-bold font-dmsans"
              href="#"
              onClick={() =>
                onShouldShowModal({
                  value: ShouldShowModal.REGISTER,
                  newTitle: 'Crie a sua conta',
                })
              }
            >
              Criar conta
            </a>
          </div>
        </form>
      </div>
    </div>
  );
};
