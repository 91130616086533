import { SeatsioSeatingChart, SelectableObject, TicketTypeJson } from '@seatsio/seatsio-react';
import React, { Fragment, JSX } from 'react';
import ChartPrice from '@/model/ChartPrice';
import ChartTicketType from '@/model/ChartTicketType';
import EventMap from '@/model/EventMap';
import EventMapSection from '@/model/EventMapSection';
import { SelectedObject } from '../types';

interface MapContentProps {
  eventMap?: EventMap;
  eventKey?: string;
  selectedObjects: SelectedObject[];
  onObjectSelected: (object: SelectableObject, selectedTicketType: TicketTypeJson) => void;
  onObjectDeselected: (object: SelectableObject, selectedTicketType: TicketTypeJson) => void;
}

export const MapContent: React.FC<MapContentProps> = ({
  eventMap,
  eventKey,
  selectedObjects,
  onObjectSelected,
  onObjectDeselected,
}): JSX.Element => {
  const mountPricing = (): ChartPrice[] => {
    const list: ChartPrice[] = [];
    if (eventMap && eventMap.sections && eventMap.sections.length > 0) {
      const sections = eventMap.sections as EventMapSection[];
      sections.forEach(data => {
        if (data.tickets && data.tickets.length > 0) {
          const dto: ChartPrice = {
            category: data.name,
            ticketTypes: [],
          };

          data.tickets.forEach(ticket => {
            let price = Number(ticket.ticket.unitValue);
            if (ticket.ticket.fee && Number(ticket.ticket.fee) > 0) {
              price = Number(ticket.ticket.unitValue) * Number(ticket.ticket.fee);
            }
            price = Number(price.toFixed(2));
            const ticketType: ChartTicketType = {
              category: data.name,
              ticketType: ticket.ticket.name,
              label: ticket.ticket.name,
              description: `${data.name} - ${ticket.ticket.name}`,
              price,
            };
            (dto.ticketTypes as ChartTicketType[]).push(ticketType);
          });
          list.push(dto);
        }
      });
    }

    return list;
  };

  const toCurrency = (value: number): string => {
    const formatter = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });
    return formatter.format(value);
  };

  return (
    <>
      {eventKey && eventMap && (
        <Fragment>
          <div
            id="chartMapKeyEvent"
            key="chartMapKeyEvent"
            className="flex flex-col justify-center text-center font-dmsans"
            style={{ marginTop: '50px', minHeight: '100%' }}
          >
            {
              <SeatsioSeatingChart
                key="chartMapKeyEventa"
                workspaceKey={eventMap.publicWorkspaceKey}
                event={eventKey}
                pricing={mountPricing()}
                selectedObjects={selectedObjects}
                // selectedObjects={['Mesa-2']}
                // selectedSeats={['Mesa-1']}
                // selectedObjects={['Mesa-1']}
                priceFormatter={(price: number) => toCurrency(price)}
                onObjectSelected={onObjectSelected}
                onObjectDeselected={onObjectDeselected}
                region="sa"
                language="pt"
              />
            }
          </div>
        </Fragment>
      )}
    </>
  );
};
