import React from 'react';

interface IconProps extends React.SVGProps<SVGSVGElement> {
  children?: React.ReactNode;
}

export const IconPix: React.FC<IconProps> = props => (
  <svg
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="xMaxYMax"
    {...props}
  >
    <path
      d="M7.06699 1.2125L1.2125 7.06699C-0.404166 8.68365 -0.404166 11.3048 1.2125 12.9215L7.06699 18.776C8.68373 20.3926 11.3048 20.3926 12.9215 18.776L18.7762 12.9215C20.3927 11.3047 20.3927 8.68347 18.7762 7.06699L12.9215 1.2125C11.3048 -0.404166 8.68367 -0.404166 7.06699 1.2125ZM11.4579 2.67613L17.3126 8.53062C18.1208 9.33892 18.1208 10.6496 17.3125 11.458L11.458 17.3125C10.6497 18.1208 9.33922 18.1208 8.53068 17.3125L2.67619 11.458C1.86783 10.6496 1.86783 9.33896 2.67619 8.53062L8.53068 2.67613C9.33904 1.86777 10.6495 1.86777 11.458 2.67613H11.4579Z"
      fill="currentColor"
    />
    <path
      d="M0.162638 10.0983C0.162638 13.576 4.88517 16.7963 7.53827 14.0275L8.11098 13.4055C8.97931 12.4615 9.41009 12.1668 9.99202 12.1682C10.5059 12.1694 10.9099 12.4042 11.5978 13.1094L12.3445 13.911C14.9426 16.9055 19.8267 13.6349 19.8267 10.0984C19.8267 6.62074 15.1043 3.40036 12.451 6.16919L11.8354 6.8357L11.533 7.14942C10.8699 7.81528 10.4854 8.02852 9.99462 8.02852C9.46602 8.02852 9.02291 7.75016 8.26747 6.95773L7.64556 6.28655C5.04643 3.29144 0.162598 6.56185 0.162598 10.0984L0.162638 10.0983ZM17.7569 10.0983C17.7569 11.9326 15.1508 13.7261 13.9734 12.6207L13.7173 12.3474L13.4345 12.0394C12.1776 10.6737 11.349 10.1015 9.99719 10.0983C8.72409 10.0951 7.91837 10.5979 6.78961 11.7879L6.13248 12.4978C4.98149 13.8229 2.23269 11.9822 2.23269 10.0984C2.23269 8.26409 4.83881 6.47082 6.01615 7.576L6.57935 8.18332C7.80881 9.5124 8.66717 10.0984 9.99482 10.0984C11.3056 10.0984 12.087 9.58854 13.3165 8.28226L13.8811 7.67198C15.0081 6.3739 17.7569 8.21458 17.7569 10.0984L17.7569 10.0983Z"
      fill="currentColor"
    />
    {props.children}
  </svg>
);
