import * as CheckboxPrimitive from '@radix-ui/react-checkbox';
import React from 'react';
import { Check } from '@/assets/icons';
import OrderItem from '@/model/OrderItem';

interface CollapsibleProps {
  label: string;
  item: OrderItem;
  index: string;
  selectedIndex: string;
  children: React.ReactNode;
  open: boolean;
  setOpen: (open: boolean, item: OrderItem, index: string) => void;
}

export const CollapsibleCustom: React.FC<CollapsibleProps> = ({
  label,
  item,
  index,
  selectedIndex,
  open,
  children,
  setOpen,
}) => {
  const toggle = (ix: string): void => {
    setOpen(!open, item, ix);
  };

  return (
    <>
      <div className={`relative flex items-center}`} onClick={() => toggle(index)}>
        <CheckboxPrimitive.Root
          aria-checked={open && index === selectedIndex}
          checked={open && index === selectedIndex}
          className={`absolute -top-[1px] -left-[1px] border border-gray-500 w-[27px] h-[27px] rounded`}
        >
          <CheckboxPrimitive.Indicator className="absolute -top-[1px] -left-[1px] flex items-center justify-center rounded bg-red-500  w-[27px] h-[27px]">
            <img src={Check} alt="Checked" />
          </CheckboxPrimitive.Indicator>
        </CheckboxPrimitive.Root>
        <label
          onClick={() => toggle(index)}
          className="block font-normal text-[21px] cursor-pointer ml-[42px] text-neutro-b-500 leading-[27px]"
        >
          {label}
        </label>
      </div>
      {open && index === selectedIndex && <div>{children}</div>}
    </>
  );
};
