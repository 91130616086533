// import { IconCalendar } from '@/assets/icons';
// import { IconPin } from '@/assets/icons/IconPin';
import { AuthWrapper } from '@/features/core/auth/screens/AuthWrapper';
import Order from '@/model/Order';
import dayjs from 'dayjs';
import PaymentType from '@/model/PaymentType';
import OrderPayment from '@/model/OrderPayment';
import OrderItemType from '@/model/OrderItemType';
import React, { JSX } from 'react';

interface StateProps {
  order: Order;
  showTickets: boolean;
  showProducts: boolean;
}

interface DispatchProps {
  toCurrency: (value: number) => string;
  toPaymentType: (paymentType: PaymentType) => string;
  mountCreditCardDescription: (orderPayment: OrderPayment) => string;
  toCPF: (value: string) => string;
  toPhone: (value: string) => string;
}

type Props = StateProps & DispatchProps;

export const TicketDetailContainer: React.FC<Props> = ({
  order,
  showTickets,
  showProducts,
  toCurrency,
  toPaymentType,
  mountCreditCardDescription,
  toCPF,
  toPhone,
}): JSX.Element => (
  <AuthWrapper isHomepage={false}>
    <div className="w-full p-7 md:px-0 container mx-auto py-24 font-dmsans">
      <header className=" mb-[20px]">
        <>
          <div className="w-full flex justify-between items-center mb-[20px]">
            <div className="flex items-center">
              <h1 className="text-neutro-b-400 font-medium text-[37px]">Detalhes do pedido</h1>
            </div>
          </div>
          {order.events &&
            order.events.length > 0 &&
            order.events.map(data => (
              <>
                {/* <div
                    key={data.event.id}
                    className="relative md:w-[1110px] h-[227px] overflow-hidden rounded-[5px]"
                  ></div> */}
                <div
                  key={data.event.id}
                  // className="overflow-hidden rounded-[5px]"
                >
                  <div className="flex align-middle">
                    {/* <label className="block text-[15px] text-neutro-w-600 mr-2">
                        Evento:
                      </label> */}
                    <h2 className="text-neutro-b-400 font-medium text-[25px] mb-[15px] text-center ">
                      {data.event.name}
                    </h2>
                  </div>
                  <div className="mb-[15px] ">
                    <label className="block text-[18px] text-neutro-w-600 mr-2">
                      Data do Evento:
                    </label>
                    <span className="block text-[18px] ml-[15px]">
                      {dayjs(data.event.startDate).locale('pt-br').format('DD/MM/YYYY')}
                    </span>
                  </div>

                  {/* <div className="absolute z-[1] w-full h-full flex flex-col justify-center items-center text-white">
                      <h2 className="font-bold text-[37px] leading-[48px] mb-[20px]"></h2>
                      <div className="flex justify-center items-center">
                        <div className="font-normal text-[21px] inline-flex items-center">
                          <IconCalendar className="h-[18px] mr-[15px] text-white" />{' '}
                        </div>
                        <div className="font-normal text-[21px] ml-10 inline-flex items-center">
                          <IconPin className="h-[18px] mr-[15px] text-white" />{' '}
                          {`${data.event.city}/${data.event.state}`}
                        </div>
                      </div>
                    </div> */}
                  {/* <div className="absolute z-auto w-full h-full bg-ticket-detail"></div> */}
                  {/* <img
                      src={data.event.imageUrl}
                      alt="Ingresso"
                      className="w-full object-cover"
                    /> */}
                </div>
                {showTickets && (
                  <>
                    <label className="block text-[18px] text-neutro-w-600 mr-2">
                      Quantidade e Tipo do ingresso:
                    </label>
                    {data.items &&
                      data.items.length > 0 &&
                      data.items.map(item => (
                        <>
                          {item.itemType === OrderItemType.TICKET && (
                            <>
                              <p
                                key={item.id}
                                className="text-[18px] text-neutro-b-400 mb-[10px] ml-[15px]"
                              >
                                {`${item.amount}x - ${item.name}`}
                              </p>
                              {item.userData && item.userData.name && (
                                <div className="mt-[10px]">
                                  <label className="block text-[18px] text-neutro-w-600 mb-[10px]">
                                    Ingresso transferido para:
                                  </label>

                                  <p className="text-[18px] text-neutro-b-400 mb-[10px] leading-normal ml-[15px]">
                                    Nome completo: {item.userData.name} <br />
                                    CPF: {toCPF(item.userData.document)} <br />
                                    E-mail: {item.userData.email}
                                    {item.userData.phone &&
                                      item.userData.phone.trim().length > 0 && (
                                        <>
                                          <br />
                                          Telefone: {toPhone(item.userData.phone)}
                                        </>
                                      )}
                                  </p>
                                </div>
                              )}
                            </>
                          )}
                        </>
                      ))}
                  </>
                )}
                {showProducts && (
                  <div className="mb-[10px]">
                    <label className="block text-[18px] text-neutro-w-600 mb-[10px]">
                      Produtos adquiridos:
                    </label>
                    {data.items &&
                      data.items.length > 0 &&
                      data.items.map(item => (
                        <>
                          {item.itemType === OrderItemType.PRODUCT && (
                            <p
                              key={item.id}
                              className="text-[18px] text-neutro-b-400 mb-[10px] leading-10 ml-[15px]"
                            >
                              {`${item.amount}x - ${item.name}`}
                            </p>
                          )}
                        </>
                      ))}
                  </div>
                )}
              </>
            ))}
        </>

        <div>
          {order.payments &&
            order.payments.length > 0 &&
            order.payments.map(data => (
              <>
                <div className="mb-[10px]">
                  <label className="block text-[18px] text-neutro-w-600 mr-2">Valor pago:</label>
                  <p className="text-[18px] text-neutro-b-400 ml-[15px]">
                    {toCurrency(data.paymentValue)}
                  </p>
                </div>

                <div className="mb-[10px]">
                  <label className="block text-[18px] text-neutro-w-600">
                    Método de pagamento utilizado:
                  </label>
                  <p className="text-[18px] text-neutro-b-400 ml-3">
                    {toPaymentType(data.paymentType)}
                  </p>
                  <p className="text-[18px] text-neutro-b-400 ml-3 uppercase">
                    {mountCreditCardDescription(data)}
                  </p>
                  <p className="text-[18px] text-neutro-b-400 ml-3">
                    {data.installments}x de ${toCurrency(data.paymentValue / data.installments)}
                  </p>
                </div>
              </>
            ))}

          {order.discountCoupon && (
            <div className="mb-[50px]">
              <label className="block text-[18px] text-neutro-w-600 mb-[15px]">
                Cupom do desconto:
              </label>
              {[1].map(item => (
                <p
                  key={item}
                  className="text-[18px] text-neutro-b-400 mb-[20px] leading-10 ml-[15px]"
                >
                  {`${order.discountCoupon?.code} - ${order.discountCoupon?.name} - ${
                    order.discountCoupon ? toCurrency(order.discountCoupon.discount) : ''
                  }`}
                </p>
              ))}
            </div>
          )}

          {order.cancelDate && (
            <div className="mb-[50px]">
              <label className="block text-[18px] text-neutro-w-600 mb-[15px]">Cancelado:</label>
              {
                <p className="text-[18px] text-neutro-b-400 mb-[20px] leading-10">
                  {`Ingresso cancelado em ${dayjs(order.cancelDate).format(
                    'DD/MM/YYYY [às] HH:mm',
                  )} ${order.cancelName}`}
                </p>
              }
            </div>
          )}
        </div>
      </header>
    </div>
  </AuthWrapper>
);
