import React, { JSX } from 'react';
import { useParams } from 'react-router-dom';
import { EventComponent } from '@/features/event/screens/Event';
import EventProductsAndCombos from '@/model/EventProductsAndCombos';
import EventSectionProductsAndCombos from '@/model/EventSectionProductsAndCombos';
import EventSiteGet from '@/model/EventSiteGet';
import EventSiteHeader from '@/model/EventSiteHeader';
import { api } from '@/services/api';

export const PdvScreen: React.FC = (): JSX.Element => {
  const { pdvId, eventId } = useParams();

  const getEvent = async (): Promise<EventSiteHeader[]> => {
    const { data } = await api.get<EventSiteHeader[]>(`/event/${eventId}/pdv/${pdvId}`);
    return data;
  };

  const handleGetEvent = async (selectedEventId: string): Promise<EventSiteGet> => {
    const { data } = await api.get<EventSiteGet>(`/event/${selectedEventId}/pdv/${pdvId}/detail`);
    return data;
  };

  const handleProductsAndCombos = async (
    selectedEventId: string,
  ): Promise<EventProductsAndCombos> => {
    const { data } = await api.get<EventProductsAndCombos>(
      `/event/product-combo/${selectedEventId}`,
    );
    return data;
  };

  const handleGetSectionProductsAndCombos = async (
    selectedEventId: string,
    sectionId: string,
  ): Promise<EventSectionProductsAndCombos> => {
    const { data } = await api.get<EventSectionProductsAndCombos>(
      `/event/${selectedEventId}/pdv/${pdvId}/section/${sectionId}/product-combo`,
    );
    return data;
  };

  const handleOnGetPDV = (): string => pdvId as string;

  return (
    <EventComponent
      getEvents={getEvent}
      getEvent={handleGetEvent}
      getProductsAndCombos={handleProductsAndCombos}
      getSectionProductsAndCombos={handleGetSectionProductsAndCombos}
      getPdv={handleOnGetPDV}
    />
  );
};
