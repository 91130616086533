// export default MetaPixel;
import ReactPixel from 'react-facebook-pixel';

interface PixelEventProps {
  category: string;
  action: string;
}

const initReactPixel = (pixelID: string): void => {
  ReactPixel.init(pixelID);
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const MetaPixel = (pixelID: string) => {
  initReactPixel(pixelID);
  return {
    trackPageView: () => {
      ReactPixel.pageView();
    },

    trackEvent: ({ category, action }: PixelEventProps) => {
      ReactPixel.track(category, { action });
    },

    addCart: (
      productId?: string,
      productName?: string,
      productPrice?: number,
      productQuantity?: number,
    ) => {
      ReactPixel.track('AddToCart', {
        content_ids: [productId],
        content_name: productName,
        content_type: 'product',
        value: productPrice,
        currency: 'BRL',
        quantity: productQuantity,
      });
    },

    purchase: (orderId?: string, totalValue?: number, currency?: string) => {
      if (orderId && totalValue && currency) {
        ReactPixel.track('Purchase', {
          value: totalValue,
          currency: 'BRL',
          order_id: orderId,
        });
      }
    },
  };
};

export default MetaPixel;
