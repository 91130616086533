import React from 'react';

interface IconProps extends React.SVGProps<SVGSVGElement> {
  children?: React.ReactNode;
}

export const IconUser: React.FC<IconProps> = props => (
  <svg
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="xMaxYMax"
    {...props}
  >
    <path
      d="M12.9994 12.7973C14.7595 12.7973 16.4473 12.1232 17.692 10.9233C18.9365 9.72313 19.6356 8.09575 19.6356 6.39867C19.6356 4.70159 18.9364 3.07416 17.692 1.87407C16.4473 0.674123 14.7595 0 12.9994 0C11.2394 0 9.55154 0.67417 8.30691 1.87407C7.06242 3.0742 6.36328 4.70159 6.36328 6.39867C6.36573 8.095 7.06565 9.72118 8.30956 10.9208C9.55366 12.1201 11.2401 12.795 12.9995 12.7974L12.9994 12.7973ZM12.9994 1.90569C14.2349 1.90569 15.4197 2.37886 16.2934 3.22134C17.167 4.06364 17.6579 5.20606 17.6579 6.39728C17.6579 7.58849 17.1673 8.73096 16.2938 9.57339C15.4203 10.4157 14.2356 10.889 13.0002 10.8892C11.7648 10.8894 10.5797 10.4164 9.70601 9.57432C8.83227 8.73203 8.34115 7.58979 8.34095 6.39858C8.34283 5.20787 8.83414 4.0667 9.70751 3.22482C10.5809 2.38309 11.7646 1.90973 12.9994 1.90829V1.90569Z"
      fill="currentColor"
    />
    <path
      d="M2.47859 25.9997C2.47859 20.4058 7.19823 15.8551 12.9997 15.8551C18.8011 15.8551 23.5208 20.4059 23.5208 25.9997H25.5C25.4998 22.8033 24.1829 19.7376 21.8386 17.4774C19.4945 15.2172 16.3149 13.9475 13 13.9473C9.68493 13.9473 6.50554 15.217 4.16141 17.4772C2.99706 18.5939 2.07385 19.9221 1.44545 21.3848C0.816802 22.8477 0.495521 24.4164 0.500047 25.9998L2.47859 25.9997Z"
      fill="currentColor"
    />
    {props.children}
  </svg>
);
