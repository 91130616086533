import React, { useState } from 'react';
import Flickity from 'react-flickity-component';
import { classNames } from '@/helpers/common';
import { Ticket } from '@/model/Ticket';
import Section from '@/model/Section';
import { EventDateSectionSite } from '../screens/Event';
import { ShouldShowModalProps } from '../types';

const flickityOptions = {
  freeScroll: true,
  percentPosition: true,
  cellAlign: 'left',
};

interface SliderProductAreaProps {
  items: EventDateSectionSite[];
  handleOnClick: (item: Section) => void;
  showModal: (props: ShouldShowModalProps) => void;
  getEventKey: (sectionId: string, ticketId: string) => string;
  onModal: (sectionId: string, tickectId: string, hasMap: boolean) => void;
}

interface TicketProps {
  item: Ticket;
  index: number;
  handleOnClick: (item: Ticket) => void;
  tabActive: number;
  setTabActive: (value: number) => void;
}

interface ProductAreaProps extends Omit<TicketProps, 'item' | 'handleOnClick'> {
  item: EventDateSectionSite;
  sectionId: string;
  handleOnClick: (item: Section) => void;
  showModal: (props: ShouldShowModalProps) => void;
  getEventKey: (sectionId: string, ticketId: string) => string;
  onModal: (sectionId: string, tickectId: string, hasMap: boolean) => void;
}

const ProductAndArea: React.FC<ProductAreaProps> = ({
  item,
  index,
  handleOnClick,
  tabActive,
  setTabActive,
  sectionId,
  onModal,
}) => (
  <a
    key={item.section.id}
    onClick={() => {
      handleOnClick(item.section);
      setTabActive(index + 1);
      onModal(sectionId, item.tickets[0].id, item.showModal || false);
    }}
    className={classNames(
      tabActive === index + 1
        ? 'bg-[#60B9CD] text-white font-bold border-alert-info-soft'
        : 'bg-[#F1F1F1] text-[#828282] font-normal border-neutro-w-100',
      'carousel-cell w-[135px] mr-5 cursor-pointer p-2 flex flex-col justify-center items-center border hover:border-alert-info-soft',
    )}
  >
    {item.count > 0 && (
      <div
        className={classNames(
          tabActive === index + 1
            ? 'bg-neutro-w-000 text-alert-info-soft'
            : 'bg-neutro-w-400 text-neutro-w-000',
          'w-[14px] h-[14px] rounded-full font-dmsans font-normal text-[10px] flex justify-center items-center absolute top-2 right-2',
        )}
      >
        {item.count}
      </div>
    )}

    {item.section.name && <span className="text-center">{item.section.name}</span>}
  </a>
);

const SliderProductArea: React.FC<SliderProductAreaProps> = ({
  items,
  handleOnClick,
  showModal,
  getEventKey,
  onModal,
}) => {
  const [tabActive, setTabActive] = useState(0);

  return (
    <Flickity options={flickityOptions} className={'slider-ticket-product pl-[60px] mt-6'}>
      {items.map((item, index) => (
        <ProductAndArea
          key={item.section.id}
          item={item}
          index={index}
          handleOnClick={handleOnClick}
          tabActive={tabActive}
          setTabActive={setTabActive}
          showModal={showModal}
          sectionId={item.section.id.toString()}
          getEventKey={getEventKey}
          onModal={onModal}
        />
      ))}
    </Flickity>
  );
};

export { SliderProductArea };
